import { Quill } from 'react-quill';
import { IndentStyleLevels } from 'constants/CampaignEditorQuillConstants';
let Parchment = Quill.import('parchment');
const multiplier = 2;
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    return super.add(node, `${value * multiplier}rem`);
  }

  value(node) {
    return parseFloat(super.value(node)) / multiplier || undefined; // Don't return NaN
  }
}

export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: IndentStyleLevels.map(value => `${value * multiplier}rem`)
});
