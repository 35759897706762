export const errorCode = {
  FILE_DUPLICATE_COL:
    'This file contains duplicate columns.Please upload a file with unique column name(s)',
  FILE_NO_EMAIL_COL:
    'Your file is missing an email column. Please fix your file.',
  FILE_MISC_ERR: 'Invalid file format. Please upload CSV file.',
  FILE_BLANK_COL: 'Header cannot be blank.',
  CAMPAIGN_ID_NOT_FOUND: 'This page does not exist.',
  FIELD_UPDATE_API_ERROR: 'Could not save field value(s)',
  NO_SEED_LIST_FOUND: 'No seed list found',
  BLANK_ROW_ERROR: 'Could not upload blank file',
  EMAIL_SEND_ERROR: 'Email Sending Failed',
  TEST_EMAIL_ERROR: 'Test Email Sending Failed',
  VALIDATE_EMAIL_ERROR: 'Could not proceed due to some error.',
  DELETE_RECIPIENT_DATA_ERROR: 'Could not delete recipient list due to some error.',
  DELETE_CAMPAIGN_ERROR: 'Could not delete campaign due to some error.'
};
