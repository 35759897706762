import React, { Fragment } from 'react';
import { Modal } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toggleLaunchModal } from 'store/actions/common';
import constants from './../../../constants';

const ModalHeader = props => {
  const { campaign } = props,
    { coming_from } = campaign;

  const launchPopupTitle = coming_from
    ? constants.TEST_EMAIL_CAMPAIGN
    : constants.LAUNCH_EMAIL_CAMPAIGN;

  return (
    <Fragment>
      <Modal.Header>
        <Modal.Title className="modal-header-title">
          {launchPopupTitle}
        </Modal.Title>
      </Modal.Header>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleLaunchModal: from => dispatch(toggleLaunchModal(from))
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalHeader)
);
