import React, { Fragment } from 'react';
import { Modal, Dropdown, Button } from 'stylist';
import { LANGUAGE } from 'constants/LanguageConstant';

export default function FieldMergeModal(props) {
  return (
    <Modal open={props.showFieldMerge} onClose={props.showFieldMergeModal}>
      <Modal.Header>
        <Modal.Title className="modal-header-title">
          {LANGUAGE.FIELD_MERGE}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.showFieldMergeList && props.fieldMergeList.length > 0 ? (
          <Fragment>
            <p>{LANGUAGE.SELECT_A_VARIABLE_TO_INSERT}:</p>
            <Dropdown
              name="fieldMergeBox"
              onChange={value => props.setFieldMergeValue(value)}
              value={props.fieldMergeValue}>
              {props.fieldMergeList.map((field, index) => (
                <Dropdown.Option key={index} value={field}>
                  {field}
                </Dropdown.Option>
              ))}
            </Dropdown>
          </Fragment>
        ) : (
          props.showFieldMergeList && <p>{LANGUAGE.NO_FIELD_VARIABLE_NAME}</p>
        )}
      </Modal.Body>
      <div className="footerButtons d-flex align-items-center justify-content-end px-4 mt-5">
        <Button
          marginRight
          kind="ghost"
          onClick={() => {
            props.showFieldMergeModal();
            props.editorRef.editor.setSelection(window.quillRange.index);
          }}>
          Cancel
        </Button>
        {props.fieldMergeValue && (
          <Button
            kind="primary"
            onClick={() => {
              props.insertField(props.fieldMergeValue);
              props.showFieldMergeModal();
            }}>
            Insert
          </Button>
        )}
      </div>
    </Modal>
  );
}
