import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import { DesignTokenProvider } from '@fv/design-tokens';
import { Theme } from 'stylist';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles/main.scss';
import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import App from 'App';
import { graphqlApi } from 'api';
import store from 'store';
import i18n from 'i18n/index';

const CampaignManager = (
  <ApolloProvider client={graphqlApi}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <DesignTokenProvider>
            <Theme>
              <App />
            </Theme>
          </DesignTokenProvider>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </ApolloProvider>
);

ReactDOM.render(CampaignManager, document.getElementById('root'));
