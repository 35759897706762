import React, { Fragment, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import {
  Tabs,
  Row,
  Container,
  DropdownMenu,
  CaretIcon,
  Button,
  Tooltip
} from 'stylist';
import Home from 'routes/Home';
import Preview from 'routes/Preview';
import ErrorPage from 'routes/ErrorPage';
import Unsubscribe from 'routes/Unsubscribe';
import Unauthorized from 'routes/Unauthorized';
import DownloadFile from 'routes/DownloadFile';
import Header from 'components/header/index';
import PopUp from 'components/PopUp';
import { MobileIcon, DesktopIcon } from 'components/Icons';
import { deleteRecipientData } from 'store/actions/common';
import store from 'store';
import { STATUS } from 'utils/campaignStatus';

const Main = props => {
  const { campaign, common, location, setActivePreviewTab } = props,
    {
      campaign: details,
      id,
      isCampaignDeleting,
      deleteCampaignErrorMsg,
      deleteRecipientErrorMsg,
      deletionRecipientStarted,
      deletionCampaignStarted,
      deletionError,
      isRecipientListDeleting,
      deleteCampaignStatus
    } = campaign,
    { activeTabType, sendingStatus } = common;

  const [popupModal, setPopupModal] = useState(false);

  if (deleteCampaignStatus) {
    if (details.back_url) {
      window.location.href = details.back_url;
    } else {
      window.location.href = window.location.reload;
    }
  }

  return (
    <Fragment>
      {campaign.id && (
        <Fragment>
          <Header campaign={campaign} />
          <Row>
            <Container>
              <div className="build-preview-tabs d-flex justify-content-between align-items-center">
                <Tabs className="">
                  <Tabs.Item to={`/campaign/${id}`} exact>
                    Build
                  </Tabs.Item>
                  {details.campaign_status === STATUS.LAUNCHED ||
                  details.campaign_status === STATUS.PENDING ||
                  sendingStatus === STATUS.SENDING ? (
                    <Tabs.Item className="tab-disabled" to="">
                      Preview
                    </Tabs.Item>
                  ) : (
                    <Tabs.Item to={`/campaign/preview/${id}`}>
                      Preview
                    </Tabs.Item>
                  )}
                </Tabs>
                {location.pathname === `/campaign/preview/${id}` && (
                  <div className="preview-button-container">
                    <Button
                      kind="ghost"
                      title="Desktop View"
                      className={
                        activeTabType === 'desktop'
                          ? 'preview-button isActive'
                          : 'preview-button'
                      }
                      onClick={() => setActivePreviewTab('desktop')}>
                      <DesktopIcon
                        fill={
                          activeTabType === 'desktop' ? '#ffffff' : '#3e5467'
                        }
                      />
                    </Button>
                    <Button
                      kind="ghost"
                      title="Mobile View"
                      className={
                        activeTabType === 'mobile'
                          ? 'preview-button isActive'
                          : 'preview-button'
                      }
                      onClick={() => setActivePreviewTab('mobile')}>
                      <MobileIcon
                        fill={
                          activeTabType === 'mobile' ? '#ffffff' : '#3e5467'
                        }
                      />
                    </Button>
                  </div>
                )}
                <div className="actions">
                  <DropdownMenu
                    trigger={toggle => (
                      <Button
                        kind="ghost"
                        onClick={toggle}
                        className="actionsBtn">
                        Actions <CaretIcon size="xl" />
                      </Button>
                    )}>
                    {details.campaign_status === STATUS.LAUNCHED ||
                    details.campaign_status === STATUS.PENDING ||
                    sendingStatus === STATUS.SENDING ? (
                      <DropdownMenu.Item className="delete disabled">
                        Duplicate Campaign
                      </DropdownMenu.Item>
                    ) : (
                      <DropdownMenu.Item>Duplicate Campaign</DropdownMenu.Item>
                    )}
                    {details.campaign_status === STATUS.LAUNCHED ||
                    details.campaign_status === STATUS.SENDING ||
                    details.campaign_status === STATUS.PENDING ||
                    (details.campaign_status === STATUS.LAUNCHED ||
                      sendingStatus === STATUS.SENDING) ? (
                      <Tooltip
                        position="bottom"
                        label={
                          details.campaign_status === STATUS.LAUNCHED
                            ? "Launched campaign can't be deleted"
                            : "Campaign can't be deleted as it is being launched"
                        }
                        className="deleteCampaignTooltip"
                        render={({ hide, show, ref }) => (
                          <DropdownMenu.Item
                            innerRef={ref}
                            onMouseOver={show}
                            onMouseOut={hide}
                            className="delete disabled">
                            Delete Campaign
                          </DropdownMenu.Item>
                        )}
                      />
                    ) : (
                      <DropdownMenu.Item
                        onClick={() => setPopupModal(!popupModal)}
                        className="delete">
                        Delete Campaign
                      </DropdownMenu.Item>
                    )}
                    <PopUp
                      headerTitle={
                        isCampaignDeleting
                          ? 'Deleting Campaign'
                          : 'Delete Campaign'
                      }
                      dialogBody="Are you sure you want to delete this campaign?"
                      cancelButton="Cancel"
                      confirmButton="Delete"
                      open={popupModal}
                      isProcessing={
                        popupModal
                          ? isCampaignDeleting
                          : isRecipientListDeleting
                      }
                      deletionErrorMsg={
                        popupModal
                          ? deleteCampaignErrorMsg
                          : deleteRecipientErrorMsg
                      }
                      deletionProcessStarted={
                        popupModal
                          ? deletionCampaignStarted
                          : deletionRecipientStarted
                      }
                      deletionError={deletionError}
                      onConfirm={() =>
                        store.dispatch(
                          deleteRecipientData(
                            store.getState().campaign.id,
                            true
                          )
                        )
                      }
                      onCancel={() => setPopupModal(!popupModal)}
                    />
                  </DropdownMenu>
                </div>
              </div>
            </Container>
          </Row>
        </Fragment>
      )}
      <main>
        <Switch>
          <Route exact path="/campaign/:id" component={Home} />
          <Route exact path="/campaign/preview/:id" component={Preview} />
          <Route exact path="/download/:string" component={DownloadFile} />
          <Route exact path="/unauthorized" component={Unauthorized} />
          <Route
            exact
            path="/campaign/unsubscribe/:campaignId/:mail"
            component={Unsubscribe}
          />
          <Route path="" component={ErrorPage} />
        </Switch>
      </main>
    </Fragment>
  );
};

export default withRouter(Main);
