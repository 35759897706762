import { Quill } from 'react-quill';
let Embed = Quill.import('blots/embed');

export default class UtfBlot extends Embed {
  static create(value) {
    const node = super.create(value);
    node.innerHTML = value;

    return node;
  }

  static value(node) {
    return node.textContent;
  }
}

UtfBlot.blotName = 'utf';
UtfBlot.tagName = 'span';
UtfBlot.className = 'utf-text';

