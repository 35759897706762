import actionTypes from 'store/actionTypes';
import { graphqlApi, restApi } from 'api';
import {
  GET_CAMPAIGN_DATA,
  GET_RECIPIENT_LIST,
  GET_SEED_LIST,
  GET_FIELD_MERGE_VALUES,
  VALIDATE_MAIL,
  GET_IMAGES,
  GET_DOWNLOAD_LINK
} from 'api/queries';
import {
  SAVE_FORM_FIELD,
  DOWNLOAD_RECIPIENT_FILE,
  SAVE_SEED_LIST,
  SEND_EMAIL,
  DELETE_IMAGE,
  DELETE_RECIPIENT,
  UPDATE_RECIPIENT_FIELDS
} from 'api/mutations';
import { isFileTypeValid, replaceString } from 'utils/fileValidator';
import { errorCode } from 'utils/errorCodes';
import { isEmailValid } from 'utils/fieldValidations';
import store from 'store';
import { URL } from 'utils/urlBuilder';
import { redirectToUrl } from 'utils/common';
import { STATUS } from 'utils/campaignStatus';
import constants from './../../constants';

let saveMailBodyTimeOut = 0;

export const getCampaignDetailsByID = id => dispatch => {

  if (Number.isInteger(parseInt(id))) {
    graphqlApi
      .query({ query: GET_CAMPAIGN_DATA(id) })
      .then(resolved => {
        if (window.localStorage.getItem(constants.SURVEY_STATE)) {
          resolved.data.campaign.data.survey_state = window.localStorage.getItem(
            constants.SURVEY_STATE
          );
          window.localStorage.removeItem(constants.SURVEY_STATE);
        }
        store.dispatch(
          updateCampaignStatus(
            resolved.data.campaign.data,
            resolved.data.campaign.campaignId,
            resolved.data.campaign.data.name
          )
        );

        if (
          (resolved.data.campaign.data.campaign_status &&
            resolved.data.campaign.data.campaign_status === STATUS.LAUNCHED) ||
          resolved.data.campaign.data.campaign_status === STATUS.PENDING ||
          resolved.data.campaign.data.campaign_status === STATUS.SENDING
        ) {
          document.getElementsByTagName('main')[0].style.pointerEvents = 'none';
        }
        if (resolved.data.campaign.data.mail_body) {
          dispatch({
            type: actionTypes.GET_CAMPAIGN_DETAILS_BY_ID,
            payload: { id, ...resolved.data.campaign }
          });
        } else {
          resolved.data.campaign.data.mail_body = '<p><br/></p>';

          dispatch({
            type: actionTypes.GET_CAMPAIGN_DETAILS_BY_ID,
            payload: { id, ...resolved.data.campaign }
          });
        }
      })
      .catch(rejected => {
        if (
          rejected.networkError &&
          rejected.networkError.response &&
          rejected.networkError.response.status
        ) {
          return redirectToUrl(rejected);
        }

        return dispatch({
          type: actionTypes.GET_CAMPAIGN_DETAILS_BY_ID_ERROR,
          payload: errorCode.CAMPAIGN_ID_NOT_FOUND
        });
      });
  } else {
    dispatch({
      type: actionTypes.GET_CAMPAIGN_DETAILS_BY_ID_ERROR,
      payload: errorCode.CAMPAIGN_ID_NOT_FOUND
    });
  }
};

export const handleLaunchProcess = () => dispatch => {
  return dispatch({
    type: actionTypes.HANDLE_LAUNCH_NOW_BUTTON,
    payload: 'Launch Now'
  });
};

export const handleScheduleProcess = () => dispatch => {
  return dispatch({
    type: actionTypes.HANDLE_SCHUDULE_BUTTON,
    payload: 'Schudule'
  });
};

export const getSeedList = id => dispatch => {
  if (Number.isInteger(parseInt(id))) {
    return graphqlApi
      .query({ query: GET_SEED_LIST(id), fetchPolicy: 'network-only' })
      .then(resolved => {
        return dispatch({
          type: actionTypes.GET_SEED_LIST,
          payload: resolved.data.seedList
        });
      })
      .catch(rejected => {
        return dispatch({
          type: actionTypes.GET_SEED_LIST_ERROR,
          payload: errorCode.NO_SEED_LIST_FOUND
        });
      });
  } else {
    return dispatch({
      type: actionTypes.GET_CAMPAIGN_DETAILS_BY_ID_ERROR,
      payload: errorCode.CAMPAIGN_ID_NOT_FOUND
    });
  }
};

export const saveRecipientList = (file, campaignId, getDetail) => dispatch => {
  dispatch({ type: actionTypes.UPLOAD_RECIPIENT_FILE_PROGRESS });

  let fileData = new FormData();

  fileData.append('file', file);
  fileData.append('fileName', file.name);
  fileData.append('id', campaignId);
  fileData.append('type', isFileTypeValid(file).type);
  fileData.append('encoding', 'utf-8');
  if (getDetail) {
    fileData.append('fetch_details', getDetail);
  } else {
    fileData.append('fetch_details', '');
  }

  return restApi
    .post('uploadList', fileData)
    .then(response => {
      if (response.data.error) {
        return dispatch({
          type: actionTypes.UPLOAD_RECIPIENT_FILE_ERROR,
          payload: response.data.error
        });
      } else {
        if (getDetail) {
          dispatch({
            type: actionTypes.UPLOAD_RECIPIENT_FILE_GET_SUCCESS,
            payload: response.data
          });
        } else {
          dispatch({
            type: actionTypes.UPLOAD_RECIPIENT_FILE_SUCCESS,
            payload: response.data
          });
        }
        document.getElementsByTagName('body')[0].style.overflow = 'auto';
      }
    })
    .catch(error => {
      if (error.response && error.response.status === 403) {
        return dispatch({
          type: actionTypes.UPLOAD_RECIPIENT_FILE_ERROR,
          payload: error.response.data.error
        });
      }
    });
};

export const getRecipientsList = (
  campaignId,
  offset,
  filterString,
  hasFilterApplied
) => dispatch => {
  dispatch({ type: actionTypes.GET_RECIPIENTS_LIST_PROGRESS });
  dispatch({ type: actionTypes.SET_RECIPIENT_EDIT_STATE, payload: false });

  offset = parseInt(offset);
  graphqlApi
    .query({
      query: GET_RECIPIENT_LIST(campaignId, offset, filterString),
      fetchPolicy: 'network-only'
    })
    .then(resolved => {
      if (resolved.data && resolved.data.recipients) {
        resolved.data.recipients.offset = offset;
        resolved.data.recipients.filterString = filterString;
        resolved.data.recipients.hasFilterApplied = hasFilterApplied;

        return dispatch({
          type: actionTypes.GET_RECIPIENTS_LIST_SUCCESS,
          payload: resolved.data.recipients
        });
      }
    })
    .catch(reject => {
      return dispatch({
        type: actionTypes.GET_RECIPIENTS_LIST_ERROR
      });
    });
};

export const setCurrentPageNumber = currentPage => dispatch => {
  return dispatch({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: currentPage
  });
};

export const saveFormField = (e, id, field) => dispatch => {
  const { oldCampaign } = store.getState().campaign;

  const { name, value } = e.target;

  let campaign = {};

  if (value.trim() !== '') {
    campaign[name] = value;
  } else {
    campaign[name] = value.trim();
  }

  if (e.target.name === campaign[name]) {
    campaign.is_new = false;
  }
  clearTimeout(saveMailBodyTimeOut);

  saveMailBodyTimeOut = setTimeout(() => {
    dispatch({ type: actionTypes.HIDE_SAVE_MESSAGE });
  }, 2000);

  if (
    (value && (field && !field.hasError)) ||
    name === 'subject' ||
    name === 'sender'
  ) {
    if (value !== oldCampaign[name]) {
      dispatch({ type: actionTypes.MAIL_BODY_SAVING });

      return graphqlApi
        .mutate({
          mutation: SAVE_FORM_FIELD(),
          variables: { campaign: JSON.stringify(campaign), id }
        })
        .then(resolved => {
          dispatch({
            type: actionTypes.UPDATE_AFTER_FIELD_VALUE_CHANGE,
            payload: {
              [name]: resolved.data.updateCampaign.campaignResult.data[name]
            }
          });
          dispatch({ type: actionTypes.MAIL_BODY_SAVED });

          return saveMailBodyTimeOut;
        });
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const downloadRecipientList = (id, fileName) => dispatch => {
  dispatch({ type: actionTypes.DOWNLOAD_RECIPIENT_FILE_PROGRESS });

  graphqlApi
    .mutate({
      mutation: DOWNLOAD_RECIPIENT_FILE(),
      variables: { id, fileName }
    })
    .then(resolved => {
      return dispatch({
        type: actionTypes.DOWNLOAD_RECIPIENT_FILE_SUCCESS,
        payload: resolved.data.downloadRecipientList.downloadLinkStatus
      });
    })
    .catch(rejected => {
      return dispatch({ type: actionTypes.DOWNLOAD_RECIPIENT_FILE_ERROR });
    });
};

export const updateSeedList = (
  id,
  deletedRows,
  list,
  launchTime,
  launchNow
) => dispatch => {
  dispatch({ type: actionTypes.IS_SEED_LIST_UPDATING });

  return graphqlApi
    .mutate({
      mutation: SAVE_SEED_LIST(),
      variables: {
        seedList: JSON.stringify(list),
        deletedRows,
        id,
        launchTime,
        launchNow
      }
    })
    .then(resolved => {
      return dispatch({
        type: actionTypes.UPDATE_SEED_LIST,
        payload: resolved.data.updateSeedList.seedListResult
      });
    });
};

export const saveMailBody = (value, id) => dispatch => {
  dispatch({ type: actionTypes.MAIL_BODY_SAVING });

  let campaign = {};

  campaign.mail_body = value;
  clearTimeout(saveMailBodyTimeOut);

  saveMailBodyTimeOut = setTimeout(() => {
    dispatch({ type: actionTypes.HIDE_SAVE_MESSAGE });
  }, 2000);

  return graphqlApi
    .mutate({
      mutation: SAVE_FORM_FIELD(),
      variables: { campaign: JSON.stringify(campaign), id }
    })
    .then(resolved => {
      dispatch({
        type: actionTypes.UPDATE_AFTER_FIELD_VALUE_CHANGE,
        payload: {
          mail_body: resolved.data.updateCampaign.campaignResult.data.mail_body
        }
      });
      dispatch({ type: actionTypes.MAIL_BODY_SAVED });

      return saveMailBodyTimeOut;
    });
};

export const handleAfterValidationSuccess = () => dispatch => {
  return dispatch({
    type: actionTypes.HANDLE_AFTER_VALIDATION_SUCCESS,
    payload: null
  });
};

export const handleTimePicker = date => dispatch => {
  return dispatch({
    type: actionTypes.HANDLE_TIME_PICKER,
    payload: date
  });
};

export const sendEmail = (
  id,
  launchType,
  back_url,
  list,
  launchTime,
  launchNow
) => dispatch => {
  let isListValid = true;

  const selectedRows = [];

  if (launchType === '') {
    isListValid = false;
  }

  dispatch({ type: actionTypes.IS_EMAIL_SENDING });

  return graphqlApi
    .mutate({
      mutation: SEND_EMAIL(),
      variables: { id, isListValid, selectedRows, launchTime, launchNow }
    })
    .then(resolved => {
      if (resolved.data.sendEmail.emailSendStatus === STATUS.SENDING) {
        document.getElementsByTagName('main')[0].style.pointerEvents = 'none';
      }
      dispatch({
        type: actionTypes.EMAIL_SEND,
        payload: {
          id,
          sendingStatus: resolved.data.sendEmail.emailSendStatus
        }
      });

      restApi
        .get(URL.REST_URL_VALID_SOURCE + '/validsource/file', {
          params: { campaign_id: Number(id) }
        })
        .then(response => {
          const { file_url } = response.data;
          window.location.href = `${back_url}:campaign?list_id=${list}&valid_source_url=${encodeURIComponent(
            file_url
          )}`;
        });
    })
    .catch(rejected => {
      dispatch({
        type: actionTypes.SET_EMAIL_SEND_ERROR,
        payload: errorCode.EMAIL_SEND_ERROR
      });
      dispatch({ type: actionTypes.CLOSE_MODAL_POPUP });
    });
};

export const getFieldMergeValues = (
  search,
  callBack,
  campaignId
) => dispatch => {
  return graphqlApi
    .query({
      query: GET_FIELD_MERGE_VALUES(campaignId),
      fetchPolicy: 'network-only'
    })
    .then(resolved => {
      if (search !== null && callBack !== null) {
        let newList = resolved.data.fieldMerge.map((field, index) => {
          return { display: field, id: index };
        });

        const matches = newList.filter(match =>
          match.display.toLowerCase().includes(search.toLowerCase())
        );

        if (matches.length > 0) {
          callBack(matches);
        } else {
          callBack(null);
        }
      }

      return dispatch({
        type: actionTypes.GET_FIELD_MERGE_LIST,
        payload: resolved.data.fieldMerge
      });
    })
    .catch(rejected => {
      return dispatch({
        type: actionTypes.GET_FIELD_MERGE_LIST_ERROR
      });
    });
};

export const getFieldVars = id => {
  return graphqlApi.query({
    query: GET_FIELD_MERGE_VALUES(id),
    fetchPolicy: 'network-only'
  });
};

export const syncEmailBody = (editorState, id, blurEvent) => dispatch => {
  if (blurEvent) {
    clearTimeout(saveMailBodyTimeOut);

    const editorString = replaceString(editorState, '&amp;', '&');

    dispatch({ type: actionTypes.SET_MAIL_BODY, payload: editorState });

    saveMailBodyTimeOut = setTimeout(() => {
      dispatch(saveMailBody(editorString, id));
    }, 2000);

    return saveMailBodyTimeOut;
  }
};

export const validateLaunchMail = (id, launchType) => dispatch => {
  let isListValid = true;

  if (launchType === '') {
    isListValid = false;
  }

  return graphqlApi
    .query({
      query: VALIDATE_MAIL(id, isListValid),
      fetchPolicy: 'network-only'
    })
    .then(resolved => {
      let data = JSON.parse(resolved.data.validateMail);

      return dispatch({
        type: actionTypes.VALIDATE_LAUNCH_MAIL,
        payload: { ...data }
      });
    })
    .catch(rejected => {
      return dispatch({
        type: actionTypes.EMAIL_VALIDATION_ERROR,
        payload: errorCode.VALIDATE_MAIL_ERROR
      });
    });
};

export const validateTestMail = (id, testMailType) => dispatch => {
  let isListValid = false;

  if (testMailType) {
    isListValid = true;
  }

  return graphqlApi
    .query({
      query: VALIDATE_MAIL(id, isListValid),
      fetchPolicy: 'network-only'
    })
    .then(resolved => {
      let data = JSON.parse(resolved.data.validateMail);

      return dispatch({
        type: actionTypes.VALIDATE_TEST_MAIL,
        payload: { ...data }
      });
    })
    .catch(rejected => {
      return dispatch({
        type: actionTypes.EMAIL_VALIDATION_ERROR,
        payload: errorCode.VALIDATE_MAIL_ERROR
      });
    });
};
export const deleteCampaignData = (id, back_url) => dispatch => {
  return restApi
    .delete(URL.REST_SWAGGER, {
      data: { campaign_id: id, survey: back_url, getCampaigns: '' }
    })
    .then(response => {
      if (response.status === 200) {
        dispatch({ type: actionTypes.DELETE_CAMPAIGN_SUCCESS });
      }
    })
    .catch(error => {
      let errorType =
        error.response && error.response.status ? error.response.status : '';
      if (typeof error.response.data.Error !== 'undefined') {
        let msg = error.response.data.Error;
        dispatch({
          type: actionTypes.DELETE_CAMPAIGN_ERROR,
          payload: { msg, deleted: errorType }
        });
      }
    });
};

export const deleteRecipientData = (id, deleteCampaign) => dispatch => {
  if (deleteCampaign) {
    store.dispatch(
      deleteCampaignData(id, store.getState().campaign.campaign.back_url)
    );
  } else {
    dispatch({ type: actionTypes.DELETING_RECIPIENT_DATA });

    return graphqlApi
      .mutate({
        mutation: DELETE_RECIPIENT(),
        variables: { id, deleteCampaign }
      })
      .then(resolved => {
        if (resolved.data.deleteData.deletionStatus) {
          dispatch({ type: actionTypes.DELETE_RECIPIENT_LIST_SUCCESS });
        } else {
          dispatch({ type: actionTypes.DELETE_RECIPIENT_LIST_ERROR });
        }
      });
  }
  document.getElementsByTagName('body')[0].style.overflow = 'auto';
};

export const updateCampaignStatus = (campaign, id, value) => dispatch => {
  campaign.name = value;
  campaign.campaignId = id;

  return graphqlApi
    .mutate({
      mutation: SAVE_FORM_FIELD(),
      variables: { campaign: JSON.stringify(campaign), id }
    })
    .then(resolved => {
      dispatch({
        type: actionTypes.UPDATE_AFTER_FIELD_VALUE_CHANGE,
        payload: {
          is_new: resolved.data.updateCampaign.campaignResult.data.is_new
        }
      });
    });
};

export const getImages = surveyLink => dispatch => {
  dispatch({ type: actionTypes.GETTING_IMAGE });

  return graphqlApi
    .query({
      query: GET_IMAGES(JSON.stringify(surveyLink)),
      fetchPolicy: 'network-only'
    })
    .then(resolved =>
      dispatch({
        type: actionTypes.GET_IMAGE,
        payload: resolved.data.campaignSurveyImages
      })
    )
    .catch(err => dispatch({ type: actionTypes.GET_IMAGE_ERR }));
};

export const uploadImages = (files, survey) => dispatch => {
  dispatch({ type: actionTypes.IMAGE_UPLOAD_PROGRESS });

  let fileData = new FormData();

  for (let i = 0; i < files.length; i++) {
    let file = files[i];

    fileData.append(`files[${i}]`, file);
  }

  fileData.append('survey_url', survey);

  restApi
    .post('imageUploadList', fileData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(res =>
      dispatch({ type: actionTypes.IMAGE_UPLOAD_SUCCESS, payload: res.data })
    )
    .catch(err => {
      dispatch({
        type: actionTypes.IMAGE_UPLOAD_ERROR,
        payload: err.response.data.error
      });
    });
};

export const deleteImage = (imgID, survey) => dispatch => {
  dispatch({ type: actionTypes.DELETING_IMAGE, payload: imgID });

  return graphqlApi
    .mutate({
      mutation: DELETE_IMAGE(),
      variables: { imgID, survey }
    })
    .then(resolved => {
      dispatch({
        type: actionTypes.DELETE_IMAGE_SUCCESS,
        payload: resolved.data.deleteCampaignImage.updatedImageList
      });
    });
};

export const getDownloadLink = id => dispatch => {
  dispatch({ type: actionTypes.GETTING_DOWNLOAD_LINK });

  graphqlApi
    .query({
      query: GET_DOWNLOAD_LINK(JSON.stringify(id))
    })
    .then(resolved => {
      store.dispatch({ type: actionTypes.GOT_DOWNLOAD_LINK });
      if (resolved.data.cloudFileDownloadUrl) {
        window.location.replace(resolved.data.cloudFileDownloadUrl);
      }
    })
    .catch(rejected => {
      if (
        rejected.networkError &&
        rejected.networkError.response &&
        rejected.networkError.response.status
      ) {
        return redirectToUrl(rejected);
      }
    });
};

export const getFileObject = file => {
  if (file && isFileTypeValid(file).status) {
    store.dispatch(saveRecipientList(file, store.getState().campaign.id, true));

    return {
      type: actionTypes.GET_FILE_OBJECT,
      payload: file
    };
  } else {
    return {
      type: actionTypes.FILE_VALIDATION_ERROR,
      payload: errorCode.FILE_MISC_ERR
    };
  }
};

export const getFieldValue = (e, type, id) => {
  const { name, value, selectionStart, selectionEnd } = e.target;

  e.persist();

  if (value) {
    if (type === 'email') {
      if (isEmailValid(value)) {
        e.target.setSelectionRange(selectionStart, selectionEnd);

        return {
          type: actionTypes.SET_FIELD_VALUE,
          payload: { name, value, hasError: false }
        };
      } else {
        return {
          type: actionTypes.EMAIL_FIELD_ERROR,
          payload: { name, value, hasError: true }
        };
      }
    } else {
      return {
        type: actionTypes.SET_FIELD_VALUE,
        payload: { name, value, hasError: false }
      };
    }
  } else {
    if (name === 'sender') {
      return {
        type: actionTypes.SET_FIELD_VALUE,
        payload: { name, value }
      };
    } else {
      return {
        type: actionTypes.BLANK_FIELD_ERROR,
        payload: { name, value }
      };
    }
  }
};

export const getSubjectValue = (e, type) => {
  const { value } = e.target;

  return {
    type: actionTypes.SET_FIELD_VALUE,
    payload: { name: type, value }
  };
};

export const removeFileData = () => {
  return { type: actionTypes.REMOVE_FILE_DATA };
};

export const toggleLaunchModal = from => {
  return { type: actionTypes.TOGGLE_LAUNCH_MODAL, payload: from };
};

export const surveyNotLive = from => {
  return { type: actionTypes.TOGGLE_SURVEY_NOT_LIVE_MODAL, payload: from };
};

export const toggleRecipientModal = () => {
  return { type: actionTypes.TOGGLE_RECIPIENT_MODAL };
};

export const showFormDetails = () => {
  return { type: actionTypes.SHOW_FORM_DETAILS };
};

export const setActivePreviewTab = type => {
  return { type: actionTypes.SET_ACTIVE_PREVIEW_TAB, payload: type };
};

export const hideHeaderOnErrorPage = () => {
  return { type: actionTypes.IS_ERROR_PAGE_RENDERED };
};

export const showHeaderOnNavigate = () => {
  return { type: actionTypes.IS_ERROR_PAGE_RENDERED };
};

export const closeAlert = () => {
  return { type: actionTypes.CLOSE_ALERT };
};

export const getFiles = files => {
  return { type: actionTypes.GET_IMAGE_FILES, payload: files };
};

export const saveCampaignDetails = (
  details,
  deleteRecipientIds
) => dispatch => {
  if (details.length || deleteRecipientIds.length) {
    const { id } = store.getState().campaign;
    graphqlApi
      .mutate({
        mutation: UPDATE_RECIPIENT_FIELDS(),
        variables: {
          campaignID: id,
          data: JSON.stringify(details),
          deletedRows: deleteRecipientIds
        }
      })
      .then(response => {
        let updatedList = response.data.updateRecipientList
          .updateRecipientListResult
          ? response.data.updateRecipientList.updateRecipientListResult
          : [];
        dispatch({
          type: actionTypes.UPDATE_RECIPIENT_LIST,
          payload: updatedList
        });

        return { type: actionTypes.SET_RECIPIENT_EDIT_STATE, payload: false };
      })
      .catch(rejected => {
        return dispatch({
          type: actionTypes.SAVE_RECIPIENT_STATE_ERROR,
          payload: { msg: rejected.graphQLErrors[0].message }
        });
      });
  }
};

export const setEditableState = isEditable => {
  return { type: actionTypes.SET_RECIPIENT_EDIT_STATE, payload: isEditable };
}

export const setSessionEnsured = () => {
  return { type: actionTypes.SET_SESSION_ENSURE, payload: false };
};
