import actionTypes from 'store/actionTypes';
import { infoCode } from 'utils/infoCodes';
import { errorCode } from '../../utils/errorCodes';

const initialState = {
  isErrorPageRendered: false,
  fileData: {},
  uploadProgress: false,
  fileError: '',
  fileTypeError: '',
  recipient_list_data: {},
  recipient_list_data_error: '',
  canReload: false,
  replaceModalStatus: false,
  sender: {},
  from: {},
  reply_to: {},
  subject: {},
  mail_body: {},
  name: {},
  fileDownloadStatus: false,
  recipient_list_success_status: '',
  recipient_list_download_message: '',
  isEmailSending: false,
  isEmailSent: false,
  emailSendingStatus: false,
  email_sent_message: '',
  IsEmailSentError: false,
  email_sent_error_message: '',
  isEditorHistoryEnabled: false,
  isTestEmailSending: false,
  isTestEmailSent: false,
  testEmailSendingStatus: false,
  test_email_sent_message: '',
  IsTestEmailSentError: false,
  test_email_error_message: '',
  activeTabType: 'desktop',
  showDetails: false,
  replaceProcessStarted: 'false',
  replaceError: 'false',
  sendingStatus: '',
  isGettingDownloadLink: false,
  isEditable: false,
  errorMessage: '',
  isSessionEnsured: true
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IS_ERROR_PAGE_RENDERED:
      return {
        ...state,
        isErrorPageRendered: true
      };

    case actionTypes.SHOW_HEADER_AFTER_NAVIGATE_FROM_ERROR_PAGE:
      return {
        ...state,
        isErrorPageRendered: false
      };

    case actionTypes.GET_FILE_OBJECT:
      return {
        ...state,
        fileData: action.payload,
        fileError: '',
        fileTypeError: ''
      };

    case actionTypes.GET_FILE_OBJECT_ERROR:
      return {
        ...state,
        fileError: action.payload,
        fileData: {}
      };

    case actionTypes.FILE_VALIDATION_ERROR:
      return {
        ...state,
        fileTypeError: action.payload,
        fileError: '',
        fileData: {}
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_PROGRESS:
      return {
        ...state,
        uploadProgress: true,
        canReload: false,
        replaceProcessStarted: true,
        replaceError: false
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_ERROR:
      return {
        ...state,
        uploadProgress: false,
        recipient_list_data_error: action.payload,
        canReload: false,
        replaceProcessStarted: true,
        replaceError: true
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_SUCCESS:
      return {
        ...state,
        uploadProgress: false,
        recipient_list_data: action.payload,
        canReload: true,
        fileData: {},
        replaceProcessStarted: true,
        replaceError: false
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_GET_SUCCESS:
      return {
        ...state,
        uploadProgress: false,
        recipient_list_data: action.payload,
        canReload: true,
        replaceProcessStarted: true,
        replaceError: false
      };

    case actionTypes.CLOSE_ALERT:
      return {
        ...state,
        fileError: '',
        fileTypeError: '',
        fileData: {},
        fileDownloadStatus: false,
        emailSendingStatus: false,
        testEmailSendingStatus: false
      };

    case actionTypes.SET_FIELD_VALUE:
      return {
        ...state,
        [action.payload.name]: {}
      };

    case actionTypes.EMAIL_FIELD_ERROR:
      return {
        ...state,
        [action.payload.name]: {
          value: action.payload.value,
          hasError: true
        }
      };

    case actionTypes.BLANK_FIELD_ERROR:
      return {
        ...state,
        [action.payload.name]: {
          hasBlank: true,
          name: action.payload.name
        }
      };

    case actionTypes.DOWNLOAD_RECIPIENT_FILE_SUCCESS:
      return {
        ...state,
        fileDownloadStatus: true,
        recipient_list_success_status: action.payload
      };

    case actionTypes.DOWNLOAD_RECIPIENT_FILE_PROGRESS:
      return {
        ...state,
        fileDownloadStatus: true,
        recipient_list_download_message: infoCode.FILE_DOWNLOAD_MESSAGE,
        recipient_list_success_status: '',
        emailSendingStatus: false
      };

    case actionTypes.GET_RECIPIENTS_LIST_SUCCESS:
      return {
        ...state,
        recipient_list_success_status: ''
      };

    case actionTypes.IS_EMAIL_SENDING:
      return {
        ...state,
        isEmailSending: true,
        emailSendingStatus: true,
        isEmailSent: false
      };

    case actionTypes.EMAIL_SEND:
      return {
        ...state,
        isEmailSending: false,
        isEmailSent: true,
        emailSendingStatus: true,
        email_sent_message: infoCode.EMAIL_SENT_MESSAGE,
        fileDownloadStatus: false,
        mail_body: {
          hasBlank: false
        },
        testEmailSendingStatus: false,
        sendingStatus: action.payload.sendingStatus
      };

    case actionTypes.SET_EMAIL_SEND_ERROR:
      return {
        ...state,
        isEmailSending: false,
        IsEmailSentError: true,
        email_sent_error_message: errorCode.EMAIL_SEND_ERROR
      };

    case actionTypes.SET_MAIL_BODY:
      return {
        ...state,
        isEditorHistoryEnabled: true,
        mail_body: {
          hasBlank: false
        }
      };

    case actionTypes.IS_TEST_EMAIL_SENDING:
      return {
        ...state,
        isTestEmailSending: true,
        testEmailSendingStatus: true,
        isTestEmailSent: false
      };

    case actionTypes.TEST_EMAIL_SEND:
      return {
        ...state,
        isTestEmailSending: false,
        isTestEmailSent: true,
        testEmailSendingStatus: true,
        test_email_sent_message: infoCode.TEST_EMAIL_SENT_MESSAGE
      };

    case actionTypes.TEST_EMAIL_SEND_ERROR:
      return {
        ...state,
        isTestEmailSending: false,
        IsTestEmailSentError: true,
        test_email_error_message: errorCode.TEST_EMAIL_ERROR
      };

    case actionTypes.SET_ACTIVE_PREVIEW_TAB:
      return {
        ...state,
        activeTabType: action.payload
      };

    case actionTypes.SHOW_FORM_DETAILS:
      return {
        ...state,
        showDetails: !state.showDetails
      };

    case actionTypes.TOGGLE_RECIPIENT_MODAL:
      return {
        ...state,
        fileData: {},
        fileTypeError: '',
        fileError: '',
        recipient_list_data_error: ''
      };

    case actionTypes.REMOVE_FILE_DATA:
      return {
        ...state,
        fileData: {},
        fileError: '',
        fileTypeError: '',
        recipient_list_data_error: ''
      };

    case actionTypes.GETTING_DOWNLOAD_LINK:
      return {
        ...state,
        isGettingDownloadLink: true
      };

    case actionTypes.GOT_DOWNLOAD_LINK:
      return {
        ...state,
        isGettingDownloadLink: false
      };

    case actionTypes.SET_RECIPIENT_EDIT_STATE:
      return {
        ...state,
        isEditable: action.payload
      };

    case actionTypes.SAVE_RECIPIENT_STATE_ERROR:
      return {
        ...state,
        errorMessage: action.payload.msg
      }
    case actionTypes.SET_SESSION_ENSURE:
      return {
        ...state,
        isSessionEnsured: false
      };

    default:
      return {
        ...state
      };
  }
};

export default commonReducer;
