import React, { Fragment } from 'react';
import { Modal } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { toggleLaunchModal } from 'store/actions/common';

import ModalHeader from 'components/header/modal/ModalHeader';
import ModalBody from 'components/header/modal/ModalBody';
import ModalFooter from 'components/header/modal/ModalFooter';

const LaunchModal = props => {
  const { campaign, handleLaunchModal } = props,
    { isLaunchModalShow } = campaign;

  return (
    <Fragment>
      <Modal size="lg" open={isLaunchModalShow} onClose={handleLaunchModal}>
        <ModalHeader />
        <ModalBody />
        <ModalFooter />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleLaunchModal: from => dispatch(toggleLaunchModal(from))
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LaunchModal)
);
