import store from 'store';
import { setSessionEnsured } from 'store/actions/common';
import  constants from './../constants';

export const initializeAuthentication = async restApi => {
  /*
    -- Hack --
    Cookies are not shared... because the parent application and campaign manager server's are on different domains.
    Hence we are extracting
    1) JWT from the URL query string and then creating a copy of that in a cookie
    2) Campaign ID from URL / URL params, because there's a check in the backend which checks for the campaign existence in every request.)
  */
  if (window.location.search) {
    var searchParams = new URLSearchParams(window.location.search);
    let cookieValue = searchParams.get('ck');
    document.cookie = `CM_AUTH=${cookieValue}; Path=/;`;
    if (searchParams.get('campId')) {
      let camp_id = searchParams.get('campId');
      let surveyState = searchParams.get('state');
      
      window.localStorage.setItem(constants.SURVEY_STATE, surveyState);
      await restApi.post('/campaign/setSession', { camp_id }).then(() => {
        store.dispatch(setSessionEnsured());
      });
    }
    window.history.pushState(
      {},
      document.title,
      window.location.origin + window.location.pathname
    );
  } else {
    // Extracting campaign ID from URL params.
    let camp_id;
    const campUrlRegX = /^\/?campaign\/\d*$/;
    const campPreviewUrlRegX = /^\/?campaign\/preview\/\d*$/;
    if (campUrlRegX.test(window.location.pathname)) {
      camp_id = window.location.pathname.replace(/^\/?campaign\//, '');
    } else if (campPreviewUrlRegX.test(window.location.pathname)) {
      camp_id = window.location.pathname.replace(/^\/?campaign\/preview\//, '');
    }
    if (camp_id) {
      await restApi.post('/campaign/setSession', { camp_id }).then(() => {
        store.dispatch(setSessionEnsured());
      });
    } else {
      store.dispatch(setSessionEnsured());
    }
  }
};
