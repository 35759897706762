import React from 'react';
import { AlertTriangleIcon } from 'stylist';

const Unauthorized = props => {
  return (
    <div className="preview-container relative unauthorize-container p-0">
      <div className="position-absolute w-100" />
      <div className="position-absolute w-100" />
      <div className="unsubscribe-screens unauthorize-screen">
        <p className="text-center">
          <AlertTriangleIcon size="xl" className="alertTriangle w-25" />
        </p>
        <h2 className="text-center mb-4">Access Denied</h2>
        <p className="text-center">
          You don't have access to this page.
        </p>
      </div>
    </div>
  );
};

export default Unauthorized;
