import actionTypes from 'store/actionTypes';
import { updateSeedList } from 'store/actions/common';
import store from 'store';
import { graphqlApi } from 'api';
import { SEND_EMAIL } from 'api/mutations';
import { errorCode } from 'utils/errorCodes';

export const addNewRow = (seedList, row, currRow) => {
  if (seedList.length > 0) {
    const keys = Object.keys(seedList[0].data);

    let rows = {};

    rows.data = {};
    rows.row_id = -1 + currRow.row_id;

    for (let i = 0; i < keys.length; i++) {
      rows.data[keys[i]] = '';
    }

    return {
      type: actionTypes.ADD_NEW_ROW_IN_SEED_LIST,
      payload: rows
    };
  } else {
    return {
      type: actionTypes.ADD_NEW_ROW_IN_SEED_LIST,
      payload: row
    };
  }
};

export const handleDeleteRow = (i, row_id, seedList) => {
  if (seedList.length === 1) {
    return null;
  } else {
    return {
      type: actionTypes.DELETE_ROW_IN_SEED_LIST,
      payload: {
        i,
        row_id
      }
    };
  }
};

export const handleChange = (e, index, key, seedList) => {
  const { value } = e.target;

  let tempArray = seedList;

  tempArray[index].data[key] = value;

  return {
    type: actionTypes.SET_SEED_LIST_ROW_FIELD_VALUE,
    payload: tempArray
  };
};

export const checkBoxStateAll = (e, seedList) => {
  let { id, checked } = e.target,
    selectedIndex = {};

  for (let i = 0; i < seedList.length; i++) {
    selectedIndex[i] = true;
  }

  return {
    type: actionTypes.SET_CHECKBOX_STATE_ALL,
    payload: { id, checked, selectedIndex }
  };
};

export const checkBoxState = (e, rid) => {
  let { id, checked } = e.target;

  return {
    type: actionTypes.SET_CHECKBOX_STATE,
    payload: { id, checked, rid }
  };
};

export const handleContainerState = (
  id,
  deletedRows,
  showListContainer,
  seedList
) => dispatch => {
  if (showListContainer) {
    dispatch(handleCheckFields(id, deletedRows, seedList));

    return dispatch({ type: actionTypes.SHOW_HIDE_SEED_LIST });
  } else {
    return dispatch({ type: actionTypes.SHOW_HIDE_SEED_LIST });
  }
};

export const handleCheckFields = (id, deletedRows, list) => dispatch => {
  const { isSeedListDirty } = store.getState().campaign;

  if (
    (list && deletedRows && list.some(l => l.row_id < 0)) ||
    deletedRows.length > 0 ||
    isSeedListDirty
  ) {
    return dispatch(updateSeedList(id, deletedRows, list));
  }
};

export const handleTestEmail = (
  id,
  testMailType,
  selectedIndex,
  launchTime,
  launchNow
) => dispatch => {
  const { seedList, selectAllSeedRows } = store.getState().campaign;
  let isListValid = false,
    selectedRows = [];

  if (testMailType) {
    isListValid = true;
  }

  if (
    (selectedIndex && Object.values(selectedIndex).some(s => s === true)) ||
    selectAllSeedRows
  ) {
    for (let i in selectedIndex) {
      if (seedList[i] && selectedIndex[i] === true) {
        selectedRows.push(seedList[i].row_id);
      }
    }
    dispatch({ type: actionTypes.IS_TEST_EMAIL_SENDING }); 
    
    graphqlApi
      .mutate({
        mutation: SEND_EMAIL(),
        variables: { id, isListValid, selectedRows, launchTime, launchNow}
      })
      .then(resolved => {
        dispatch({
          type: actionTypes.TEST_EMAIL_SEND,
          payload: id
        });
        dispatch({ type: actionTypes.CLOSE_MODAL_POPUP });
      })
      .catch(rejected => {
        dispatch({
          type: actionTypes.TEST_EMAIL_SEND_ERROR,
          payload: errorCode.EMAIL_SEND_ERROR
        });
        dispatch({ type: actionTypes.CLOSE_MODAL_POPUP });
      });
  }
};
