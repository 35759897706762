import React, { Fragment } from 'react';
import { Button, Modal } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { handleTestEmail } from 'store/actions/seedList';
import {
  toggleLaunchModal,
  validateLaunchMail,
  validateTestMail,
  sendEmail,
  handleAfterValidationSuccess,
  surveyNotLive
} from 'store/actions/common';
import constants from './../../../constants';
const ModalFooter = props => {
  const {
      campaign,
      handleSendEmail,
      handleLaunchModal,
      handleLaunchValidation,
      handleTestValidation,
      handleTestEmail,
      handleAfterValidationSuccess,
      handleSurveyNotLiveModal
    } = props,
    {
      campaign: details,
      id,
      isValidationStarted,
      validateMail,
      coming_from,
      isSeedListUpdating,
      selectedIndex,
      step,
      launchTime,
      launchNow,
      finishButtonStatus
    } = campaign;

  const inputAlert =
    details.from.trim() === '' ||
    details.reply_to.trim() === '' ||
    details.subject.trim() === ''
      ? constants.VALIDATION_MESSAGE
      : null;
  const buttonStatus = !finishButtonStatus;

  return (
    <Fragment>
      <Modal.Footer className="footerButtons mt-4">
        <Button kind="ghost" onClick={handleLaunchModal}>
          Cancel
        </Button>
        {isValidationStarted ? (
          validateMail.from &&
          validateMail.reply_to &&
          validateMail.subject &&
          validateMail.list_data &&
          validateMail.field_merge &&
          validateMail.mail_body &&
          !inputAlert ? (
            coming_from === '' ? (
              step === 2 ? (
                <Button
                  kind="primary"
                  disabled={buttonStatus}
                  onClick={() => {
                    if (campaign.campaign.survey_state === constants.SURVEY_STATE_TRUE) {
                      handleSendEmail(
                        id,
                        coming_from,
                        details.back_url,
                        details.list,
                        launchTime,
                      launchNow
                      );
                    } else {
                      handleLaunchModal();
                      handleSurveyNotLiveModal();
                    }
                  }}>
                  Finish
                </Button>
              ) : (
                <Button
                  kind="primary"
                  onClick={() => handleAfterValidationSuccess()}>
                  Proceed
                </Button>
              )
            ) : (
              <Button
                kind="primary"
                onClick={() =>
                  handleTestEmail(
                    id,
                    coming_from,
                    selectedIndex,
                    launchTime,
                    launchNow
                  )
                }>
                Finish
              </Button>
            )
          ) : (
            <Button kind="standard" onClick={handleLaunchModal}>
              Edit Email
            </Button>
          )
        ) : coming_from === '' ? (
          <Button
            kind="primary"
            onClick={() => handleLaunchValidation(id, coming_from)}>
            Proceed
          </Button>
        ) : isSeedListUpdating ? (
          <Button
            kind="primary"
            disabled
            onClick={() => handleTestValidation(id, coming_from)}>
            Proceed
          </Button>
        ) : (
          <Button
            kind="primary"
            onClick={() => handleTestValidation(id, coming_from)}>
            Proceed
          </Button>
        )}
      </Modal.Footer>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleAfterValidationSuccess: () => dispatch(handleAfterValidationSuccess()),
  handleSendEmail: (id, launchType, back_url, list, launchTime, launchNow) =>
    dispatch(sendEmail(id, launchType, back_url, list, launchTime, launchNow)),
  handleTestEmail: (id, testMailType, selectedIndex, launchTime, launchNow) =>
    dispatch(
      handleTestEmail(id, testMailType, selectedIndex, launchTime, launchNow)
    ),
  handleLaunchModal: from => dispatch(toggleLaunchModal(from)),
  handleSurveyNotLiveModal: from => dispatch(surveyNotLive(from)),
  handleLaunchValidation: (id, launchType) =>
    dispatch(validateLaunchMail(id, launchType)),
  handleTestValidation: (id, testMailType) =>
    dispatch(validateTestMail(id, testMailType))
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalFooter)
);
