import actionTypes from 'store/actionTypes';
import { errorCode } from 'utils/errorCodes';

const initialState = {
  isCampaignDataFetching: true,
  isSeedListFetching: true,
  id: null,
  survey: '',
  campaign: {},
  oldCampaign: {},
  seedList: [],
  recipients: [],
  isDetailsError: false,
  isRecipientsFetching: false,
  showListContainer: false,
  row: {},
  deletedRows: [],
  seedListCount: null,
  currRow: { row_id: -1 },
  fieldMergeList: [],
  showFieldMergeList: false,
  isMailBodySaving: false,
  isMailBodySaved: false,
  showRecipientModal: false,
  selectAllSeedRows: false,
  isLaunchModalShow: false,
  isSurveyNotLiveModal: false,
  recipient_terms: false,
  isListValid: false,
  step: 0,
  finishButtonStatus: true,
  scheduleTimeStatus: false,
  launchTime: new Date(),
  launchNow: true,
  isValidationStarted: false,
  validateMail: {},
  isTestModalShow: false,
  coming_from: '',
  validateError: false,
  validate_email_error_message: '',
  isRecipientFileDeleted: false,
  isRecipientListDeleting: false,
  selectedIndex: {},
  isCampaignDeleting: false,
  deleteCampaignErrorMsg: '',
  isSeedListUpdating: false,
  deleteCampaignDataError: false,
  deleteCampaignStatus: false,
  deleteRecipientError: false,
  deleteRecipientStatus: false,
  deleteRecipientErrorMsg: '',
  deletionRecipientStarted: false,
  deletionCampaignStarted: false,
  deleteCampaignError: false,
  isSeedListDirty: false,
  deletionError: false,
  isImageUploading: false,
  files: [],
  isImageDeleting: false,
  imgID: '',
  gettingImages: false,
  isImageDeleted: false,
  iscampaignDeleted: false,
  radioButton: true,
  totalRecipients: 0,
  currentPage: 1,
  filterInput: '',
  hasFilterApplied: false,
  validateImageErrorMessage: ''
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CAMPAIGN_DETAILS_BY_ID:
      return {
        ...state,
        id: action.payload.id,
        campaign: action.payload.data,
        isCampaignDataFetching: false,
        oldCampaign: action.payload.data,
        survey: action.payload.survey
      };

    case actionTypes.GET_CAMPAIGN_DETAILS_BY_ID_ERROR:
      return {
        ...state,
        isDetailsError: true,
        isCampaignDataFetching: false,
        idNotFound: action.payload
      };

    case actionTypes.GET_SEED_LIST:
      return {
        ...state,
        seedList: action.payload.data,
        seedListCount: action.payload.listCount,
        isSeedListFetching: false
      };

    case actionTypes.GET_SEED_LIST_ERROR:
      return {
        ...state,
        isSeedListFetching: false
      };

    case actionTypes.IS_SEED_LIST_UPDATING:
      return {
        ...state,
        isSeedListUpdating: true
      };

    case actionTypes.UPDATE_SEED_LIST:
      return {
        ...state,
        seedList: action.payload.data,
        seedListCount: action.payload.listCount,
        deletedRows: [],
        currRow: { row_id: -1 },
        isSeedListUpdating: false,
        isSeedListDirty: false
      };

    case actionTypes.UPDATE_AFTER_FIELD_VALUE_CHANGE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          ...action.payload
        },
        oldCampaign: {
          ...state.campaign,
          ...action.payload
        }
      };

    case actionTypes.MAIL_BODY_SAVED:
      return {
        ...state,
        isMailBodySaving: false,
        isMailBodySaved: true
      };

    case actionTypes.HIDE_SAVE_MESSAGE:
      return {
        ...state,
        isMailBodySaving: false,
        isMailBodySaved: false
      };

    case actionTypes.GET_RECIPIENTS_LIST_PROGRESS:
      return {
        ...state,
        isRecipientsFetching: true
      };

    case actionTypes.GET_RECIPIENTS_LIST_SUCCESS:
      return {
        ...state,
        recipients: action.payload.recipientData,
        isRecipientsFetching: false,
        totalRecipients: Math.ceil(action.payload.count / 10),
        currentPage: action.payload.offset,
        filterInput: action.payload.filterString,
        hasFilterApplied: action.payload.hasFilterApplied
      };

    case actionTypes.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload
      };

    case actionTypes.GET_RECIPIENTS_LIST_ERROR:
      return {
        ...state,
        isRecipientsFetching: false
      };

    case actionTypes.SET_FIELD_VALUE:
    case actionTypes.EMAIL_FIELD_ERROR:
    case actionTypes.BLANK_FIELD_ERROR:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          [action.payload.name]: action.payload.value
        }
      };

    case actionTypes.SET_MAIL_BODY:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          mail_body: action.payload
        },
        isMailBodySaving: false,
        isMailBodySaved: false
      };

    case actionTypes.SHOW_HIDE_SEED_LIST:
      return {
        ...state,
        showListContainer: !state.showListContainer,
        seedList: state.seedList.filter((_, index) => {
          if (state.seedListCount > 0) {
            return _.row_id > 0;
          } else {
            return _.row_id === -1;
          }
        })
      };

    case actionTypes.ADD_NEW_ROW_IN_SEED_LIST:
      return {
        ...state,
        seedList: [...state.seedList, action.payload],
        currRow: action.payload
      };

    case actionTypes.DELETE_ROW_IN_SEED_LIST:
      return {
        ...state,
        seedList: state.seedList.filter(
          (_, index) => index !== action.payload.i
        ),
        deletedRows:
          action.payload.row_id < 0
            ? state.deletedRows
            : [...state.deletedRows, action.payload.row_id],
        selectedIndex:
          delete state.selectedIndex[action.payload.i] && state.selectedIndex
      };

    case actionTypes.SET_SEED_LIST_ROW_FIELD_VALUE:
      return {
        ...state,
        seedList: action.payload,
        isSeedListDirty: true
      };

    case actionTypes.SEED_LIST_HAS_ERROR:
      return {
        ...state,
        seedListWithError: action.payload.newList
      };

    case actionTypes.GET_FIELD_MERGE_LIST:
      return {
        ...state,
        fieldMergeList: action.payload,
        showFieldMergeList: true
      };

    case actionTypes.MAIL_BODY_SAVING:
      return {
        ...state,
        isMailBodySaving: true,
        isMailBodySaved: false
      };

    case actionTypes.TOGGLE_RECIPIENT_MODAL:
      return {
        ...state,
        showRecipientModal: !state.showRecipientModal,
        recipient_terms: false
      };

    case actionTypes.SET_CHECKBOX_STATE_ALL:
      return {
        ...state,
        [action.payload.id]: action.payload.checked,
        selectedIndex: action.payload.checked
          ? action.payload.selectedIndex
          : {}
      };

    case actionTypes.SET_CHECKBOX_STATE:
      return {
        ...state,
        selectedIndex: {
          ...state.selectedIndex,
          [action.payload.id]: action.payload.checked
        },
        selectAllSeedRows: false
      };

    case actionTypes.TEST_EMAIL_SEND:
      return {
        ...state,
        selectAllSeedRows: false,
        selectedIndex: {}
      };

    case actionTypes.TOGGLE_LAUNCH_MODAL:
      return {
        ...state,
        coming_from: action.payload,
        isLaunchModalShow: !state.isLaunchModalShow,
        isValidationStarted: false,
        step: 0,
        scheduleTimeStatus: false,
        finishButtonStatus: true,
        launchTime: new Date(),
        launchNow: true,
        radioButton: true,
      };

    case actionTypes.TOGGLE_SURVEY_NOT_LIVE_MODAL:
      return {
        ...state,
        isSurveyNotLiveModal: !state.isSurveyNotLiveModal
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_SUCCESS:
      return {
        ...state,
        showRecipientModal: false,
        campaign: {
          ...state.campaign,
          recipient_list: action.payload.recipient_list
        }
      };

    case actionTypes.UPLOAD_RECIPIENT_FILE_GET_SUCCESS:
      return {
        ...state,
        showRecipientModal: true
      };

    case actionTypes.VALIDATE_LAUNCH_MAIL:
    case actionTypes.VALIDATE_TEST_MAIL:
      return {
        ...state,
        isListValid: true,
        validateMail: action.payload,
        isValidationStarted: true,
        step: 1
      };

    case actionTypes.HANDLE_AFTER_VALIDATION_SUCCESS:
      return {
        ...state,
        step: 2
      };

    case actionTypes.HANDLE_LAUNCH_NOW_BUTTON:
      return {
        ...state,
        finishButtonStatus: true,
        scheduleTimeStatus: false,
        launchTime: new Date(),
        launchNow: true,
        radioButton: true
      };

    case actionTypes.HANDLE_SCHUDULE_BUTTON:
      return {
        ...state,
        scheduleTimeStatus: true,
        finishButtonStatus: false,
        launchNow: false,
        radioButton: false
      };

    case actionTypes.HANDLE_TIME_PICKER:
      return {
        ...state,
        scheduleTimeStatus: true,
        finishButtonStatus: true,
        launchTime: action.payload,
        launchNow: false
      };

    case actionTypes.EMAIL_VALIDATION_ERROR:
      return {
        ...state,
        validateError: true,
        validate_email_error_message: errorCode.VALIDATE_EMAIL_ERROR,
        isLaunchModalShow: false
      };

    case actionTypes.CLOSE_MODAL_POPUP:
      return {
        ...state,
        isLaunchModalShow: false
      };

    case actionTypes.DELETE_RECIPIENT_LIST_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          recipient_list: ''
        },
        recipients: [],
        isRecipientListDeleting: false,
        deletionRecipientStarted: false,
        deleteRecipientStatus: true
      };

    case actionTypes.DELETE_RECIPIENT_LIST_ERROR:
      return {
        ...state,
        isRecipientListDeleting: false,
        deleteRecipientErrorMsg: errorCode.DELETE_RECIPIENT_DATA_ERROR,
        deleteRecipientError: true,
        deletionRecipientStarted: true,
        deleteRecipientStatus: false,
        deletionError: true
      };

    case actionTypes.DELETING_RECIPIENT_DATA:
      return {
        ...state,
        isRecipientListDeleting: true,
        deletionRecipientStarted: true,
        deletionCampaignStarted: false,
        isCampaignDeleting: false
      };

    case actionTypes.DELETING_CAMPAIGN_DATA:
      return {
        ...state,
        isRecipientListDeleting: false,
        isCampaignDeleting: true,
        deleteCampaignDataError: false,
        deletionRecipientStarted: false,
        deletionCampaignStarted: true
      };

    case actionTypes.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isCampaignDeleting: true,
        deleteCampaignDataStatus: true,
        deletionProcessStarted: true,
        deleteCampaignStatus: true,
        deleteRecipientStatus: false,
        deletionCampaignStarted: true
      };

    case actionTypes.DELETE_CAMPAIGN_ERROR:
      return {
        ...state,
        isCampaignDeleting: false,
        deletionProcessStarted: true,
        deleteCampaignStatus: false,
        deleteCampaignErrorMsg: action.payload.msg
          ? action.payload.msg
          : errorCode.DELETE_CAMPAIGN_DATA_ERROR,
        deleteCampaignError: true,
        deletionError: true,
        isCampaignDeleted: action.payload.deleted === 404
      };

    case actionTypes.GET_IMAGE_FILES:
      return {
        ...state,
        files: action.payload,
        validateImageErrorMessage: ''
      };

    case actionTypes.GETTING_IMAGE:
      return {
        ...state,
        gettingImages: true
      };

    case actionTypes.GET_IMAGE:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          images: action.payload
        },
        gettingImages: false,
        validateImageErrorMessage: ''
      };

    case actionTypes.IMAGE_UPLOAD_PROGRESS:
      return {
        ...state,
        isImageUploading: true
      };

    case actionTypes.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        files: [],
        campaign: {
          ...state.campaign,
          images: action.payload
        },
        isImageUploading: false
      };

    case actionTypes.IMAGE_UPLOAD_ERROR:
      return {
        ...state,
        files: [],
        isImageUploading: false,
        validateImageErrorMessage: action.payload
      };

    case actionTypes.DELETING_IMAGE:
      return {
        ...state,
        isImageDeleting: true,
        imgID: action.payload,
        isImageDeleted: false
      };

    case actionTypes.DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        campaign: {
          ...state.campaign,
          images: action.payload
        },
        isImageDeleting: false,
        imgID: '',
        isImageDeleted: true
      };

    case actionTypes.UPDATE_RECIPIENT_LIST:
      return {
        ...state,
        recipients: action.payload
      };

    case actionTypes.EMAIL_SEND:
      return {
        ...state,
        isLaunchModalShow: false
      };

    default:
      return {
        ...state
      };
  }
};

export default campaignReducer;
