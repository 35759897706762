import React, { Fragment } from 'react';
import { Button, Tooltip } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { handleContainerState } from 'store/actions/seedList';
import constants from './../../constants';
import {
  toggleLaunchModal,
  saveFormField,
  getSeedList
} from 'store/actions/common';
import EditableLabel from 'components/EditableLabel';
import { STATUS } from 'utils/campaignStatus';

const NewCampaign = props => {
  const {
      campaign,
      common,
      handleContainerState,
      handleLaunchModal,
      handleSaveField,
      getSeedList
    } = props,
    {
      campaign: details,
      id,
      deletedRows,
      showListContainer,
      seedList
    } = campaign,
    { name, sendingStatus } = common;

  return (
    <Fragment>
      <div className="new-campaign">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <EditableLabel
              className="font-weight-bold mb-0"
              elementName="name"
              campaignId={id}
              campaignStatus={details.is_new}
              name={details.name}
              labelType="h2"
              handleSaveField={handleSaveField}
              isLaunched={details.campaign_status}
            />
            {name.hasBlank && (
              <span className="error-message d-block pt-1 px-0 w-50">
                {constants.LABEL_CAN_NOT_BE_BLANK}
              </span>
            )}
          </div>
          <div className="col-md-6">
            {details.campaign_status === STATUS.LAUNCHED ||
            details.campaign_status === STATUS.SENDING ||
            details.campaign_status === STATUS.PENDING ||
            sendingStatus === STATUS.SENDING ? (
              <div className="launchCampaign d-flex justify-content-end">
                <Button
                  id="test_email_btn"
                  kind="standard"
                  className="text-email disabled"
                  dark
                  disabled={true}>
                  {constants.TEST_EMAIL}
                </Button>
                <Tooltip
                  className=""
                  position="top"
                  label={
                    details.campaign_status === STATUS.LAUNCHED
                      ? ''
                      : constants.CAMPAIGN_IS_BEING_LAUNCHED
                  }
                  render={({ hide, show, ref }) =>
                    details.campaign_status === STATUS.LAUNCHED ? (
                      <Button
                        id="launch_email_btn"
                        kind="primary"
                        className="launchCampaignBtn">
                        {constants.SET_REMINDER}
                      </Button>
                    ) : (
                      <Button
                        innerRef={ref}
                        onMouseOver={show}
                        onMouseOut={hide}
                        id="launch_email_btn"
                        kind="primary"
                        className="launchCampaignBtn disabled"
                        disabled={true}>
                        {constants.LAUNCH_CAMPAIGN}
                      </Button>
                    )
                  }
                />
              </div>
            ) : (
              <div className="d-flex justify-content-end">
                <Button
                  id="test_email_btn"
                  kind="standard"
                  className="text-email"
                  dark
                  onClick={() => {
                    getSeedList(id);
                    handleContainerState(
                      id,
                      deletedRows,
                      showListContainer,
                      seedList
                    );
                  }}>
                  Test Email
                </Button>
                <Button
                  id="launch_email_btn"
                  kind="primary"
                  onClick={() => handleLaunchModal('')}>
                  Launch Campaign
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleContainerState: (id, deletedRows, showListContainer, seedList) =>
    dispatch(
      handleContainerState(id, deletedRows, showListContainer, seedList)
    ),
  handleLaunchModal: from => dispatch(toggleLaunchModal(from)),
  handleSaveField: (e, id, field) => dispatch(saveFormField(e, id, field)),
  getSeedList: id => dispatch(getSeedList(id))
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(NewCampaign)
);
