import React, { Fragment } from 'react';
import { ArrowIcon, Button } from 'stylist';
import constants from './../../constants';

const Composer = props => {
  const t = props.backButton,
    details = props.detailsData;

  return (
    <Fragment>
      <div className="back-composer d-flex align-items-center">
        {details.back_url ? (
          <a href={details.back_url} className="backBtn text-uppercase">
            <span className="back-arrow">
              <ArrowIcon size="xs" direction="left" /> {t('button.back')}
            </span>
          </a>
        ) : (
          <a href="#foo" className="backBtn">
            <span className="back-arrow">
              <ArrowIcon size="xs" direction="left" /> Back
            </span>
          </a>
        )}
        <span className="slash">|</span>
        <Button
          className="survey-name p-0"
          title={details.survey_name || constants.SURVEY_NAME}>
          {details.survey_name || constants.SURVEY_NAME}
        </Button>
      </div>
    </Fragment>
  );
};

export default Composer;
