import { Quill } from 'react-quill';
import store from 'store/index';

let Embed = Quill.import('blots/embed');

export default class SurveyOptOutBlot extends Embed {
  static create() {
    const node = super.create();

    this._optOutTextInsertion(node);
    this._optOutPreviewInsertion(node);

    return node;
  }

  static _optOutTextInsertion(node) {
    const optOutInsert = document.createElement('a');
    const href = `${store.getState().campaign.campaign.opt_out}`;
    const text = 'link:[surveyOptOut]';
    optOutInsert.setAttribute('href', href);
    optOutInsert.setAttribute('class', 'survey-editor-link');
    optOutInsert.innerHTML = text;
    node.appendChild(optOutInsert);
  }

  static _optOutPreviewInsertion(node) {
    const TagOptOutInsert = document.createElement('a');
    const href = `${store.getState().campaign.campaign.opt_out}`;
    TagOptOutInsert.setAttribute('href', href);
    TagOptOutInsert.setAttribute('target', '_blank');
    TagOptOutInsert.setAttribute('class', 'survey-preview-link');
    TagOptOutInsert.innerHTML = href;
    node.appendChild(TagOptOutInsert);
  }
}

SurveyOptOutBlot.blotName = 'surveyOptOut';
SurveyOptOutBlot.className = 'optout-insertion';
SurveyOptOutBlot.tagName = 'span';
