const actionTypes = {
  IS_ERROR_PAGE_RENDERED: 'IS_ERROR_PAGE_RENDERED',
  SHOW_HEADER_AFTER_NAVIGATE_FROM_ERROR_PAGE:
    'SHOW_HEADER_AFTER_NAVIGATE_FROM_ERROR_PAGE',
  CLOSE_ALERT: 'CLOSE_ALERT',

  GET_CAMPAIGN_DETAILS_BY_ID: 'GET_CAMPAIGN_DETAILS_BY_ID',
  GET_CAMPAIGN_DETAILS_BY_ID_ERROR: 'GET_CAMPAIGN_DETAILS_BY_ID_ERROR',

  UPDATE_AFTER_FIELD_VALUE_CHANGE: 'UPDATE_AFTER_FIELD_VALUE_CHANGE',
  SET_FIELD_VALUE: 'SET_FIELD_VALUE',
  EMAIL_FIELD_ERROR: 'EMAIL_FIELD_ERROR',
  BLANK_FIELD_ERROR: 'BLANK_FIELD_ERROR',
  FIELD_UPDATE_ERROR: 'FIELD_UPDATE_ERROR',

  GET_FILE_OBJECT: 'GET_FILE_OBJECT',
  GET_FILE_OBJECT_ERROR: 'GET_FILE_OBJECT_ERROR',
  FILE_VALIDATION_ERROR: 'FILE_VALIDATION_ERROR',

  GET_SEED_LIST: 'GET_SEED_LIST',
  GET_SEED_LIST_ERROR: 'GET_SEED_LIST_ERROR',
  IS_SEED_LIST_UPDATING: 'IS_SEED_LIST_UPDATING',
  UPDATE_SEED_LIST: 'UPDATE_SEED_LIST',
  UPDATE_SEED_LIST_ERROR: 'UPDATE_SEED_LIST_ERROR',

  UPLOAD_RECIPIENT_FILE_PROGRESS: 'UPLOAD_RECIPIENT_FILE_PROGRESS',
  UPLOAD_RECIPIENT_FILE_SUCCESS: 'UPLOAD_RECIPIENT_FILE_SUCCESS',
  UPLOAD_RECIPIENT_FILE_GET_SUCCESS: 'UPLOAD_RECIPIENT_FILE_GET_SUCCESS',
  UPLOAD_RECIPIENT_FILE_ERROR: 'UPLOAD_RECIPIENT_FILE_ERROR',

  DOWNLOAD_RECIPIENT_FILE_PROGRESS: 'DOWNLOAD_RECIPIENT_FILE_PROGRESS',
  DOWNLOAD_RECIPIENT_FILE: 'DOWNLOAD_RECIPIENT_FILE',
  DOWNLOAD_RECIPIENT_FILE_SUCCESS: 'DOWNLOAD_RECIPIENT_FILE_SUCCESS',
  DOWNLOAD_RECIPIENT_FILE_ERROR: 'DOWNLOAD_RECIPIENT_FILE_ERROR',

  GET_RECIPIENTS_LIST_PROGRESS: 'GET_RECIPIENTS_LIST_PROGRESS',
  GET_RECIPIENTS_LIST_ERROR: 'GET_RECIPIENTS_LIST_ERROR',
  GET_RECIPIENTS_LIST_SUCCESS: 'GET_RECIPIENTS_LIST_SUCCESS',

  SET_MAIL_BODY: 'SET_MAIL_BODY',
  EMAIL_SEND: 'EMAIL_SEND',
  SET_EMAIL_SEND_ERROR: 'SET_EMAIL_SEND_ERROR',
  IS_EMAIL_SENDING: 'IS_EMAIL_SENDING',
  MAIL_BODY_SAVING: 'MAIL_BODY_SAVING',
  MAIL_BODY_SAVED: 'MAIL_BODY_SAVED',
  HIDE_SAVE_MESSAGE: 'HIDE_SAVE_MESSAGE',

  SHOW_HIDE_SEED_LIST: 'SHOW_HIDE_SEED_LIST',
  ADD_NEW_ROW_IN_SEED_LIST: 'ADD_NEW_ROW_IN_SEED_LIST',
  DELETE_ROW_IN_SEED_LIST: 'DELETE_ROW_IN_SEED_LIST',
  SET_SEED_LIST_ROW_FIELD_VALUE: 'SET_SEED_LIST_ROW_FIELD_VALUE',
  SEED_LIST_HAS_ERROR: 'SEED_LIST_HAS_ERROR',

  TEST_EMAIL_SEND: 'TEST_EMAIL_SEND',
  TEST_EMAIL_SEND_ERROR: 'TEST_EMAIL_SEND_ERROR',
  IS_TEST_EMAIL_SENDING: 'IS_TEST_EMAIL_SENDING',

  GET_FIELD_MERGE_LIST: 'GET_FIELD_MERGE_LIST',
  GET_FIELD_MERGE_LIST_ERROR: 'GET_FIELD_MERGE_LIST_ERROR',

  SET_ACTIVE_PREVIEW_TAB: 'SET_ACTIVE_PREVIEW_TAB',
  SHOW_FORM_DETAILS: 'SHOW_FORM_DETAILS',

  TOGGLE_RECIPIENT_MODAL: 'TOGGLE_RECIPIENT_MODAL',
  TOGGLE_LAUNCH_MODAL: 'TOGGLE_LAUNCH_MODAL',
  TOGGLE_SURVEY_NOT_LIVE_MODAL: 'TOGGLE_SURVEY_NOT_LIVE_MODAL',

  CLOSE_MODAL_POPUP: 'CLOSE_MODAL_POPUP',

  VALIDATE_LAUNCH_MAIL: 'VALIDATE_LAUNCH_MAIL',
  HANDLE_AFTER_VALIDATION_SUCCESS: 'HANDLE_AFTER_VALIDATION_SUCCESS',
  HANDLE_LAUNCH_NOW_BUTTON: 'HANDLE_LAUNCH_NOW_BUTTON',
  HANDLE_SCHUDULE_BUTTON: 'HANDLE_SCHUDULE_BUTTON',
  HANDLE_TIME_PICKER: 'HANDLE_TIME_PICKER',

  VALIDATE_TEST_MAIL: 'VALIDATE_TEST_MAIL',
  EMAIL_VALIDATION_ERROR: 'EMAIL_VALIDATION_ERROR',
  VALIDATE_MAIL_ERROR: 'VALIDATE_MAIL_ERROR',

  SET_CHECKBOX_STATE: 'SET_CHECKBOX_STATE',
  SET_CHECKBOX_STATE_ALL: 'SET_CHECKBOX_STATE_ALL',

  REMOVE_FILE_DATA: 'REMOVE_FILE_DATA',
  DELETE_RECIPIENT_LIST_SUCCESS: 'DELETE_RECIPIENT_LIST_SUCCESS',
  DELETE_RECIPIENT_LIST_ERROR: 'DELETE_RECIPIENT_LIST_ERROR',
  DELETING_RECIPIENT_DATA: 'DELETING_RECIPIENT_DATA',

  DELETING_CAMPAIGN_DATA: 'DELETING_CAMPAIGN_DATA',
  DELETE_CAMPAIGN_SUCCESS: 'DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_ERROR: 'DELETE_CAMPAIGN_ERROR',

  GETTING_IMAGE: 'GETTING_IMAGE',
  GET_IMAGE_FILES: 'GET_IMAGE_FILES',
  GET_IMAGE: 'GET_IMAGE',
  GET_IMAGE_ERR: 'GET_IMAGE_FILES_ERR',
  IMAGE_UPLOAD_PROGRESS: 'IMAGE_UPLOAD_PROGRESS',
  IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
  IMAGE_UPLOAD_ERROR: 'IMAGE_UPLOAD_ERROR',

  DELETING_IMAGE: 'DELETING_IMAGE',
  DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',

  GET_EXPORT_STATUS_SUCCESS: 'GET_EXPORT_STATUS_SUCCESS',
  GET_EXPORT_STATUS_ERROR: 'GET_EXPORT_STATUS_ERROR',
  GETTING_EXPORT_STATUS: 'GETTING_EXPORT_STATUS',
  GETTING_DOWNLOAD_LINK: 'GETTING_DOWNLOAD_LINK',
  GOT_DOWNLOAD_LINK: 'GOT_DOWNLOAD_LINK',
  SET_RECIPIENT_EDIT_STATE: 'SET_RECIPIENT_EDIT_STATE',
  SAVE_RECIPIENT_STATE_ERROR: 'SAVE_RECIPIENT_STATE_ERROR',
  UPDATE_RECIPIENT_LIST: 'UPDATE_RECIPIENT_LIST',
  SET_SESSION_ENSURE: 'SET_SESSION_ENSURE',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE'
};

export default actionTypes;
