import i18n from 'i18next';
import en from 'i18n/languages/en.json';
import es from 'i18n/languages/es.json';

i18n.init({
  interpolation: {
    escapeValue: false
  },
  lng: 'en',
  resources: {
    en: {
      res: en
    },
    es: {
      res: es
    }
  }
});

export default i18n;
