import { Quill } from 'react-quill';
let Embed = Quill.import('blots/embed');

export default class EmojiBlot extends Embed {
  static create(value) {
    const node = super.create(value);

    node.innerHTML = value;

    return node;
  }

  static value(node) {
    return node.textContent;
  }
}

EmojiBlot.blotName = 'emoji';
EmojiBlot.tagName = 'span';
EmojiBlot.className = 'emoji-text';
