import React, { Fragment, useState } from 'react';
import {
  Button,
  Uploader,
  Modal,
  Tooltip,
  Checkbox,
  Badge,
  FileIcon,
  CancelIcon,
  Alert,
  Loader
} from 'stylist';
import { instructionCode } from 'utils/instructionCodes';
import PopUp from 'components/PopUp';

const FileUpload = props => {
  const {
      uploadProgress,
      handleBrowseFile,
      match,
      fileData,
      fileError,
      fileTypeError,
      recipient_list_data_error,
      showRecipientModal,
      handleRecipientModal,
      campaign,
      handleSaveRecipientList,
      handleFileRemove,
      replaceProcessStarted,
      replaceError,
      recipient_list_data,
      deleteCampaignError,
      deleteCampaignErrorMsg,
      isCampaignDeleted
    } = props,
    errMsg =
      fileError.error ||
      fileError ||
      fileTypeError ||
      recipient_list_data_error ||
      '',
    [checkBox, setCheckBox] = useState(false),
    [showDetails, setShowDetails] = useState(false),
    [replacePopup, setReplacePopup] = useState(false);

  function deleteCampaignErrorAction() {
    if (isCampaignDeleted) {
      window.location = campaign.back_url;
    } else {
      window.location.reload();
    }
  }

  const spamLink = (
    <a
      href="https://decipher.zendesk.com/hc/en-us/articles/360010275973"
      target="_blank"
      rel="noopener noreferrer">
      Spam Reduction.
    </a>
  );

  return (
    <Fragment>
      <Modal
        open={showRecipientModal}
        onClose={() => {
          handleRecipientModal();
          setCheckBox(false);
          setShowDetails(false);
        }}
        dismissable={false}>
        <Modal.Header>
          <Modal.Title className="modal-header-title">
            {campaign.recipient_list ? 'Replace Recipients' : 'Add Recipients'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalPopup position-relative">
          {uploadProgress ? (
            <div className="d-flex">
              <p>Uploading recipient list. Please wait..</p>
            </div>
          ) : (
            <div className="d-flex">
              <p>
                Upload your recipient list below.
              </p>
              <span className="instructions">
                <Tooltip
                  position="top"
                  label={instructionCode.map((inst, index) => (
                    <p key={index}>{inst}</p>
                  ))}
                  render={({ hide, show, ref }) => (
                    <Button
                      kind="utility"
                      innerRef={ref}
                      onMouseOver={show}
                      onMouseOut={hide}
                      className="infoIcon">
                      <span className="d-flex align-items-center justify-content-center">
                        i
                      </span>
                    </Button>
                  )}
                />
              </span>
            </div>
          )}
          {showRecipientModal && uploadProgress ? (
            <div>
              <Loader centered />
            </div>
          ) : (
            <Fragment>
              {errMsg && (
                <Alert
                  className="mb-3 error-message"
                  kind="error"
                  message={errMsg}
                />
              )}
              {fileData.name ? (
                <div className="uploadComplete">
                  <div
                    className={
                      showDetails
                        ? 'fileNameContainer offwhitebg'
                        : 'fileNameContainer'
                    }>
                    <Badge solid color="ink" className="filename-badge">
                      <FileIcon className="fileIcon ml-0" />
                      <span className="fileName" title={fileData.name}>
                        {fileData.name}
                      </span>
                      <Button
                        title="Delete"
                        className="fileCancel p-0 align-top position-relative"
                        onClick={() => {
                          handleFileRemove();
                          setShowDetails(false);
                          setCheckBox(false);
                        }}>
                        <CancelIcon
                          size="xs"
                          className="deleteFileIcon"
                        />
                      </Button>
                    </Badge>
                    {fileData && recipient_list_data_error === '' && (
                      <p
                        className="showHideText mt-2 mb-2 text-center"
                        onClick={() => {
                          setShowDetails(!showDetails);
                        }}>
                        {showDetails ? 'Hide List Details' : 'Show Details'}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="uploadContainer">
                  <Uploader
                    accept=".csv"
                    onChange={file => handleBrowseFile(file)}
                    value={fileData.name}
                  />
                  <div className="d-flex justify-content-center">
                    <em
                      className={
                        fileTypeError
                          ? 'fileInstruction text-danger'
                          : 'fileInstruction'
                      }>
                      .csv files only
                    </em>
                  </div>
                </div>
              )}
              {showDetails && (
                <div className="rr-list">
                  <div className="recipient-response-list mb-0">
                    <p>
                      Invalid/Blank emails in list:
                      {recipient_list_data &&
                        recipient_list_data.invalid_emails}
                    </p>
                    <p>
                      Valid emails in list:
                      {recipient_list_data && recipient_list_data.valid_emails}
                    </p>
                    <div className="tableContainer">
                      <table className="add-recipients-table">
                        <thead>
                          <tr>
                            <th>Imported Variables</th>
                            <th>Records with Data</th>
                          </tr>
                        </thead>
                        <tbody>
                          {recipient_list_data &&
                            recipient_list_data.details &&
                            Object.keys(recipient_list_data.details).map(
                              (k, i) => {
                                return (
                                  <tr key={i}>
                                    <td>{k}</td>
                                    <td>{recipient_list_data.details[k]}</td>
                                  </tr>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-3 d-flex">
                <Checkbox
                  id="recipient_terms"
                  className="recipient_terms"
                  checked={checkBox}
                  onChange={e => setCheckBox(!checkBox)}
                />
                <div className="recipient_terms_label">
                  <label htmlFor="recipient_terms" className="mb-0">
                    <span>
                      All recipients on this list have agreed to opt in to this
                      method of communication from me. I have reviewed the
                      documentation on {spamLink}
                    </span>
                  </label>
                </div>
              </div>
            </Fragment>
          )}
        </Modal.Body>

        <Modal.Footer className="footerButtons mt-4">
          {!uploadProgress && (
            <Fragment>
              <Button
                kind="utility"
                onClick={() => {
                  setShowDetails(false);
                  handleRecipientModal();
                  setCheckBox(false);
                }}>
                Cancel
              </Button>
              {campaign.recipient_list ? (
                <Button
                  kind="primary"
                  disabled={
                    !fileData || !checkBox || errMsg !== '' ? true : false
                  }
                  onClick={() => {
                    setReplacePopup(!replacePopup);
                    setCheckBox(false);
                    setShowDetails(false);
                  }}>
                  Save Recipients
                </Button>
              ) : (
                <Button
                  kind="primary"
                  title={errMsg}
                  disabled={
                    !fileData.name || !checkBox || errMsg !== '' ? true : false
                  }
                  onClick={() => {
                    handleSaveRecipientList(fileData, match.params.id, false);
                    setCheckBox(false);
                    setShowDetails(false);
                  }}>
                  Save Recipients
                </Button>
              )}
              {campaign.recipient_list && (
                <PopUp
                  headerTitle="Replace Old File with New One"
                  dialogBody={`Are you sure you want to delete '${
                    campaign.recipient_list
                  }' and replace it with '${fileData && fileData.name}' ?`}
                  cancelButton="Cancel"
                  confirmButton="Replace"
                  open={replacePopup}
                  isProcessing={showRecipientModal && uploadProgress}
                  deletionErrorMsg={errMsg}
                  deletionProcessStarted={replaceProcessStarted}
                  deletionError={replaceError}
                  onConfirm={() => {
                    handleSaveRecipientList(fileData, match.params.id, false);
                    setReplacePopup(false);
                    setCheckBox(false);
                  }}
                  onCancel={() => setReplacePopup(!replacePopup)}
                />
              )}
            </Fragment>
          )}
        </Modal.Footer>
      </Modal>
      {deleteCampaignError && (
        <Modal
          open={deleteCampaignError}
          onClose={() => {
            deleteCampaignErrorAction();
          }}>
          <Modal.Header>
            <Modal.Title className="modal-header-title">
              Campaign Error
            </Modal.Title>
          </Modal.Header>
          {
            <Fragment>
              <Modal.Body className="modalPopup delete-popup">
                <p>{deleteCampaignErrorMsg}</p>
              </Modal.Body>
              <Modal.Footer className="footerButtons mt-4">
                <Button
                  kind="danger"
                  onClick={() => {
                    deleteCampaignErrorAction();
                  }}>
                  ok
                </Button>
              </Modal.Footer>
            </Fragment>
          }
        </Modal>
      )}
    </Fragment>
  );
};

export default FileUpload;
