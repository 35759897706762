import React, { Fragment, Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import DOMPurify from 'dompurify';
import 'quill-mention';
import {
  getFieldMergeValues,
  getFiles,
  uploadImages,
  getFieldVars
} from 'store/actions/common';
import AlertNotifier from 'components/AlertNotifier';
import store from 'store/index';
import { getFileExtension, getFileSize } from 'utils/fileValidator';
import Emoji from 'components/Emoji';
import Toolbar from 'components/campaignEditor/toolbar/Toolbar';
import SurveyLinkBlot from 'components/campaignEditor/toolbar/blots/SurveyLinkBlot';
import { IndentStyle } from 'components/campaignEditor/toolbar/blots/IndentStyle';
import DividerBlot from 'components/campaignEditor/toolbar/blots/DividerBlot';
import FieldTagBlot from 'components/campaignEditor/toolbar/blots/FieldTagBlot';
import CallToActionBlot from 'components/campaignEditor/toolbar/blots/CallToActionBlot';
import SurveyOptOutBlot from 'components/campaignEditor/toolbar/blots/SurveyOptOutBlot';
import ImageFormat from 'components/campaignEditor/toolbar/blots/ImageFormat';
import EmojiBlot from 'components/campaignEditor/toolbar/blots/EmojiBlot';
import UtfBlot from 'components/campaignEditor/toolbar/blots/UtfBlot';
import InsertLink from 'components/campaignEditor/modals/InsertLink';
import FieldMergeModal from 'components/campaignEditor/modals/FieldMergeModal';
import SourceCodeModal from 'components/campaignEditor/modals/SourceCodeModal';
import ImageModal from 'components/campaignEditor/modals/ImageModal';
import ButtonModal from 'components/campaignEditor/modals/ButtonModal';
import {
  CampaignEditorQuillformats,
  Whitelist
} from 'constants/CampaignEditorQuillConstants';
import { LANGUAGE } from 'constants/LanguageConstant';
window.Quill = Quill;

const ImageResize = require('quill-image-resize-module').default;

let Font = Quill.import('formats/font');

Font.whitelist = Whitelist;

Quill.register(IndentStyle);
Quill.register(DividerBlot);
Quill.register(FieldTagBlot);
Quill.register(CallToActionBlot);
Quill.register(SurveyLinkBlot);
Quill.register(SurveyOptOutBlot);
Quill.register(ImageFormat, true);
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/color'), true);
Quill.register(Quill.import('attributors/style/font'), true);
Quill.register('modules/imageResize', ImageResize);
Quill.register(Font, true);
Quill.register(EmojiBlot);
Quill.register(UtfBlot);

function insertLink() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection(),
      cursorPosition = range.index;

    if (range && range.length) {
      const blotType = this.quill.getContents(cursorPosition, range.length)
        .ops[0].insert;

      if (blotType.surveyOptOut || blotType.surveyLink) {
        return null;
      }
      this.quill.format(
        'link',
        store.getState().campaign.campaign.survey_campaign_url,
        'user'
      );
    } else {
      this.quill.insertEmbed(cursorPosition, 'surveyLink', 'user');

      this.quill.setSelection(cursorPosition + 1, 0, 'user');
    }
  }
}

function insertOptOutLink() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection(),
      cursorPosition = range.index;

    if (range && range.length) {
      const blotType = this.quill.getContents(cursorPosition, range.length)
        .ops[0].insert;

      if (blotType.surveyOptOut || blotType.surveyLink) {
        return null;
      }
      this.quill.format(
        'link',
        `${store.getState().campaign.campaign.opt_out}`,
        'user'
      );
    } else {
      this.quill.insertEmbed(cursorPosition, 'surveyOptOut', 'user');
      this.quill.setSelection(cursorPosition + 1, 0, 'user');
    }
  }
}

function insertHorizontalRule() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection(true);

    this.quill.insertEmbed(range.index, 'divider', true, 'user');
    this.quill.insertEmbed(range.index + 1, 'paragraph', true, 'user');
    this.quill.setSelection(range.index + 1, 0, 'user');
  }
}

function undo() {
  this.quill.history.undo();
}

function redo() {
  this.quill.history.redo();
}

function fieldMerge() {
  const { id } = store.getState().campaign;

  window.quillRef = this.quill;

  window.quillRange = this.quill.getSelection();

  return store.dispatch(getFieldMergeValues(null, null, id));
}

function insertField(field) {
  const { index } = window.quillRange,
    val = '[' + field + ']';

  window.quillRef.enable();
  window.quillRef.insertEmbed(index, 'fieldtag', val, 'user');
  window.quillRef.insertText(index + 1, ' ', 'user');
  window.quillRef.setSelection(index + 2, 0, 'user');
  window.quillRange.index += 1;
  window.quillRef.focus();
}

function link(label, url, range) {
  if (label === undefined || url === undefined) {
    window.quillRef = this.quill;
  }

  if (label !== undefined && url !== undefined) {
    if (!LANGUAGE.PATTERN.test(url)) {
      url = `http://${url}`;
    }
    if (range && range.length) {
      window.quillRef.deleteText(range.index, range.length, 'user');
      window.quillRef.insertText(range.index, label, 'link', url, 'user');
      window.quillRef.setSelection(range.index + label.length, 0, 'user');
    } else {
      if (label) {
        window.quillRef.insertText(range.index, label, 'link', url, 'user');
        window.quillRef.setSelection(range.index + label.length, 0, 'user');
      } else {
        window.quillRef.insertText(range.index, url, 'link', url, 'user');
        window.quillRef.setSelection(range.index + url.length, 0, 'user');
      }
    }
  }
}

function insertCallToAction(
  buttonWidth,
  buttonHeight,
  buttonLabel,
  buttonURL,
  buttonColor,
  textColor,
  range,
  updateBtnElement,
  elementId
) {
  if (!buttonURL) {
    window.quillRef = this.quill;
  }

  buttonURL = buttonURL === '' ? 'https://' : buttonURL;
  if (buttonURL) {
    if (updateBtnElement && elementId) {
      let node = document.getElementById(elementId);
      if (node) {
        const buttonStyle = `background-color:${buttonColor};border:1px solid ${buttonColor};height: ${buttonHeight}px;font-family:sans-serif;border-radius:4px;width: ${buttonWidth}px;color:${textColor};display:flex;align-items: center;justify-content: center;font-size:16px;line-height:60px;cursor: pointer;text-align:center;text-decoration:none;-webkit-text-size-adjust:none;mso-hide:all;`;
        node.setAttribute('style', buttonStyle);
        node.setAttribute('href', buttonURL);
        node.innerHTML = buttonLabel.trim();
      }
      window.quillRef.insertText(window.quillRange.index + 1, ' ', 'user');
      window.quillRef.setSelection(window.quillRange.index + 2, 0, 'user');
      if (window.quillRange && window.quillRange.index) {
        window.quillRange.index += 1;
      }
    } else {
      if (range) {
        window.quillRef.insertEmbed(
          range.index,
          'calltoaction',
          {
            text: buttonLabel,
            href: buttonURL,
            style: `background-color:${buttonColor};border:1px solid ${buttonColor};height: ${buttonHeight}px;font-family:sans-serif;border-radius:4px;width: ${buttonWidth}px;color:${textColor};display:flex;align-items: center;justify-content: center;font-size:16px;line-height:60px;cursor: pointer;text-align:center;text-decoration:none;-webkit-text-size-adjust:none;mso-hide:all;`
          },
          'user'
        );
        window.quillRef.insertText(range.index + 1, ' ', 'user');
        window.quillRef.setSelection(range.index + 2, 0, 'user');
        if (window.quillRange && window.quillRange.index) {
          window.quillRange.index += 1;
        }
      }
    }
  }
}

function image() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection();

    window.quillRef = this.quill;
    window.quillRange = range;
  }
}

function emoji() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection();

    window.quillRef = this.quill;
    window.quillRange = range;
  }
}

function utf() {
  if (this.quill.getSelection()) {
    const range = this.quill.getSelection();

    window.quillRef = this.quill;
    window.quillRange = range;
  }
}

function showAlignmentTip(e) {
  let toolTip = document.getElementById('alignmentText'),
    title = e.target.attributes['data-title'];

  if (title && title.value !== undefined) {
    toolTip.innerHTML = title.value;
    toolTip.style.top = e.target.offsetTop + 'px';
  }
}

const EditorToolBar = props => (
  <div id="customToolbar" className="ql-toolbar">
    <Toolbar
      linkCB={() => props.linkCB()}
      imageCB={() => props.imageCB()}
      emojiCB={() => props.emojiCB()}
      utfcharacters={() => props.utfcharacters()}
      showUtfCharacters={props.showUtfCharacters}
      isEditorHistoryEnabled={props.isEditorHistoryEnabled}
      showHTMLModal={props.showHTMLModal}
      showFieldMergeModal={props.showFieldMergeModal}
      setWrapperRef={props.setWrapperRef}
      buttonCB={props.handleButtonModal}
      campaign={props.campaign}
      editorIsFocused={props.editorIsFocused}
      showFieldMerge={props.showFieldMerge}
    />

    <div id="ql-tooltip-text" className="tooltip-text" />
  </div>
);

class CampaignEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLinkModal: false,
      linkLabel: '',
      linkURL: '',
      range: null,
      editorIsFocused: false,
      isFirstBlur: false,
      showFieldMerge: false,
      fieldMergeValue: '',
      showCodeModal: false,
      editorcode: '',
      isCopied: false,
      showImageModal: false,
      isLoaded: false,
      selectedImages: {},
      fileTypeErr: '',
      showEmoji: false,
      emojiBtnPosition: {},
      nodeType: null,
      showUtfCharacters: false,
      showButtonModal: false,
      buttonWidth: '208',
      buttonHeight: '60',
      buttonLabel: 'Button',
      buttonURL: 'https://',
      updateBtnElement: false,
      displayColorPicker: false,
      textColor: {
        r: '255',
        g: '255',
        b: '255'
      },
      buttonColor: {
        r: '19',
        g: '18',
        b: '18'
      },
      displayColorPickerText: false,
      displayColorPickerButton: false,
      buttonModalTitleText: 'Insert Button',
      buttonModalInsertText: 'Insert'
    };

    this.editorRef = null;
    this.reactQuillRef = null;
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.editorRef.editor.getModule('history').clear();
    this.setState({ isFirstBlur: true });

    const toolTip = document.createElement('span'),
      alignmentCont = document.getElementsByClassName('text-alignment')[0]
        .childNodes[0].childNodes[1];

    for (let nodeElement of alignmentCont.childNodes) {
      if (nodeElement.getAttribute('data-value')) {
        if (nodeElement.getAttribute('data-value') !== 'justify') {
          nodeElement.setAttribute(
            'data-title',
            `Align ${nodeElement.getAttribute('data-value')}`
          );
          nodeElement.addEventListener('mouseenter', showAlignmentTip);
        }

        if (nodeElement.getAttribute('data-value') === 'justify') {
          nodeElement.setAttribute(
            'data-title',
            nodeElement.getAttribute('data-value')
          );
          nodeElement.addEventListener('mouseenter', showAlignmentTip);
        }
      } else {
        nodeElement.setAttribute('data-title', `Align Left`);
        nodeElement.addEventListener('mouseenter', showAlignmentTip);
      }
    }

    toolTip.classList.add('align-tooltip');
    toolTip.setAttribute('id', 'alignmentText');
    alignmentCont.appendChild(toolTip);

    document.addEventListener('selectionchange', () => {
      const selection = document.getSelection();
      const node = selection.anchorNode;
      const editor = document.getElementsByClassName('ql-editor')[0];

      if (editor.contains(node)) {
        return this.setState({
          nodeType: node.parentNode.nodeName.toLowerCase()
        });
      }

      return this.setState({ nodeType: null });
    });
    window.setButtonStyle = this.handleButtonModal;
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('selectionchange', () => {
      return null;
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showUtfCharacters: false });
      document.getElementById('utf-8-box').innerHTML = '';
    }
  }

  showFieldMergeModal = () => {
    this.setState(
      {
        showFieldMerge: !this.state.showFieldMerge,
        fieldMergeValue: ''
      },
      () => {
        if (this.state.showFieldMerge) {
          window.quillRef.disable();
        } else {
          window.quillRef.enable();
          window.quillRef.focus();
        }
      }
    );
  };

  showHTMLModal = () => {
    this.setState({
      showCodeModal: true,
      editorcode: this.props.campaign.mail_body
    });
  };

  hideHTMLModal = () => {
    this.setState({
      showCodeModal: false,
      editorcode: ''
    });
  };

  hideImageModal = () => {
    this.setState({
      showImageModal: false,
      selectedImages: {},
      fileTypeErr: ''
    });
    if (this.props.isImageDeleted) {
      window.location.reload();
    }
  };

  handleImageModal = () => {
    this.props.handleGetImages(this.props.survey);

    this.setState({ showImageModal: true });
  };

  handleLinkModal = () => {
    const range = this.editorRef.editor.getSelection(),
      { nodeType } = this.state;
    if (range && range.length) {
      let text = this.editorRef.editor.getText(range.index, range.length);
      this.setState({ linkLabel: text });
      if (nodeType === 'a' || nodeType === 'div') {
        this.setState({
          linkURL:
            (window.getSelection().anchorNode.parentNode.attributes[0] &&
              window.getSelection().anchorNode.parentNode.attributes[0]
                .nodeValue) ||
            window.getSelection().anchorNode.nextSibling.attributes[0].nodeValue
        });
      } else {
        this.setState({ linkURL: '' });
      }
    } else {
      this.setState({ linkURL: '', linkLabel: '' });
    }

    this.setState({
      showLinkModal: !this.state.showLinkModal,
      range: window.quillRef.getSelection()
    });
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleCreateLink = () => {
    const { linkLabel, linkURL, range } = this.state;

    link(linkLabel, linkURL, range);

    this.setState({ linkLabel: '', linkURL: '', showLinkModal: false });
  };

  handleEditorFocus = () => {
    this.setState({ editorIsFocused: !this.state.editorIsFocused });
  };

  insertEditorHTML = () => {
    this.editorRef.editor.setText('');
    this.setState({ showCodeModal: false }, () => {
      this.editorRef.editor.clipboard.dangerouslyPasteHTML(
        DOMPurify.sanitize(this.state.editorcode),
        'user'
      );
    });
  };

  handleCopy = () => {
    const code = document.getElementById('editorCodeText');

    code.select();
    document.execCommand('copy');

    this.setState({ isCopied: true }, () => {
      setTimeout(() => {
        this.setState({ isCopied: false });
      }, 1000);
    });
  };

  handleFiles = files => {
    let arr = [];
    let fileSize = [];
    for (let file of files) {
      const fileType = getFileExtension(file.name).toLowerCase();
      fileSize.push(getFileSize(file.size));
      if (
        fileType === 'png' ||
        fileType === 'jpg' ||
        fileType === 'jpeg' ||
        fileType === 'gif'
      ) {
        arr.push(file);
      }
    }
    let fileSizeCheck = fileSize.some(el => el > 2);
    if (arr.length > 0 && !fileSizeCheck) {
      this.setState({ fileTypeErr: '' });
      store.dispatch(getFiles(files));
      store.dispatch(uploadImages(this.props.files, this.props.survey));
    } else if (fileSizeCheck) {
      this.setState({
        fileTypeErr:
          'Invalid file size. Please upload an image of maximum 2 MB.'
      });
    } else {
      this.setState({
        fileTypeErr:
          'Invalid file format. Please upload any valid .jpeg/.jpg/.png/.gif file(s).'
      });
    }
  };

  handleCheckBoxChange = (e, img_url) => {
    let { id, checked } = e.target;

    this.setState({
      selectedImages: {
        ...this.state.selectedImages,
        [id]: { checked, img_url }
      }
    });
  };

  handleInsertImage = () => {
    const { selectedImages } = this.state;
    let images = [];

    for (let img in selectedImages) {
      if (selectedImages[img].checked) {
        images.push(selectedImages[img].img_url);
      }
    }

    for (let i = 0; i < images.length; i++) {
      window.quillRef.insertEmbed(
        window.quillRange.index,
        'image',
        images[i],
        'user'
      );
    }
    window.quillRef.setSelection(window.quillRange.index + 1);
    window.quillRange = window.quillRef.getSelection();
    this.hideImageModal();
  };

  handleRemoveImage = () => {
    let images = this.state.selectedImages;

    for (let img in images) {
      delete images[img];
    }

    this.setState({ selectedImages: images });
  };

  handleEmojiPicker = () => {
    const emojiBtn = document.getElementById('emojiBtn');

    const emojiBtnPosition = {
      left: emojiBtn.offsetParent.offsetLeft,
      top: emojiBtn.offsetParent.offsetTop
    };

    this.setState({ showEmoji: !this.state.showEmoji, emojiBtnPosition });
  };

  insertUtfChars = (e, code) => {
    window.quillRef.insertEmbed(window.quillRange.index, 'utf', code, 'user');
    window.quillRef.setSelection(window.quillRange.index + 1);
    window.quillRange = window.quillRef.getSelection();
  };

  handleUtfCharacters = () => {
    if (!this.props.showUtfCharacters) {
      for (let i = 195; i <= 500; i++) {
        const btn = document.createElement('BUTTON');
        btn.classList.add('ql-utf');
        const code = String.fromCharCode(i);

        btn.innerHTML = code;
        btn.addEventListener(
          'click',
          e => {
            this.insertUtfChars(e, code);
          },
          false
        );
        document.getElementById('utf-8-box').appendChild(btn);
      }
    }
    this.setState({ showUtfCharacters: !this.state.showUtfCharacters });
  };

  handleInsertButton = () => {
    const {
      buttonWidth,
      buttonHeight,
      buttonLabel,
      buttonURL,
      buttonColor,
      textColor,
      range,
      updateBtnElement,
      elementId
    } = this.state;
    insertCallToAction(
      buttonWidth,
      buttonHeight,
      buttonLabel,
      buttonURL,
      `rgb(${buttonColor.r}, ${buttonColor.g}, ${buttonColor.b})`,
      `rgb(${textColor.r}, ${textColor.g}, ${textColor.b})`,
      range,
      updateBtnElement,
      elementId
    );
    this.handleCloseButtonModal();
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  };

  handleColorPickerButtonColor = color => {
    this.setState({ buttonColor: color.rgb });
  };

  handleColorPickerTextColor = color => {
    this.setState({ textColor: color.rgb });
  };

  handleCreateLink = () => {
    const { linkLabel, linkURL, range } = this.state;

    link(linkLabel, linkURL, range);

    this.setState({ linkLabel: '', linkURL: '', showLinkModal: false });
  };

  handleButtonModal = (
    buttonWidth,
    buttonHeight,
    buttonLabel,
    buttonURL,
    updateBtnElement,
    elementId,
    buttonColor,
    textColor
  ) => {
    window.quillRef = this.editorRef.editor;
    if (elementId && this.editorRef) {
      window.quillRange = this.editorRef.editor.getSelection();
      this.editorRef.editor.blur();
    }
    this.setState({
      showButtonModal: !this.state.showButtonModal,
      range: this.editorRef.editor.getSelection(),
      buttonWidth: buttonWidth ? buttonWidth : this.state.buttonWidth,
      buttonHeight: buttonHeight ? buttonHeight : this.state.buttonHeight,
      buttonLabel:
        (buttonLabel === '' || buttonLabel) && elementId
          ? buttonLabel
          : 'Button',
      buttonURL: buttonURL ? buttonURL : 'https://',
      updateBtnElement: updateBtnElement,
      elementId,
      buttonColor: buttonColor
        ? buttonColor
        : {
            r: '19',
            g: '18',
            b: '18'
          },
      textColor: textColor
        ? textColor
        : {
            r: '255',
            g: '255',
            b: '255'
          },
      buttonModalInsertText: elementId ? 'Update' : 'Insert',
      buttonModalTitleText: elementId ? 'Update Button' : 'Insert Button'
    });
  };

  handleColorPickerClick = element => {
    this.setState({ [element]: !this.state[element] });
  };

  handleColorPickerClose = () => {
    this.setState({
      displayColorPickerText: false,
      displayColorPickerButton: false
    });
  };

  handleCloseButtonModal = () => {
    this.setState({
      showButtonModal: false,
      buttonWidth: '208',
      buttonHeight: '60',
      buttonLabel: 'Button',
      buttonURL: '',
      updateBtnElement: false,
      displayColorPickerText: false,
      displayColorPickerButton: false
    });
  };

  insertEmoji = e => {
    window.quillRef.insertEmbed(
      window.quillRange.index,
      'emoji',
      e.native,
      'user'
    );
    window.quillRef.setSelection(window.quillRange.index + 1);
    window.quillRange = window.quillRef.getSelection();
  };

  setFieldMergeValue = value => {
    this.setState({ fieldMergeValue: value });
  };
  modules = {
    toolbar: {
      container: '#customToolbar',
      handlers: {
        insertLink,
        insertCallToAction,
        image,
        insertOptOutLink,
        link,
        undo,
        redo,
        fieldMerge,
        emoji,
        utf,
        divider: insertHorizontalRule
      }
    },
    clipboard: {
      matchVisual: false
    },
    imageResize: {},
    mention: {
      showDenotationChar: false,
      mentionDenotationChars: ['['],
      isolateCharacter: true,
      onSelect: function(item, insertItem) {
        item.value = `[${item.value}]`;

        return insertItem(item);
      },
      source: function(searchTerm, renderList) {
        getFieldVars(store.getState().campaign.id).then(res => {
          let list = res.data.fieldMerge.map((fieldVar, index) => {
            return { id: index, value: fieldVar };
          });

          if (searchTerm.length === 0) {
            renderList(list, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < list.length; i++) {
              if (
                ~list[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(list[i]);
            }

            renderList(matches, searchTerm);
          }
        });
      }
    }
  };

  render() {
    const {
        campaign,
        handleHTMLChange,
        id,
        isEditorHistoryEnabled,
        showFieldMergeList,
        fieldMergeList,
        isMailBodySaving,
        isMailBodySaved,
        files,
        isImageUploading,
        handleDeleteImage,
        isImageDeleting,
        imgID,
        survey,
        gettingImages,
        validateImageErrorMessage
      } = this.props,
      {
        showLinkModal,
        linkLabel,
        linkURL,
        editorIsFocused,
        isFirstBlur,
        showFieldMerge,
        fieldMergeValue,
        showCodeModal,
        editorcode,
        isCopied,
        showImageModal,
        selectedImages,
        fileTypeErr,
        showEmoji,
        emojiBtnPosition
      } = this.state;

    let sImages = false;
    for (let k in selectedImages) {
      if (selectedImages[k].checked) {
        sImages = true;
      }
    }

    const imageuploader = document.getElementById('imageUploaderModal');

    if (imageuploader) {
      imageuploader.parentNode.style.width = '1000px';
    }

    var editableElements = document.querySelectorAll(
      '.editorArea span[contenteditable=false]'
    );

    for (var i = 0; i < editableElements.length; ++i) {
      editableElements[i].setAttribute('contentEditable', true);
    }

    return (
      <Fragment>
        <div className="fixed-top draftSaving">
          {isMailBodySaving && (
            <AlertNotifier kind="info" message="Saving..." />
          )}
          {isMailBodySaved && (
            <AlertNotifier kind="success" message="Draft saved" />
          )}
        </div>
        <div className="editorArea">
          <EditorToolBar
            isEditorHistoryEnabled={isEditorHistoryEnabled}
            showFieldMergeList={showFieldMergeList}
            fieldMergeList={fieldMergeList}
            linkCB={this.handleLinkModal}
            imageCB={this.handleImageModal}
            editorIsFocused={editorIsFocused}
            showFieldMergeModal={this.showFieldMergeModal}
            showHTMLModal={this.showHTMLModal}
            emojiCB={this.handleEmojiPicker}
            utfcharacters={this.handleUtfCharacters}
            showUtfCharacters={this.state.showUtfCharacters}
            setWrapperRef={this.setWrapperRef}
            handleButtonModal={this.handleButtonModal}
            campaign={campaign}
            showFieldMerge={showFieldMerge}
          />
          <ReactQuill
            ref={el => {
              this.editorRef = el;
            }}
            theme="snow"
            placeholder="Compose your mail..."
            defaultValue={campaign.mail_body}
            onChange={value => {
              handleHTMLChange(value, id, isFirstBlur);
            }}
            onKeyDown={() =>
              this.setState({ showEmoji: false, showUtfCharacters: false })
            }
            modules={this.modules}
            formats={CampaignEditor.formats}
            preserveWhitespace={true}
            onFocus={() => {
              this.handleEditorFocus();
            }}
            onBlur={previousRange => {
              window.editorRange = previousRange;
              handleHTMLChange(campaign.mail_body, id);
              this.handleEditorFocus();
            }}
          />
          {showEmoji && (
            <Emoji
              position={emojiBtnPosition}
              getEmoji={this.insertEmoji}
              showHideEmojiPicker={this.handleEmojiPicker}
            />
          )}
        </div>

        <ImageModal
          showImageModal={showImageModal}
          hideImageModal={this.hideImageModal}
          isImageUploading={isImageUploading}
          fileTypeErr={fileTypeErr || validateImageErrorMessage}
          gettingImages={gettingImages}
          files={files}
          campaign={campaign}
          selectedImages={selectedImages}
          handleCheckBoxChange={this.handleCheckBoxChange}
          isImageDeleting={isImageDeleting}
          handleDeleteImage={handleDeleteImage}
          handleRemoveImage={this.handleRemoveImage}
          survey={survey}
          handleInsertImage={this.handleInsertImage}
          sImages={sImages}
          imgID={imgID}
          handleFiles={this.handleFiles}
        />
        <SourceCodeModal
          showCodeModal={showCodeModal}
          hideHTMLModal={this.hideHTMLModal}
          isCopied={isCopied}
          handleChange={this.handleChange}
          handleCopy={this.handleCopy}
          editorcode={editorcode}
          insertEditorHTML={this.insertEditorHTML}
        />

        <ButtonModal
          showButtonModal={this.state.showButtonModal}
          buttonModalTitleText={this.state.buttonModalTitleText}
          buttonModalInsertText={this.state.buttonModalInsertText}
          handleCloseButtonModal={this.handleCloseButtonModal}
          buttonLabel={this.state.buttonLabel}
          handleChange={this.handleChange}
          buttonURL={this.state.buttonURL}
          handleColorPickerClick={this.handleColorPickerClick}
          handleColorPickerClose={this.handleColorPickerClose}
          handleColorPickerButtonColor={this.handleColorPickerButtonColor}
          textColor={this.state.textColor}
          handleColorPickerTextColor={this.handleColorPickerTextColor}
          displayColorPickerButton={this.state.displayColorPickerButton}
          displayColorPickerText={this.state.displayColorPickerText}
          buttonWidth={this.state.buttonWidth}
          buttonHeight={this.state.buttonHeight}
          handleInsertButton={this.handleInsertButton}
          redButtonColor={this.state.buttonColor.r}
          greenButtonColor={this.state.buttonColor.g}
          blueButtonColor={this.state.buttonColor.b}
          redTextColor={this.state.textColor.r}
          greenTextColor={this.state.textColor.g}
          blueTextColor={this.state.textColor.b}
          buttonColor={this.state.buttonColor}
        />
        <FieldMergeModal
          showFieldMerge={showFieldMerge}
          showFieldMergeModal={this.showFieldMergeModal}
          showFieldMergeList={showFieldMergeList}
          fieldMergeValue={fieldMergeValue}
          editorRef={this.editorRef}
          insertField={insertField}
          fieldMergeList={fieldMergeList}
          setFieldMergeValue={this.setFieldMergeValue}
        />
        <InsertLink
          showLinkModal={showLinkModal}
          handleLinkModal={this.handleLinkModal}
          handleChange={this.handleChange}
          linkLabel={linkLabel}
          linkURL={linkURL}
          handleCreateLink={this.handleCreateLink}
        />
      </Fragment>
    );
  }
}

export default CampaignEditor;

CampaignEditor.formats = CampaignEditorQuillformats;
