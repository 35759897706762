import React from 'react';

const ContentPlaceholder = props => {
  switch (props.componentType) {
    case 'home':
      return (
        <div className="ph-item position-relative d-flex flex-wrap overflow-hidden">
          <div>
            <div className="ph-row d-flex flex-wrap">
              <div className="ph-col-4 big" />
              <div className="ph-col-8 empty" />
              <div className="ph-col-6" />
              <div className="ph-col-6 empty" />
              <div className="ph-col-12 big empty" />
              <div className="ph-col-2 big" />
              <div className="ph-col-2 empty" />
              <div className="ph-col-8 big" />
              <div className="ph-col-12 big empty" />
              <div className="ph-col-2 big" />
              <div className="ph-col-2 empty" />
              <div className="ph-col-8 big" />
              <div className="ph-col-12 big empty" />
              <div className="ph-col-2 big" />
              <div className="ph-col-2 empty" />
              <div className="ph-col-8 big" />
              <div className="ph-col-12 big empty" />
              <div className="ph-col-2 big" />
              <div className="ph-col-2 empty" />
              <div className="ph-col-8 big" />
              <div className="ph-col-12 empty" />
              <div className="ph-col-12 empty" />
              <div className="ph-col-12 empty" />
            </div>
            <div>
              <div className="ph-picture w-100" />
              <div className="ph-picture w-100" />
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default ContentPlaceholder;
