import React, { Fragment } from 'react';
import { Modal, Button, Input } from 'stylist';
import { SketchPicker } from 'react-color';

export default function ButtonModal(props) {
  return (
    <Fragment>
      <Modal
        dismissable={false}
        open={props.showButtonModal}
        onClose={props.handleCloseButtonModal}>
        <Modal.Header>
          <Modal.Title>{props.buttonModalTitleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label>Label</label>
            <Input
              type="text"
              placeholder="Button"
              name="buttonLabel"
              id="buttonLabel"
              className="w-100"
              value={props.buttonLabel}
              onChange={e => props.handleChange(e)}
            />
          </div>
          <div className="mb-3">
            <label>URL</label>
            <Input
              type="text"
              name="buttonURL"
              placeholder="https://example.com"
              className="w-100"
              value={props.buttonURL}
              onChange={e => props.handleChange(e)}
            />
          </div>
          <div className="d-flex">
            <div className="choose-color mr-5">
              <label>Choose Color:</label>
              <div className="choose-color-txt d-flex align-items-center">
                <div className="d-flex mr-3 align-items-center position-relative">
                  <label className="mb-0 mr-2">Text</label>
                  <div
                    className="colorpicker-btn position-relative"
                    onClick={() =>
                      props.handleColorPickerClick('displayColorPickerText')
                    }>
                    <div
                      className="colorpicker-text"
                      style={{
                        backgroundColor: `rgb(${props.redTextColor}, ${props.greenTextColor}, ${props.blueTextColor})`
                      }}></div>
                  </div>
                  {props.displayColorPickerText ? (
                    <div className="sketch-picker-absolute">
                      <div
                        className="sketch-picker-close position-fixed"
                        onClick={props.handleColorPickerClose}></div>
                      <SketchPicker
                        disableAlpha={true}
                        color={props.textColor}
                        name="textColor"
                        onChange={props.handleColorPickerTextColor}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="d-flex align-items-center position-relative">
                  <label className="mb-0 mr-2">Button</label>
                  <div
                    className="colorpicker-btn"
                    onClick={() =>
                      props.handleColorPickerClick('displayColorPickerButton')
                    }>
                    <div
                      className="colorpicker-button"
                      style={{
                        backgroundColor: `rgb(${props.redButtonColor}, ${props.greenButtonColor}, ${props.blueButtonColor})`
                      }}></div>
                  </div>
                  {props.displayColorPickerButton ? (
                    <div className="sketch-picker-absolute">
                      <div
                        className="sketch-picker-close position-fixed"
                        onClick={props.handleColorPickerClose}></div>
                      <SketchPicker
                        disableAlpha={true}
                        color={props.buttonColor}
                        name="buttonColor"
                        onChange={props.handleColorPickerButtonColor}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="choose-size">
              <label>Choose Size:</label>
              <div className="choose-size-txt d-flex align-items-center">
                <label className="mb-0 mr-1">W:</label>
                <input
                  type="text"
                  name="buttonWidth"
                  className="size-btn mr-3"
                  value={props.buttonWidth}
                  onChange={e => props.handleChange(e)}
                />
                <label className="mb-0 mr-1">H:</label>
                <input
                  type="text"
                  name="buttonHeight"
                  className="size-btn"
                  value={props.buttonHeight}
                  onChange={e => props.handleChange(e)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footerButtons mt-4">
          <Button onClick={props.handleInsertButton}>
            {props.buttonModalInsertText}
          </Button>
          <Modal.CancelButton />
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
