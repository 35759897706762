import React from 'react';
import { Modal, Button, Badge, Textarea } from 'stylist';
import { LANGUAGE } from 'constants/LanguageConstant';

export default function SourceCodeModal(props) {
  return (
    <Modal size="lg" open={props.showCodeModal} onClose={props.hideHTMLModal}>
      <Modal.Header>
        <Modal.Title>{LANGUAGE.SOURCE_CODE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isCopied && (
          <div className="d-flex align-items-center justify-content-center mb-3">
            <Badge solid color="green">
              {LANGUAGE.COPIED}
            </Badge>
          </div>
        )}
        <Textarea
          id="editorCodeText"
          name="editorcode"
          className="code-area"
          type="code"
          value={props.editorcode}
          onChange={e => props.handleChange(e)}
        />
      </Modal.Body>
      <Modal.Footer className="footerButtons mt-4">
        <Modal.CancelButton onClick={props.hideHTMLModal} />
        <Button kind="utility" onClick={props.handleCopy}>
          {LANGUAGE.COPY}
        </Button>
        <Button kind="primary" onClick={props.insertEditorHTML}>
          {LANGUAGE.OK}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
