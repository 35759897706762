import React, { Fragment } from 'react';
import {
  Modal,
  Button,
  Loader,
  Uploader,
  Alert,
  Checkbox,
  TrashIcon
} from 'stylist';
import Image from 'components/Image';
import { LANGUAGE } from 'constants/LanguageConstant';

export default function ImageModal(props) {
  return (
    <Modal size="lg" open={props.showImageModal} onClose={props.hideImageModal}>
      <Modal.Header>
        <Modal.Title className="modal-header-title">
          {LANGUAGE.INSERT_IMAGE}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body id="imageUploaderModal">
        {props.isImageUploading || props.gettingImages ? (
          <div className="loaderContainer">
            <Loader centered />
          </div>
        ) : (
          <Fragment>
            {props.fileTypeErr && (
              <Alert
                className="mb-3"
                kind="error"
                message={props.fileTypeErr}
              />
            )}
            <div className="uploadContainer insertImageUploader">
              <Uploader
                accept=".png, .gif, .jpg, .jpeg"
                multiple
                onChange={files => props.handleFiles(files)}
                valid={props.fileTypeErr !== '' ? false : true}
                value={props.files}
              />
                <div className="d-flex justify-content-center">
                  <em className="fileInstruction fileSizeInstruction">
                      (Max 2 MB)
                  </em>
              </div>
            </div>

            <Modal.Title className="image-manager text-uppercase mb-4">
              {LANGUAGE.IMAGE_MANAGER}
            </Modal.Title>
            {props.campaign.images &&
            Object.keys(props.campaign.images).length > 0 ? (
              <div className="thumbnail-container d-flex flex-wrap">
                {Object.keys(props.campaign.images).map((k, index) => (
                  <div
                    key={k}
                    className={
                      props.selectedImages[k] && props.selectedImages[k].checked
                        ? 'thumbnail selected position-relative'
                        : 'thumbnail position-relative'
                    }>
                    <Image
                      src={props.campaign.images[k].image_url}
                      alt={props.campaign.images[k].file_name}
                      isImageDeleting={
                        props.imgID === k ? props.isImageDeleting : false
                      }
                    />
                    <div className="nameContainer position-absolute">
                      <label title={props.campaign.images[k].file_name}>{props.campaign.images[k].file_name}</label>
                    </div>
                    <Checkbox
                      dark
                      id={k}
                      checked={
                        props.selectedImages[k] &&
                        props.selectedImages[k].checked
                      }
                      onChange={e =>
                        props.handleCheckBoxChange(
                          e,
                          props.campaign.images[k].image_url
                        )
                      }
                    />
                    {!props.isImageDeleting && (
                      <TrashIcon
                        size="m"
                        color="white"
                        onClick={() => {
                          props.handleDeleteImage(k, props.survey);
                          props.handleRemoveImage(k);
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center mb-3 mt-3">
                {LANGUAGE.THERE_ARE_NO_IMAGE_IN_LIBRARY}
              </p>
            )}
          </Fragment>
        )}
      </Modal.Body>
      <Modal.Footer className="footerButtons mt-4">
        {!props.isImageUploading && (
          <Fragment>
            <Modal.CancelButton />
            <Button
              kind="primary"
              onClick={props.handleInsertImage}
              disabled={
                props.campaign.images &&
                Object.keys(props.campaign.images).length > 0 &&
                props.sImages
                  ? false
                  : true
              }>
              {LANGUAGE.INSERT_IMAGE}
            </Button>
          </Fragment>
        )}
      </Modal.Footer>
    </Modal>
  );
}
