import React, { Fragment } from 'react';
import { Button, Tooltip } from 'stylist';
import { UndoIcon, RedoIcon } from 'components/Icons';
import SpanSeparator from 'components/campaignEditor/toolbar/SpanSeparator';
import EmojiImg from '../../../assets/images/emoji.svg';
import { LANGUAGE } from 'constants/LanguageConstant';

export default class Toolbar extends React.Component {
  render() {
    const { editorIsFocused, showFieldMerge } = this.props;

    return (
      <Fragment>
        <Tooltip
          position="top"
          label="Font Family"
          render={({ hide, show, ref }) => (
            <Button innerRef={ref} onMouseOver={show} onMouseOut={hide}>
              <select className="ql-font" defaultValue="sans-serif">
                <option value="arial">{LANGUAGE.FONT_Arial}</option>
                <option value="courier">{LANGUAGE.FONT_Courier}</option>
                <option value="georgia">{LANGUAGE.FONT_Georgia}</option>
                <option value="monospace">{LANGUAGE.FONT_Monospace}</option>
                <option value="sans-serif">{LANGUAGE.FONT_Sans_Serif}</option>
                <option value="serif">{LANGUAGE.FONT_Serif}</option>
                <option value="verdana">{LANGUAGE.FONT_Verdana}</option>
              </select>
            </Button>
          )}
        />
        <Tooltip
          position="top"
          label="Font Size"
          render={({ hide, show, ref }) => (
            <Button innerRef={ref} onMouseOver={show} onMouseOut={hide}>
              <select className="ql-size" />
            </Button>
          )}
        />
        <Tooltip
          position="top"
          label="Bold"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-bold"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Italic"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-italic"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Underline"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-underline"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Strikethrough"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-strike"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Font Color"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="tooltip-trigger">
              <select className="ql-color" />
            </Button>
          )}
        />
        <SpanSeparator />
        <Tooltip
          position="top"
          label="Header 1"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-header"
              value="1"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Header 2"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-header"
              value="2"
            />
          )}
        />
        <SpanSeparator />
        <Tooltip
          position="top"
          label="Numbered List"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-list"
              value="ordered"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Bullet List"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-list"
              value="bullet"
            />
          )}
        />
        <SpanSeparator />
        <Tooltip
          position="top"
          label="Indent Less"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-indent"
              value="-1"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Indent More"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-indent"
              value="+1"
            />
          )}
        />
        <Tooltip
          position="top"
          label="Text Alignment"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="tooltip-trigger text-alignment">
              <select className="ql-align">
                <option value="" />
                <option value="center" />
                <option value="right" />
                <option value="justify" />
              </select>
            </Button>
          )}
        />
        <SpanSeparator />
        <Tooltip
          position="top"
          label="Horizontal Line"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-divider">
              __
            </Button>
          )}
        />
        <Tooltip
          position="top"
          label=" Insert Link"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-link"
              onClick={() => this.props.linkCB()}
            />
          )}
        />
        <Tooltip
          position="top"
          label="Image Manager"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-image"
              onClick={() => this.props.imageCB()}
            />
          )}
        />
        <Tooltip
          position="top"
          label="Emoji"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              id="emojiBtn"
              className="ql-emoji"
              onClick={() => this.props.emojiCB()}>
              <span role="img" aria-label="Emoji">
                <img src={EmojiImg} alt="Emoji" className="emojiImage" />
              </span>
            </Button>
          )}
        />
        <SpanSeparator />
        <Tooltip
          position="top"
          label="Undo"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-undo"
              disabled={!this.props.isEditorHistoryEnabled && 'disabled'}>
              <UndoIcon fill="#627585" />
            </Button>
          )}
        />
        <Tooltip
          position="top"
          label="Redo"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-redo"
              disabled={!this.props.isEditorHistoryEnabled && 'disabled'}>
              <RedoIcon fill="#627585" />
            </Button>
          )}
        />
        <Tooltip
          position="top"
          label="Source Code"
          render={({ hide, show, ref }) => (
            <Button
              innerRef={ref}
              onMouseOver={show}
              onMouseOut={hide}
              className="ql-insertHTML"
              onClick={() => this.props.showHTMLModal()}>
              &lt;&gt;
            </Button>
          )}
        />
        <div className="position-relative">
          <Tooltip
            position="top"
            label="UTF-8 characters"
            render={({ hide, show, ref }) => (
              <Button
                innerRef={ref}
                onMouseOver={show}
                onMouseOut={hide}
                className="ql-utf ql-insertUtfCharacters"
                onClick={() => this.props.utfcharacters()}>
                UTF-8
              </Button>
            )}
          />
          <div
            id="utf-8-box"
            ref={this.props.setWrapperRef}
            className="utf-characters-body position-absolute overflow-auto"
            style={{ display: this.props.showUtfCharacters ? 'block' : 'none' }}
          />
        </div>
        <button
          className="ql-insertCallToAction"
          onClick={() => {
            this.props.buttonCB();
            window.setButtonStyle = this.props.buttonCB;
          }}>
          {LANGUAGE.BUTTON}
        </button>

        <button className="ql-insertLink">{LANGUAGE.SURVEY_LINK}</button>
        <button className="ql-insertOptOutLink">
          {LANGUAGE.SURVEY_OPTOUT}
        </button>
        <button
          className={editorIsFocused || showFieldMerge ? "ql-fieldMerge isFocused" : "ql-fieldMerge"}
          onClick={() => {
            this.props.showFieldMergeModal();
          }}>
          {LANGUAGE.FIELD_MERGE}
        </button>
      </Fragment>
    );
  }
}
