import axios from 'axios';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { getCookie } from 'utils/cookie';
import { URL as urlBuilder } from 'utils/urlBuilder';

axios.defaults.withCredentials = true;

export const restApi = axios.create({
  baseURL: urlBuilder.REST
});

restApi.interceptors.request.use(
  function(config) {
    const token = getCookie('CM_AUTH');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

restApi.interceptors.response.use(undefined, function(error) {
  if (error && error.response && error.response.status === 401) {
    if (!error.response.data.redirect_url) {
      return window.location.replace(`${window.location.origin}/unauthorized`);
    } else {
      const url = new URL(error.response.data.redirect_url);
      return window.location.replace(url);
    }
  }

  return Promise.reject(error);
});

const httpLink = new HttpLink({
  uri: urlBuilder.GRAPH_QL
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    fetchOptions: {
      credentials: 'include'
    },
    headers: {
      Authorization: `Bearer ${getCookie('CM_AUTH')}`
    }
  });

  return forward(operation);
});

const unAuthorizedLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    if (!networkError.result.redirect_url) {
      return window.location.replace(`${window.location.origin}/unauthorized`);
    } else {
      const url = new URL(networkError.result.redirect_url);
      return window.location.replace(url);
    }
  }
});

export const graphqlApi = new ApolloClient({
  link: unAuthorizedLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
});
