import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Table,
  Button,
  SearchIcon,
  ChevronIcon,
  UsersIcon,
  Modal,
  PlusIcon,
  Checkbox
} from 'stylist';
import { saveCampaignDetails, setEditableState } from 'store/actions/common';
import { LANGUAGE } from 'constants/LanguageConstant';
import { CancelIconCustom } from './Icons';

class RecipientDataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterInput: '',
      allRecipients: [],
      currentPage: 1,
      pageLimit: 10,
      totalPages: 1,
      isTableEditable: false,
      updatedRecipients: [],
      newRowId: -1,
      isNewlyAdded: false,
      isAllDeleted: false,
      isDeleteEnable: false,
      deleteRecipientIds: [],
      hasFilterApplied: false,
      searchContent: ''
    };
    this.hasRecipientId = true;
  }

  componentDidMount() {
    const { recipients, filterInput, hasFilterApplied } = this.props;

    let allRecipients = [];

    if (recipients[0]) {
      allRecipients = recipients.map((row, index) => {
        let data = row.data,
          source = { source: row.source };
        delete row.data;
        delete row.campaignId;
        delete row.__typename;
        delete row.source;

        Object.assign(row, data);

        return Object.assign(row, source);
      });
    }

    return this.setState({
      allRecipients,
      filterInput,
      hasFilterApplied,
      searchContent: filterInput
    });
  }

  handleFilter = e => {
    const { value } = e.target;
    this.setState({
      searchContent: value
    });
  };

  handleSearchOnEnter = e => {
    let code = e.keyCode || e.which;
    const filterInput = this.state.searchContent;
    let { hasFilterApplied } = this.props;
    const { campaignId } = this.props,
      offset = '1';

    if ((code === 13 && filterInput) || (code === 13 && hasFilterApplied)) {
      hasFilterApplied = filterInput ? true : false;
      this.props.handleRecipientsList(
        campaignId,
        offset,
        filterInput,
        hasFilterApplied
      );
    }
  };

  handleSearchOnPageEnter = e => {
    let code = e.keyCode || e.which;
    let { filterInput } = this.state;
    const { campaignId, currentPage } = this.props;
    if (code === 13 && currentPage > 0) {
      //13 is the enter keycode
      const hasFilterApplied = filterInput ? true : false;
      this.props.handleRecipientsList(
        campaignId,
        currentPage,
        filterInput,
        hasFilterApplied
      );
    }
  };

  searchRecipientData = () => {
    const { campaignId } = this.props,
      filterInput = this.state.searchContent,
      offset = '1';
    let { hasFilterApplied } = this.props;

    if (filterInput || hasFilterApplied) {
      hasFilterApplied = filterInput ? true : !hasFilterApplied;

      this.props.handleRecipientsList(
        campaignId,
        offset,
        filterInput,
        hasFilterApplied
      );
    }
  };

  cancleRecipientSearch = () => {
    const { campaignId } = this.props,
      filterInput = '',
      offset = '1';
    let { hasFilterApplied } = this.props;
    if (hasFilterApplied) {
      hasFilterApplied = false;
      this.props.handleRecipientsList(
        campaignId,
        offset,
        filterInput,
        hasFilterApplied
      );
    } else {
      this.setState({
        filterInput: '',
        searchContent: ''
      });
    }
  };

  handlePageInput = e => {
    const { totalPages } = this.props,
      { value } = e.target;

    const hasEmptyInput = value === '';
    const isDecimalValue = value.includes('.');
    const hasValidPageNo = value <= totalPages && value > 0;

    if ((hasValidPageNo || hasEmptyInput) && !isDecimalValue) {
      this.props.setCurrentPage(value);
    }
  };

  getPageData = e => {
    let { filterInput } = this.state,
      { hasFilterApplied } = this.props;
    const { totalPages } = this.props,
      { value } = e.target;

    filterInput = hasFilterApplied ? filterInput : '';
    if (value && value <= totalPages && value > 0) {
      const { campaignId } = this.props;
      hasFilterApplied = filterInput ? true : false;
      this.props.handleRecipientsList(
        campaignId,
        value,
        filterInput,
        hasFilterApplied
      );
    }
  };

  handleNext = () => {
    const { campaignId } = this.props;
    let { currentPage } = this.props;
    const { filterInput } = this.state,
      hasFilterApplied = filterInput ? true : false;
    currentPage = currentPage + 1;
    this.props.setCurrentPage(currentPage);
    this.props.handleRecipientsList(
      campaignId,
      currentPage,
      filterInput,
      hasFilterApplied
    );
  };

  handlePrevious = () => {
    const { campaignId } = this.props;
    let { currentPage } = this.props;
    const { filterInput } = this.state,
      hasFilterApplied = filterInput ? true : false;
    currentPage = currentPage - 1;
    this.props.setCurrentPage(currentPage);
    this.props.handleRecipientsList(
      campaignId,
      currentPage,
      filterInput,
      hasFilterApplied
    );
  };

  editCampaignErrorAction = () => {
    window.location.reload();
  };

  handleEditRecipients = () => {
    this.props.setEditableState(true);
    this.setState({ isTableEditable: true });
  };

  handleSaveRecipients = () => {
    this.setState({
      isTableEditable: false,
      newRowId: -1,
      isNewlyAdded: false
    });
    this.props.setEditableState(false);
    let { updatedRecipients, deleteRecipientIds } = this.state;
    if (deleteRecipientIds.length) {
      updatedRecipients = updatedRecipients.filter(recipientValue => {
        return !deleteRecipientIds.includes(recipientValue.row_id);
      });
      deleteRecipientIds = deleteRecipientIds.filter(function(x) {
        return x > 0;
      });
    }

    this.props.getCampaignDetails(updatedRecipients, deleteRecipientIds);
    this.handleFilter(this.state.filterInput);
    this.setState({
      updatedRecipients: []
    });
  };

  handleFieldChange = (event, key, recipientRow) => {
    let { value, id } = event.target;
    let allRecipients = this.state.allRecipients;
    let { updatedRecipients } = this.state;
    let arrIndex = 0;
    let nextIndex = 0;
    id = parseInt(id);
    allRecipients.forEach((_recipient, index) => {
      if (_recipient.rowId === id) {
        allRecipients[index][key] = value;

        return false;
      }
    });
    this.setState({ allRecipients: allRecipients });

    this.props.setRecipientFieldStatus(true);

    this.props.setRecipientListStatus(true);
    if (updatedRecipients.length) {
      let exist = updatedRecipients.find((recipient, recipientIndex) => {
        arrIndex = recipientIndex;

        return recipient.row_id === parseInt(id);
      });

      if (exist) {
        updatedRecipients[arrIndex].data[key] = value;

        return this.setState({ updatedRecipients });
      } else {
        nextIndex = updatedRecipients.length;
      }
    }
    let recipientData = Object.assign({}, recipientRow);
    delete recipientData['rowId'];
    updatedRecipients[nextIndex] = {};
    updatedRecipients[nextIndex]['row_id'] = parseInt(id);
    updatedRecipients[nextIndex]['data'] = recipientData;
    updatedRecipients[nextIndex].data[key] = value;
    this.setState({ updatedRecipients });

    if (this.state.isTableEditable) {
      this.setUpdateFlag();
    }
  };

  setUpdateFlag = () => {
    const hasUpdate = this.state.updatedRecipients.length ? true : false;
    this.props.setRecipientListStatus(hasUpdate);
  };

  addNewRecipientRow = () => {
    this.props.setRecipientFieldStatus(true);
    let { updatedRecipients } = this.state;
    let allRecipients = this.props.recipients;
    let recipientsCount = allRecipients.length;
    let updatedRecipientsCount = updatedRecipients.length;
    let totalPages = 1;
    const keys = Object.keys(allRecipients[0]);
    let rows = {};
    totalPages = Math.ceil((allRecipients.length + 1) / 9);
    this.setState(
      {
        filterInput: '',
        currentPage: totalPages,
        totalPages
      },
      () => {
        rows.data = {};
        rows.row_id = this.state.newRowId;
        allRecipients[recipientsCount] = {};
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === 'rowId') {
            allRecipients[recipientsCount]['rowId'] = rows.row_id;
            allRecipients[recipientsCount]['isAutofocus'] = true;
            allRecipients[recipientsCount]['isDeleted'] = false;
          } else {
            rows.data[keys[i]] = '';
            allRecipients[recipientsCount][keys[i]] = '';
          }
        }
        updatedRecipients[updatedRecipientsCount] = rows;
        this.setState({
          allRecipients,
          updatedRecipients,
          newRowId: -1 + rows.row_id,
          isNewlyAdded: true
        });
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.recipients.length < 1) {
      state.allRecipients = [];
    } else if (props.recipients.length) {
      let { recipients } = props;
      let allRecipients = [];
      if (recipients[0]) {
        allRecipients = recipients.map((row, index) => {
          if (row.data && !row.data.id && recipients.length !== 1) {
            Object.assign(row, { id: row.rowId, key: index });
          }
          const data = row.data;
          const source = { source: row.source };

          delete row.rowId;
          delete row.campaignId;
          delete row.data;
          delete row.source;
          delete row.__typename;

          Object.assign(row, data);

          return Object.assign(row, source);
        });
      }
      state.allRecipients = allRecipients;
    }
    if (state.allRecipients) {
      let doUpdate = state.allRecipients.find(recipient => {
        return recipient.rowId < 0 || recipient.data;
      });
      if (!doUpdate) {
        doUpdate = props.recipients.find(recipient => {
          return recipient.data;
        });
      }
      if (doUpdate && state.allRecipients.length && !state.isNewlyAdded) {
        let recipients = props.recipients;
        let allRecipients = [];
        let totalPages = 1;

        if (recipients[0]) {
          allRecipients = recipients.map(row => {
            if (row.data) {
              let data = row.data,
                source = { source: row.source };

              delete row.data;
              delete row.campaignId;
              delete row.email;
              delete row.__typename;
              delete row.source;

              Object.assign(row, data);

              return Object.assign(row, source);
            }

            return row;
          });
        }
        totalPages = Math.ceil(allRecipients.length / 9);
        state.filterInput = '';
        state.totalPages = totalPages;
        state.allRecipients = allRecipients;
      }
    }

    return null;
  }

  setRecipientToDelete = (rowId, e) => {
    this.props.setRecipientFieldStatus(true);
    let allRecipients = this.state.allRecipients;
    rowId = parseInt(rowId);
    allRecipients.forEach((_recipient, index) => {
      if (_recipient.rowId === rowId) {
        allRecipients[index]['isDeleted'] = e.target.checked;

        return false;
      }
    });

    let checkDeletedElements = allRecipients.find(function(element) {
      if (element.isDeleted) {
        return true;
      }

      return null;
    });
    const canDelete = checkDeletedElements ? true : false;
    this.setState({
      allRecipients,
      isDeleteEnable: canDelete
    });
  };

  deleteAllRows = () => {
    this.props.setRecipientFieldStatus(true);
    let allRecipients = this.props.recipients;
    let deleteRecipientIds = this.state.deleteRecipientIds,
      indexToDelete = [];

    allRecipients.forEach((recipientValue, index) => {
      if (recipientValue['isDeleted']) {
        deleteRecipientIds.push(recipientValue['rowId']);
        indexToDelete.push(index);
      }
    });

    for (var i = indexToDelete.length - 1; i >= 0; i--) {
      allRecipients.splice(indexToDelete[i], 1);
    }

    let totalPages = Math.ceil(allRecipients.length / 9);
    let currentPage =
      this.state.currentPage <= totalPages ? this.state.currentPage : 1;

    this.setState({
      allRecipients,
      isDeleteEnable: false,
      isAllDeleted: false,
      deleteRecipientIds,
      totalPages,
      currentPage,
      filterInput: '',
      updatedRecipients: []
    });

    this.setDeleteFlag();
  };

  setDeleteFlag = () => {
    const deleteRecipientIds = this.state.deleteRecipientIds;
    const minDeleteId = Math.min(...deleteRecipientIds);
    const hasDeletedRows = deleteRecipientIds.length && minDeleteId >= 0;
    this.props.setRecipientListStatus(hasDeletedRows);
  };

  render() {
    const {
        allRecipients,
        filterInput,
        searchContent,
        isTableEditable
      } = this.state,
      { campaign, common, totalPages, currentPage } = this.props,
      { recipient_list } = campaign,
      { errorMessage } = common;
    let recipients_list;

    if (allRecipients.length > 0) {
      let newKeys = Object.keys(allRecipients[0]);
      let rowIdIndex = newKeys.indexOf('rowId');
      if (rowIdIndex !== -1) {
        newKeys.splice(rowIdIndex, 1);
      }
      let indexOfDelete = newKeys.indexOf('isDeleted');
      if (indexOfDelete !== -1) {
        newKeys.splice(indexOfDelete, 1);
      }
      let indexOfId = newKeys.indexOf('id');
      if (indexOfId !== -1) {
        newKeys.splice(indexOfId, 1);
      }
      let indexOfKey = newKeys.indexOf('key');
      if (indexOfKey !== -1) {
        newKeys.splice(indexOfKey, 1);
      }
      this.hasRecipientId = !allRecipients[allRecipients.length - 1].key;

      recipients_list = isTableEditable ? (
        <table className="css-17u7tcl">
          <thead>
            <tr>
              <th></th>
              {newKeys.map((key, index) => {
                return <th key={index}>{key}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {allRecipients.map((recipient, recipientKey) => (
              <tr key={recipientKey}>
                <td className="recipient-list-checkbox align-middle">
                  <Checkbox
                    id={'check_' + recipient['rowId']}
                    checked={recipient.isDeleted}
                    onChange={e =>
                      this.setRecipientToDelete(recipient['rowId'], e)
                    }
                  />
                </td>
                {newKeys.map((cell, recipientCellIndex) => (
                  <td key={recipientCellIndex}>
                    <Input
                      autoFocus={
                        recipientCellIndex === 0 && recipient['isAutofocus']
                      }
                      id={recipient['rowId']}
                      value={recipient[cell] ? recipient[cell] : ''}
                      onChange={e => this.handleFieldChange(e, cell, recipient)}
                      disabled={cell === 'source' ? true : false}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        (recipients_list = (
          <Table
            className={this.hasRecipientId ? '' : 'hideFirstCol'}
            data={allRecipients}>
            <Table.Row>
              {newKeys.map((key, index) => {
                return <Table.Column key={index} dataKey={key} label={key} />;
              })}
            </Table.Row>
          </Table>
        ))
      );
    }

    const modal = document.getElementById('customModalSize');

    if (modal) {
      modal.parentNode.style.width = '1000px';
    }

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-6 recipient-list-name">
            <Button
              kind="utility"
              className="recipient-list-file-name file-name-wbtn"
              marginRight>
              {recipient_list}
            </Button>
            {isTableEditable ? (
              <Button
                kind="utility"
                className="recipient-list-file-name"
                onClick={this.handleSaveRecipients}>
                Save List
              </Button>
            ) : (
              <Button
                kind="utility"
                className="recipient-list-file-name"
                onClick={this.handleEditRecipients}>
                Edit List
              </Button>
            )}
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div className="position-relative searchbox">
              <Input
                name="filterInput"
                value={searchContent}
                placeholder="Search"
                onChange={e => this.handleFilter(e)}
                onKeyPress={e => this.handleSearchOnEnter(e)}
                className="w-100"
              />

              {(searchContent && searchContent.trim().length > 0) ||
              this.props.hasFilterApplied ? (
                <div className="search-message">Press 'Enter' to Search.</div>
              ) : (
                ''
              )}
              {searchContent && searchContent.trim().length > 0 ? (
                <CancelIconCustom
                  size="xs"
                  className="search-clear-icon position-absolute"
                  onClick={this.cancleRecipientSearch}
                  showTitle={false}
                />
              ) : (
                ''
              )}
              <SearchIcon
                size="xs"
                className="searchIcon position-absolute"
                onClick={this.searchRecipientData}
              />
            </div>
          </div>
        </div>
        <div className="recipient-list-table table overflow-x-auto">
          {allRecipients.length > 0 ? (
            recipients_list
          ) : filterInput ? (
            <div className="d-flex h-100 flex-column align-items-center justify-content-center">
              <p>{LANGUAGE.NO_MATCH_RESULT}</p>
            </div>
          ) : (
            <div className="d-flex h-100 flex-column align-items-center justify-content-center">
              <UsersIcon />
              <p>{LANGUAGE.NO_RECIPIENT_UPLOADED}</p>
            </div>
          )}
        </div>

        {allRecipients.length > 0 &&
          (isTableEditable ? (
            <div className="row d-flex align-items-center mt-4 mb-4">
              <div className="col-sm-5 pr-0 d-flex align-items-center">
                <Button
                  kind="ghost"
                  onClick={this.addNewRecipientRow}
                  className="add-more-contact d-flex align-items-center mr-4">
                  <PlusIcon /> Add Row
                </Button>
                <Button
                  kind="standard"
                  disabled={
                    this.state.isAllDeleted || this.state.isDeleteEnable
                      ? false
                      : true
                  }
                  onClick={this.deleteAllRows}>
                  Delete Row(s)
                </Button>
              </div>
              <div className="col-sm-7 pl-0 arrowIcon">
                <ChevronIcon
                  className={currentPage > 1 ? 'pointer' : 'disabled'}
                  direction="left"
                  onClick={this.handlePrevious}
                />
                <span className="pageDetails d-inline-flex align-items-center justify-content-center">
                  <Input
                    disabled={totalPages === 1 && true}
                    type="text"
                    className="pageInput ml-3 text-center"
                    value={currentPage}
                    onChange={e => this.handlePageInput(e)}
                    onBlur={e => this.getPageData(e)}
                    onKeyPress={e => this.handleSearchOnPageEnter(e)}
                  />
                  <span className="mr-3">of {Math.ceil(totalPages)}</span>
                </span>
                <ChevronIcon
                  className={currentPage < totalPages ? 'pointer' : 'disabled'}
                  direction="right"
                  onClick={this.handleNext}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-3 mb-3">
              <div className="arrowIcon">
                <ChevronIcon
                  className={currentPage > 1 ? 'pointer' : 'disabled'}
                  direction="left"
                  onClick={this.handlePrevious}
                />
                <span className="pageDetails d-inline-flex align-items-center justify-content-center">
                  <Input
                    disabled={totalPages === 1 && true}
                    type="number"
                    min={1}
                    max={totalPages}
                    className="pageInput ml-3 text-center"
                    value={currentPage}
                    onChange={e => this.handlePageInput(e)}
                  />
                  <span className="mr-3">of {Math.ceil(totalPages)}</span>
                </span>
                <ChevronIcon
                  className={currentPage < totalPages ? 'pointer' : 'disabled'}
                  direction="right"
                  onClick={this.handleNext}
                />
              </div>
            </div>
          ))}
        {errorMessage && (
          <Modal open={true} onClose={this.editCampaignErrorAction}>
            <Modal.Header>
              <Modal.Title className="modal-header-title">
                Campaign Error
              </Modal.Title>
            </Modal.Header>
            {
              <Fragment>
                <Modal.Body className="modalPopup delete-popup">
                  <p>{errorMessage}</p>
                </Modal.Body>
                <Modal.Footer className="footerButtons mt-4">
                  <Button kind="danger" onClick={this.editCampaignErrorAction}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Fragment>
            }
          </Modal>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { common } = state;

  return { common };
};
const mapDispatchToProps = dispatch => ({
  getCampaignDetails: (details, deleteRecipientIds) =>
    dispatch(saveCampaignDetails(details, deleteRecipientIds)),
  setEditableState: isEditable => dispatch(setEditableState(isEditable))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientDataGrid);
