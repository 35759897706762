import React, { Fragment } from 'react';
import { Button, Input, Modal, PlusIcon, CancelIcon, Checkbox } from 'stylist';
import { connect } from 'react-redux';
import {
  handleDeleteRow,
  addNewRow,
  handleChange,
  handleCheckFields,
  handleContainerState,
  checkBoxState,
  checkBoxStateAll
} from 'store/actions/seedList';
import { isEmailValid } from 'utils/fieldValidations';
import { toggleLaunchModal } from 'store/actions/common';

const SeedList = props => {
  const {
      handleDeleteRow,
      handleChange,
      handleContainerState,
      handleAddNewRow,
      handleCheckFields,
      campaign,
      common,
      handleCheckBoxChange,
      handleLaunchModal,
      handleALlCheckBoxChange
    } = props,
    {
      seedList,
      id,
      deletedRows,
      row,
      showListContainer,
      currRow,
      selectAllSeedRows,
      isSeedListDirty,
      selectedIndex
    } = campaign,
    { from, reply_to, subject, mail_body } = common;

  if (seedList.length > 0) {
    let columns = Object.keys(seedList[0].data).map((colItem, i) => (
      <th key={i}>
        <label className="mb-0">{colItem}</label>
      </th>
    ));

    let rows = seedList.map((row, index) => {
      let keys = Object.keys(row.data),
        rowItems;

      rowItems = keys.map((rowItem, idx) => (
        <td key={`${idx}_${index}`}>
          <Input
            className="rowItem"
            type="text"
            name={`${rowItem}_${index}`}
            onChange={e => handleChange(e, index, rowItem, seedList)}
            valid={
              rowItem.toLowerCase() === 'email' && row.data[rowItem]
                ? isEmailValid(row.data[rowItem])
                : true
            }
            value={row.data[rowItem]}
          />
        </td>
      ));

      return (
        <Fragment key={index}>
          <tbody>
            <tr key={index}>
              <td>
                <Checkbox
                  id={index.toString()}
                  checked={selectedIndex[index] || selectAllSeedRows}
                  onChange={e => handleCheckBoxChange(e, row.row_id)}
                />
              </td>
              {rowItems}
              {seedList.length > 1 && (
                <td>
                  <Button
                    kind="ghost"
                    className="cancelIcon p-0"
                    onClick={() =>
                      handleDeleteRow(index, row.row_id, seedList)
                    }>
                    <CancelIcon size="xs" />
                  </Button>
                </td>
              )}
            </tr>
          </tbody>
        </Fragment>
      );
    });

    let disableSendButton;

    if (
      selectAllSeedRows === false &&
      Object.values(selectedIndex).some(s => s === true)
    ) {
      disableSendButton = false;
    } else if (
      from.hasBlank ||
      from.hasError ||
      reply_to.hasBlank ||
      reply_to.hasError ||
      subject.hasBlank ||
      mail_body.hasBlank ||
      !selectAllSeedRows
    ) {
      disableSendButton = true;
    }

    return (
      <Modal
        size="lg"
        open={showListContainer}
        onClose={() =>
          handleContainerState(id, deletedRows, showListContainer, seedList)
        }>
        <Modal.Header>
          <Modal.Title className="modal-header-title">Test Send</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modalPopup">
          <Fragment>
            <p>
              Add your seed list of recipients, check the ones you want to
              include in this send:
            </p>
            <div className="test-send-table w-100">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>
                      <Checkbox
                        id="selectAllSeedRows"
                        checked={selectAllSeedRows}
                        onChange={e => handleALlCheckBoxChange(e, seedList)}
                      />
                    </th>
                    {columns}
                    {seedList.length > 1 && <th />}
                  </tr>
                </thead>
                {rows}
              </table>
            </div>
            <div className="rowButton d-flex align-items-center mt-3">
              <Button
                kind="ghost"
                className="add-more-contact d-flex align-items-center"
                onClick={() => handleAddNewRow(seedList, row, currRow)}>
                <PlusIcon /> Add Recipient
              </Button>
            </div>
          </Fragment>
        </Modal.Body>
        <div className="footerButtons d-flex align-items-center justify-content-end mt-4">
          <Button
            kind="ghost"
            marginRight
            onClick={() =>
              handleContainerState(id, deletedRows, showListContainer, seedList)
            }>
            Cancel
          </Button>
          <Button
            kind="standard"
            marginRight
            disabled={
              seedList.some(l => l.row_id < 0) ||
              deletedRows.length > 0 ||
              isSeedListDirty
                ? false
                : true
            }
            onClick={() => handleCheckFields(id, deletedRows, seedList)}>
            Save Seed List
          </Button>

          {disableSendButton ? (
            <Button kind="primary" disabled>
              Send Test Email
            </Button>
          ) : (
            <Button
              kind="primary"
              onClick={() => {
                handleContainerState(
                  id,
                  deletedRows,
                  showListContainer,
                  seedList
                );
                handleLaunchModal('seedlist');
              }}>
              Send Test Email
            </Button>
          )}
        </div>
      </Modal>
    );
  }
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleDeleteRow: (index, row_id, seedList) =>
    dispatch(handleDeleteRow(index, row_id, seedList)),
  handleChange: (e, index, rowItem, seedList) =>
    dispatch(handleChange(e, index, rowItem, seedList)),
  handleAddNewRow: (seedList, row, currRow) =>
    dispatch(addNewRow(seedList, row, currRow)),
  handleCheckFields: (id, deletedRows, list) =>
    dispatch(handleCheckFields(id, deletedRows, list)),
  handleContainerState: (id, deletedRows, showListContainer, seedList) =>
    dispatch(
      handleContainerState(id, deletedRows, showListContainer, seedList)
    ),
  handleCheckBoxChange: (e, id) => dispatch(checkBoxState(e, id)),
  handleALlCheckBoxChange: (e, seedList) =>
    dispatch(checkBoxStateAll(e, seedList)),
  handleLaunchModal: from => dispatch(toggleLaunchModal(from))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SeedList);
