import { Quill } from 'react-quill';
let Embed = Quill.import('blots/embed');

export default class CallToActionBlot extends Embed {
  static create(value) {
    const node = super.create(value);
    const elementId = new Date().getTime().toString();
    node.innerHTML = `
      <span style="display: inline-table;" contenteditable="false">
         <!--[if mso]>
         <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href=${value.href} style="${value.style}">
            <w:anchorlock/>
            <center style="color:#ffffff;font-family:Helvetica, Arial,sans-serif;font-size:15px;"><span contenteditable="false">${value.text}</span></center>
         </v:roundrect>
         <![endif]-->
         <a href="${value.href}" id="${elementId}" target="_blank" class="action-button" style="${value.style}">
            <span style="cursor: pointer;width: 100%;" contenteditable="false">${value.text}</span>
         </a>
      </span>
   `;
    node.addEventListener('click', function() {
      updateButtonDetails(this);
    });

    return node;
  }

  static value(node) {
    node = node.tagName === 'A' ? node : node.getElementsByTagName('a')[0];

    return {
      href: node.getAttribute('href'),
      text: node.innerText,
      style: node.getAttribute('style')
    };
  }
}

CallToActionBlot.blotName = 'calltoaction';
CallToActionBlot.className = 'action-button';
CallToActionBlot.tagName = 'span';

function updateButtonDetails(element) {
  let targetElement = element.getElementsByTagName('a')[0],
    href = targetElement.getAttribute('href');
  const width = targetElement.style.width.slice(0, -2),
    height = targetElement.style.height.slice(0, -2),
    buttonUpadteColor = targetElement.style.backgroundColor,
    textColor = targetElement.style.color,
    text = targetElement.innerText,
    elementId = targetElement.getAttribute('id');
  let buttonColorRGB = buttonUpadteColor
    .substring(4, buttonUpadteColor.length - 1)
    .replace(/ /g, '')
    .split(',');
  let textColorRGB = textColor
    .substring(4, textColor.length - 1)
    .replace(/ /g, '')
    .split(',');
  href = href === 'https:' ? 'https://' : href;
  window.setButtonStyle(
    width,
    height,
    text,
    href,
    true,
    elementId,
    {
      r: buttonColorRGB[0],
      g: buttonColorRGB[1],
      b: buttonColorRGB[2]
    },
    {
      r: textColorRGB[0],
      g: textColorRGB[1],
      b: textColorRGB[2]
    }
  );
}
