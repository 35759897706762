import React from 'react';
import { Button, Modal } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { surveyNotLive } from 'store/actions/common';
import constants from './../../../constants';

const SurveyNotLive = props => {
  const { campaign, handleSurveyNotLiveModal } = props,
    { isSurveyNotLiveModal } = campaign;

  return (
    <Modal open={isSurveyNotLiveModal} onClose={handleSurveyNotLiveModal}>
      <Modal.Header>
        <Modal.Title className="Modal-header-title">
          {constants.SURVEY_MODAL_TITLE}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ModalPopup">
        {constants.SURVEY_MODAL_BODY}
      </Modal.Body>
      <Modal.Footer>
        <Button kind="ghost" onClick={handleSurveyNotLiveModal}>
          Cancel
        </Button>
        <Button
          kind="primary"
          onClick={() => {
            window.location = campaign.campaign.back_url;
          }}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleSurveyNotLiveModal: from => {
    dispatch(surveyNotLive(from));
  }
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SurveyNotLive)
);
