export const preview = body => {
  return `<!DOCTYPE html>
      <html>
        <head>
          <style type="text/css">
          .ql-container {
            max-width: 600px;
            margin: 0 auto;
            background-color: #ffffff;
            z-index: 3;
            position: relative;
            padding: 0 20px;
            border-radius: 4px;
            white-space: normal;
          }
          .ql-editor { 
            padding: 0;
            font-family: sans-serif;
            line-height: 1.5;
            font-size: 14px;
            background-color: #ffffff;
          }
          .ql-editor > * {
            font-family: sans-serif;
            cursor: text;
            text-align: left;
          }
          .ql-editor p,
          .ql-editor ol,
          .ql-editor ul,
          .ql-editor pre,
          .ql-editor blockquote,
          .ql-editor h1,
          .ql-editor h2,
          .ql-editor h3,
          .ql-editor h4,
          .ql-editor h5,
          .ql-editor h6 {
            margin: 0;
            padding: 0;
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
          }
          .ql-editor ol,
          .ql-editor ul {
            padding-left: 1.5em;
          }
          .ql-editor ol > li,
          .ql-editor ul > li {
            list-style-type: none;
          }
          </style>
          <style type="text/css">
          .ql-editor ul > li::before {
            content: '\\2022';
          }
          .ql-editor ul[data-checked=true],
          .ql-editor ul[data-checked=false] {
            pointer-events: none;
          }
          .ql-editor ul[data-checked=true] > li *,
          .ql-editor ul[data-checked=false] > li * {
            pointer-events: all;
          }
          .ql-editor ul[data-checked=true] > li::before,
          .ql-editor ul[data-checked=false] > li::before {
            color: #777;
            cursor: pointer;
            pointer-events: all;
          }
          .ql-editor ul[data-checked=true] > li::before {
            content: '\\2611';
          }
          .ql-editor ul[data-checked=false] > li::before {
            content: '\\2610';
          }
          .ql-editor li::before {
            display: inline-block;
            white-space: nowrap;
            width: 1.2em;
          }
          .ql-editor li:not(.ql-direction-rtl)::before {
            margin-left: -1.5em;
            margin-right: 0.3em;
            text-align: right;
          }
          .ql-editor li.ql-direction-rtl::before {
            margin-left: 0.3em;
            margin-right: -1.5em;
          }
          </style>
          <style type="text/css">
          .ql-editor ol li:not(.ql-direction-rtl),
          .ql-editor ul li:not(.ql-direction-rtl) {
            padding-left: 1.5em;
          }
          .ql-editor ol li.ql-direction-rtl,
          .ql-editor ul li.ql-direction-rtl {
            padding-right: 1.5em;
          }
          .ql-editor ol li {
            counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
            counter-increment: list-0;
          }
          .ql-editor ol li:before {
            content: counter(list-0, decimal) '. ';
          }
          </style>
          <style type="text/css">
          .ql-editor .ql-size-small {
            font-size: 0.75em;
          }
          .ql-editor .ql-size-large {
            font-size: 1.5em;
          }
          .ql-editor .ql-size-huge {
            font-size: 2.5em;
          }
          .ql-editor .ql-direction-rtl {
            direction: rtl;
            text-align: inherit;
          }
          .ql-editor.ql-blank::before {
            color: rgba(0,0,0,0.6);
            content: attr(data-placeholder);
            font-style: italic;
            pointer-events: none;
            position: absolute;
          }
          fieldtag button {
            display: none !important;
          }
          .ql-snow .action-button {
            display: inline-block;
            width: 208px;
            height: 60px;
            color: #fff;
            text-align: center;
            color: #ffffff;
            text-decoration: none;
            cursor: pointer;
            vertical-align: top;
          }
          .ql-snow .action-button span {
            display: inline-table;
          }
          .ql-snow {
            box-sizing: border-box;
          }
          .ql-snow * {
            box-sizing: border-box;
          }
          .ql-snow .ql-hidden {
            display: none;
          }
          .ql-snow .ql-editor img {
            max-width: 100%;
          }
          </style>
          <style>
          .ql-font-arial {
            font-family: 'Arial';
          }          
          .ql-font-courier {
            font-family: 'Courier';
          }          
          .ql-font-georgia {
            font-family: 'Georgia';
          }          
          .ql-font-monospace {
            font-family: 'Monospace';
          }          
          .ql-font-sans-serif {
            font-family: 'Sans Serif';
          }          
          .ql-font-serif {
            font-family: 'Serif';
          }          
          .ql-font-times-new-roman {
            font-family: 'Times New Roman';
          }          
          .ql-font-verdana {
            font-family: 'Verdana';
          }
          </style>
        </head>
        <body>
          <pre class="ql-container ql-snow">
            <div class="ql-editor" contenteditable="false">
              ${body}
            </div>
          </pre>
        </body>
      </html>`;
};
