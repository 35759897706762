import React, { Component, Fragment } from 'react';
import Main from 'routes';
import { connect } from 'react-redux';
import { setActivePreviewTab } from 'store/actions/common';
import { initializeAuthentication } from 'utils/initializeAuthentication';
import { restApi } from 'api';
import { Loader } from 'stylist';

class App extends Component {
  componentWillMount() {
    initializeAuthentication(restApi);
  }

  render() {
    const { campaign, common, setActivePreviewTab } = this.props;
    const { isSessionEnsured } = common;

    return (
      <Fragment>
        {isSessionEnsured ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
            <Loader centered />
          </div>
        ) : (
          <Main
            campaign={campaign}
            common={common}
            setActivePreviewTab={setActivePreviewTab}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  setActivePreviewTab: type => dispatch(setActivePreviewTab(type))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
