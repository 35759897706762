import React, { Fragment, useState } from 'react';
import { Loader } from 'stylist';

const Image = props => {
  const [imgLoaded, setImgLoaded] = useState(false),
    { src, alt, isImageDeleting } = props;

  return (
    <Fragment>
      <img
        className={imgLoaded ? 'image' : 'imageLoading'}
        src={src}
        onLoad={() => setImgLoaded(true)}
        alt={alt}
      />
      {!imgLoaded && (
        <div className="imgLoader d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
          <Loader centered size="sm" />
        </div>
      )}
      {isImageDeleting && (
        <div className="imgLoader d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
          <Loader centered size="sm" />
        </div>
      )}
    </Fragment>
  );
};

export default Image;
