import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Container } from 'stylist';
import ContentPlaceholder from 'components/ContentPlaceholder';
import { getDownloadLink } from 'store/actions/common';

class DownloadFile extends Component {
  componentDidMount() {
    this.props.getDownloadLinkFromId(this.props.match.params.string);
  }

  render() {
    const { isGettingDownloadLink } = this.props;

    return isGettingDownloadLink ? (
      <Row>
        <Container>
          <ContentPlaceholder componentType="home" />
        </Container>
      </Row>
    ) : (
      <div className="preview-container relative unauthorize-container p-0">
        <div className="position-absolute w-100" />
        <div className="position-absolute w-100" />
        <div className="unsubscribe-screens download-link-screen">
          <p className="mb-0 text-center">The file will be downloaded soon...</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { common } = state;

  return common;
};

const mapDispatchToProps = dispatch => ({
  getDownloadLinkFromId: id => dispatch(getDownloadLink(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadFile);
