import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Container } from 'stylist';
import { getCampaignDetailsByID, getSeedList } from 'store/actions/common';
import { preview } from 'utils/mailPreview';

class Preview extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    if (!this.props.campaign.campaign.mail_body) {
      this.props.getCampaignDetails(id);
    }
  }

  render() {
    const { common, campaign } = this.props,
      { campaign: details } = campaign,
      { activeTabType } = common;

    return (
      <Row>
        <Container>
          <div
            className={
              activeTabType !== 'desktop'
                ? 'preview-container mobile position-relative'
                : 'preview-container position-relative'
            }>
            <div className="position-absolute w-100" />
            <div className="position-absolute w-100" />
            {
              details.mail_body ? (
                activeTabType === 'desktop' ? (
                  <div
                    className="desktop-view position-relative"
                    dangerouslySetInnerHTML={{ __html: preview(details.mail_body) }}
                  />
                ) : (
                  <div
                    className="mobile-view position-relative"
                    dangerouslySetInnerHTML={{ __html: preview(details.mail_body) }}
                  />
                )
              ) : ''
            }
          </div>
        </Container>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  getCampaignDetails: id => dispatch(getCampaignDetailsByID(id)),
  getSeedList: id => dispatch(getSeedList(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
