import React, { Fragment } from 'react';
import { Modal, CheckIcon, CancelIcon, Alert, RadioButton } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import constants from './../../../constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  handleLaunchProcess,
  handleScheduleProcess,
  handleTimePicker
} from 'store/actions/common';

const rgbColorCode = constants.RGB_COLOR_CODE,
  brandGreen = constants.brandGreen,
  red = constants.RED;

const ModalBody = props => {
  const {
      campaign,
      handleTimePicker,
      handleLaunchProcess,
      handleScheduleProcess
    } = props,
    {
      campaign: details,
      isValidationStarted,
      validateMail,
      coming_from,
      step,
      scheduleTimeStatus,
      launchTime,
      radioButton
    } = campaign;

  let progressWidth,
    syncError = '',
    syncErrorLength,
    syncErrorMsg;

  const inputAlert =
    details.from.trim() === '' ||
    details.reply_to.trim() === '' ||
    details.subject.trim() === ''
      ? constants.VALIDATION_MESSAGE
      : null;

  if (validateMail.fields && validateMail.fields.length > 0) {
    for (let i = 0; i < validateMail.fields.length; i++) {
      syncError += `, ${validateMail.fields[i]}`;
    }
  }

  syncErrorLength = syncError.substring(
    syncError.indexOf(',') + 1,
    syncError.length
  );

  if (syncErrorLength.indexOf(',') === -1) {
    syncErrorMsg = `${constants.MISSING_COLUMN_NAMES} ${syncError.substr(
      2,
      syncError.length
    )}`;
  } else {
    syncErrorMsg = `
    ${constants.MISSING_COLUMN_NAME}
    ${syncError.substr(2, syncError.length)}`;
  }

  const launchListCheck = coming_from
      ? constants.AVAILABLE_SEED_LIST
      : constants.RECIPIENT_LIST_UPLOADED,
    alertRecipientMsg = coming_from
      ? constants.NO_SEED_LIST_AVAILABLE
      : constants.NO_RECIPIENT_LIST_UPLOADED,
    launchPopupBody = coming_from
      ? constants.TEST_LAUNCH_POP_UP_MESSAGE
      : setTitleAfterValidation(),
    currentDateAndTime = new Date(),
    timeInterval = 1;

  function setTitleAfterValidation() {
    return step === 0 || step === 1
      ? constants.LAUNCH_POP_UP_MESSAGE
      : constants.CAMPAIGN_TIMING_MESSAGE;
  }

  return (
    <Fragment>
      <Modal.Body className="modalPopup">
        <p className="mb-4">{launchPopupBody}</p>
        {step === 2 ? (
          <Fragment>
            <div className="manual-launch-email">
              <div className="launch-now-btn">
                <RadioButton
                  name="color"
                  id="colorLaunch"
                  value="Launch Now"
                  label="Launch Now"
                  checked={radioButton}
                  onChange={() => {
                    handleLaunchProcess();
                  }}
                />
              </div>
              <div className="manual-launch-time">
                <RadioButton
                  name="color"
                  id="colorManual"
                  value="ScheduleLaunch"
                  label="Schedule Launch"
                  checked={radioButton === false}
                  onChange={() => {
                    handleScheduleProcess();
                  }}
                />
              </div>

              <div>
                {scheduleTimeStatus ? (
                  <DatePicker
                    selected={launchTime}
                    onChange={date => {
                      handleTimePicker(date);
                    }}
                    showTimeSelect
                    minDate={currentDateAndTime}
                    timeFormat="HH:mm"
                    timeIntervals={timeInterval}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy H:mm aa"
                  />
                ) : (
                  <DatePicker
                    selected={launchTime}
                    onChange={date => {
                      handleTimePicker(date);
                    }}
                    minDate={currentDateAndTime}
                    showTimeSelect
                    disabled
                    timeFormat="HH:mm"
                    timeIntervals={timeInterval}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy H:mm aa"
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <p className="d-flex mb-3 launchmailText">
              {isValidationStarted ? (
                validateMail.list_data ? (
                  <Fragment>
                    <CheckIcon size="l" color={brandGreen} className="mr-3" />
                    <span>{launchListCheck}</span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CancelIcon size="l" color={red} className="mr-3" />
                    <span>
                      {launchListCheck}
                      <Alert kind="error" message={alertRecipientMsg} />
                    </span>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <CheckIcon size="l" color={rgbColorCode} className="mr-3" />
                  <span className="launch-check-lists">{launchListCheck}</span>
                </Fragment>
              )}
            </p>
            <p className="d-flex mb-3 launchmailText">
              {isValidationStarted ? (
                validateMail.list_data && validateMail.field_merge ? (
                  <Fragment>
                    <CheckIcon size="l" color={brandGreen} className="mr-3" />
                    <span>{constants.FIELD_MERGED_IS_SYNCED}</span>
                  </Fragment>
                ) : validateMail.list_data && !validateMail.field_merge ? (
                  <Fragment>
                    <CancelIcon size="l" color={red} className="mr-3" />
                    <span>
                      {constants.FIELD_MERGED_IS_SYNCED}
                      <Alert kind="error" message={syncErrorMsg} />
                    </span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CheckIcon size="l" color={rgbColorCode} className="mr-3" />
                    <span className="launch-check-lists">
                      {constants.FIELD_MERGED_IS_SYNCED}
                    </span>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <CheckIcon size="l" color={rgbColorCode} className="mr-3" />
                  <span className="launch-check-lists">
                    {constants.FIELD_MERGED_IS_SYNCED}
                  </span>
                </Fragment>
              )}
            </p>
            <p className="d-flex mb-3 launchmailText">
              {isValidationStarted ? (
                validateMail.from &&
                validateMail.reply_to &&
                validateMail.subject &&
                validateMail.list_data &&
                validateMail.mail_body &&
                inputAlert === null ? (
                  <Fragment>
                    <CheckIcon size="l" color={brandGreen} className="mr-3" />
                    <span>{constants.CHECKING_INPUT_FEILDS}</span>
                  </Fragment>
                ) : (
                  <Fragment>
                    <CancelIcon size="l" color={red} className="mr-3" />
                    <span>
                      {constants.CHECKING_INPUT_FEILDS}
                      {inputAlert && (
                        <Alert kind="error" message={inputAlert} />
                      )}
                    </span>
                  </Fragment>
                )
              ) : (
                <Fragment>
                  <CheckIcon size="l" color={rgbColorCode} className="mr-3" />
                  <span className="launch-check-lists">
                    {constants.CHECKING_INPUT_FEILDS}
                  </span>
                </Fragment>
              )}
            </p>
            <p className="mt-5">{constants.LAUNCH_SURVEY_MESSAGE}</p>
            {isValidationStarted && (
              <div className="progressBar w-100 position-relative">
                <div
                  className="colorBar position-absolute h-100"
                  style={{ width: progressWidth }}
                />
              </div>
            )}
          </Fragment>
        )}
      </Modal.Body>
    </Fragment>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  handleTimePicker: date => dispatch(handleTimePicker(date)),
  handleLaunchProcess: () => dispatch(handleLaunchProcess()),
  handleScheduleProcess: () => dispatch(handleScheduleProcess())
});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalBody)
);
