import React, { Fragment, useState } from 'react';
import { Input, EditIcon } from 'stylist';
import store from 'store';
import { updateCampaignStatus } from 'store/actions/common';
import { STATUS } from 'utils/campaignStatus';

const EditableLabel = props => {
  const [editing, setEditing] = props.campaignStatus
      ? useState(true)
      : useState(false),
    [val, setVal] = useState(props.name),
    { campaign, id } = store.getState().campaign,
    testBtn = document.getElementById('test_email_btn'),
    launchBtn = document.getElementById('launch_email_btn');

  if (testBtn && launchBtn) {
    if (val.trim() === '') {
      testBtn.disabled = true;
      launchBtn.disabled = true;
    } else {
      testBtn.disabled = false;
      launchBtn.disabled = false;
    }
  }

  return (
    <Fragment>
      {editing ? (
        <Input
          className="underlined-input"
          autoFocus={props.campaignStatus || editing}
          id={props.id}
          name={props.elementName}
          value={val}
          onChange={e => setVal(e.target.value)}
          onBlur={e => {
            if (e.target.value.trim() === '') {
              e.target.focus();
              e.target.value = '';
            } else {
              setVal(e.target.value.trim());
              if (props.campaignStatus) {
                campaign.is_new = false;
                store.dispatch(updateCampaignStatus(campaign, id, val));
              }
              props.handleSaveField(e, props.campaignId, props.elementName);
              setEditing();
            }
          }}
        />
      ) : (
        <props.labelType className={props.className} title={val}>
          {val}
        </props.labelType>
      )}
      <Fragment>
        {props.isLaunched !== STATUS.LAUNCHED && (
          <EditIcon
            className="editIcon"
            size="m"
            onClick={() => setEditing(true)}
          />
        )}
        {val.trim() === '' && (
          <span className="error-message ml-4 white">
            This field cannot be blank
          </span>
        )}
      </Fragment>
      {val.trim() === '' && <div className="disable-overlay" />}
    </Fragment>
  );
};

export default EditableLabel;
