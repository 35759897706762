import gql from 'graphql-tag';

export const SAVE_FORM_FIELD = () => {
  return gql`
    mutation($id: Int!, $campaign: String!) {
      updateCampaign(campaignInput: { campaignID: $id, data: $campaign }) {
        campaignResult {
          data
        }
      }
    }
  `;
};

export const SAVE_SEED_LIST = () => {
  return gql`
    mutation($id: Int!, $seedList: String!, $deletedRows: [Int!]) {
      updateSeedList(
        seedListInput: {
          campaignID: $id
          data: $seedList
          deletedRows: $deletedRows
        }
      ) {
        seedListResult {
          data
          listCount
        }
      }
    }
  `;
};

export const DOWNLOAD_RECIPIENT_FILE = () => {
  return gql`
    mutation($id: Int!, $fileName: String!) {
      downloadRecipientList(
        downloadInput: { campaignID: $id, filename: $fileName}
      ) {
        downloadLinkStatus
        
      }
    }
  `;
};

export const SEND_EMAIL = () => {
  return gql`
    mutation($id: Int!, $isListValid: Boolean!, $selectedRows: [Int!], $launchTime: DateTime!, $launchNow: Boolean!) {
      sendEmail(
        emailInput: {
          campaignID: $id
          seedList: $isListValid
          rowDetails: $selectedRows
          launchTime: $launchTime
          launchNow: $launchNow
        }
      ) {
        emailSendStatus
      }
    }
  `;
};


export const DELETE_RECIPIENT = () => {
  return gql`
    mutation($id: Int!, $deleteCampaign: Boolean!) {
      deleteData(
        deleteInput: {
          campaignID: $id
          deleteCampaignIndicator: $deleteCampaign
        }
      ) {
        deletionStatus
      }
    }
  `;
};

export const UNSUBSCRIBE_MAIL = () => {
  return gql`
    mutation($email: String!, $campaignId: Int!) {
      unsubscribeEmail(
        emailInput: {
          campaignId: $campaignId
          email: $email
        }
      ) {
          unsubscribeStatus
      }
    }
  `;
};

export const DELETE_IMAGE = () => {
  return gql`
    mutation($imgID: String!, $survey: String!) {
      deleteCampaignImage(
        imageDeleteInput: { campaignImageId: $imgID, campaignSurvey: $survey }
      ) {
        updatedImageList
      }
    }
  `;
};

export const UPDATE_RECIPIENT_FIELDS = () => {
  return gql`
    mutation($campaignID: Int!, $data: String!, $deletedRows: [Int!]) {
      updateRecipientList(
        updateRecipientListInput: { campaignID: $campaignID, data: $data, deletedRows: $deletedRows }
      ) {
        updateRecipientListResult {
          rowId
          campaignId
          email
          source
          data
        }
      }
    }
  `;
};
