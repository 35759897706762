import { combineReducers } from 'redux';
import campaignReducer from 'store/reducers/campaignReducer';
import commonReducer from 'store/reducers/commonReducer';

const rootReducers = combineReducers({
  campaign: campaignReducer,
  common: commonReducer
});

export default rootReducers;
