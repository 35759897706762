import gql from 'graphql-tag';

export const GET_CAMPAIGN_DATA = campaignId => {
  return gql`
    {
      campaign(campaignId: ${campaignId}) {
        campaignId
        survey
        data
      }
    }
  `;
};

export const GET_RECIPIENT_LIST = (campaignId, offset, filterString) => {
  filterString = JSON.stringify(filterString);
  
  return gql`
  {
    recipients(
      campaignId: ${campaignId}
      offset: ${offset}
      filterString: ${filterString})
    {
      count
      recipientData{
        rowId
        campaignId
        source
        data
      }
    }
  }
`;
};

export const GET_SEED_LIST = campaignId => {
  return gql`
    {
      seedList(campaignId: ${campaignId}) {
        data
        listCount
      }
    }
  `;
};

export const GET_FIELD_MERGE_VALUES = campaignId => {
  return gql`
    {
      fieldMerge(campaignId: ${campaignId})
    }
  `;
};

export const VALIDATE_MAIL = (campaignId, isListValid) => {
  return gql`
    {
      validateMail(campaignId: ${campaignId}
      seedList: ${isListValid})
    }
 `;
};

export const GET_IMAGES = surveyLink => {
  return gql`
    {
      campaignSurveyImages(surveyLink: ${surveyLink})
    }
  `;
};

export const GET_DOWNLOAD_LINK = sourceId => {
  return gql`
    {
      cloudFileDownloadUrl(sourceId: ${sourceId})
    }
  `;
};
