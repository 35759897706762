/* eslint-disable */
import React, { useRef, useEffect } from 'react';
import { Picker } from 'emoji-mart';

const useOutSideClick = (ref, props) => {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      props.showHideEmojiPicker();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};

const Emoji = props => {
  const emojiRef = useRef(null);
  useOutSideClick(emojiRef, props);

  return (
    <div
      ref={emojiRef}
      className="emoji-container position-absolute overflow-hidden"
      style={
        props.position && {
          left: props.position.left - 150,
          top: props.position.top + 30
        }
      }>
      <Picker
        title="Pick your emoji…"
        emoji="point_up"
        showSkinTones={false}
        onSelect={props.getEmoji}
      />
    </div>
  );
};

export default Emoji;
