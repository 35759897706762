import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import {
  Row,
  Container,
  Input,
  Modal,
  Button,
  Loader,
  Dropdown,
  PlusIcon,
  Badge,
  CancelIcon,
  FileIcon,
  UploadIcon,
  ArrowIcon,
  AlertCircleIcon
} from 'stylist';
import {
  getCampaignDetailsByID,
  getFileObject,
  closeAlert,
  getRecipientsList,
  getFieldValue,
  saveFormField,
  downloadRecipientList,
  sendEmail,
  syncEmailBody,
  saveMailBody,
  getFieldMergeValues,
  showFormDetails,
  toggleRecipientModal,
  saveRecipientList,
  removeFileData,
  deleteRecipientData,
  deleteImage,
  getImages,
  getSubjectValue,
  setCurrentPageNumber
} from 'store/actions/common';
import { checkBoxState } from 'store/actions/seedList';
import ContentPlaceholder from 'components/ContentPlaceholder';
import CampaignEditor from 'components/campaignEditor/index';
import FileUpload from 'components/FileUpload';
import RecipientDataGrid from 'components/RecipientDataGrid';
import ErrorPage from 'routes/ErrorPage';
import AlertNotifier from 'components/AlertNotifier';
import PopUp from '../components/PopUp';
import Emoji from '../components/Emoji';
import EmojiImg from '../assets/images/emoji.svg';
import defaultStyle from 'utils/defaultStyle';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRecipientList: false,
      showFieldMerge: false,
      fieldMergeValue: '',
      showConfirmPopup: false,
      showEmoji: false,
      isSeedListDirty: false,
      showClosePopup: false,
      isUpdatedRecipientList: false,
      offset: 1,
      filterString: '',
      isSubjectFocused: false
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (!this.props.campaign.campaign.mail_body) {
      this.props.getCampaignDetails(id);
    }
  }

  removeTitleFromSVG = () => {
    const svgElements = document.getElementsByTagName('svg');

    for (let i = 0; i < svgElements.length; i++) {
      const title = svgElements[i].getElementsByTagName('title')[0];

      if (title) {
        svgElements[i].removeChild(title);
      }
    }
  };

  handleRecipientData = id => {
    if (this.state.isSeedListDirty) {
      window.location.reload();
    }
    this.setState({ showRecipientList: !this.state.showRecipientList }, () => {
      if (this.state.showRecipientList) {
        const { offset, filterString } = this.state,
          hasFilterApplied = false;
        this.props.handleRecipientsList(
          id,
          offset,
          filterString,
          hasFilterApplied
        );
        document
          .getElementsByTagName('body')[0]
          .classList.remove('overflow-auto');
      } else {
        document.getElementsByTagName('body')[0].classList.add('overflow-auto');
      }
    });
  };

  handleConfirmPopup = () => {
    this.setState(
      {
        showConfirmPopup: !this.state.showConfirmPopup
      },
      () => {
        if (!this.state.showConfirmPopup) {
          document
            .getElementsByTagName('body')[0]
            .classList.add('overflow-auto');
        }
      }
    );
  };

  handleClosePopup = () => {
    this.setState({
      showClosePopup: !this.state.showClosePopup
    });
  };

  handleAlertPopup = data => {
    this.setState({
      isUpdatedRecipientList: data
    });
  };

  handleCloseConfirm = () => {
    this.setState({
      showClosePopup: !this.state.showClosePopup
    });

    this.handleRecipientData();
  };

  showFieldMergeModal = () => {
    this.setState({
      showFieldMerge: !this.state.showFieldMerge,
      fieldMergeValue: ''
    });
  };

  insertFieldTag = (field, currValue) => {
    const cursorPositon = window.subjectCursorPosition,
      subject = this.subjectRef;

    field = '[' + field + ']';
    subject.focus();
    subject.setSelectionRange(
      window.subjectCursorPosition,
      window.subjectCursorPosition
    );

    if (cursorPositon === 0 || cursorPositon > 0) {
      subject.value = `${currValue.slice(
        0,
        cursorPositon
      )}${field} ${currValue.slice(cursorPositon)}`;
    } else {
      subject.value = `${currValue.slice(0, currValue.length)}${field} `;
    }

    subject.blur();

    this.setState({ showFieldMerge: false });

    subject.focus();
    subject.setSelectionRange(
      window.subjectCursorPosition,
      window.subjectCursorPosition
    );
  };

  handleEmojiPicker = () => {
    this.setState({ showEmoji: !this.state.showEmoji });
  };

  insertEmoji = e => {
    const cursorPositon = window.subjectCursorPosition,
      subjectField = this.subjectRef,
      { subject } = this.props.campaign.campaign,
      emoji = e.native;

    subjectField.focus();

    if (cursorPositon >= 0) {
      subjectField.value = `${subject.slice(
        0,
        cursorPositon
      )}${emoji}${subject.slice(cursorPositon + 1)}`;
    } else {
      subjectField.value = `${subject.slice(0, subject.length)}${emoji}`;
    }

    subjectField.blur();
  };

  setRecipientFieldStatus = state => {
    this.setState({ isSeedListDirty: state });
  };

  render() {
    const {
        campaign,
        common,
        handleBrowseFile,
        match,
        handleFormFieldChange,
        handleSaveField,
        handleDownloadRecipientFile,
        handleCloseAlert,
        handleEditorHTML,
        handleHTMLChange,
        handleShowForm,
        handleRecipientModal,
        handleCheckBoxChange,
        handleSaveRecipientList,
        handleFileRemove,
        handleDeleteRecipient,
        handleDeleteImage,
        handleGetImages,
        handleGetRecipientList,
        handleSubjectValue,
        getFieldMergeList
      } = this.props,
      {
        campaign: details,
        recipients,
        totalRecipients,
        currentPage,
        filterInput,
        isCampaignDataFetching,
        id,
        isDetailsError,
        idNotFound,
        showFieldMergeList,
        fieldMergeList,
        isMailBodySaving,
        isMailBodySaved,
        showRecipientModal,
        recipient_terms,
        validateError,
        validate_email_error_message,
        isCampaignDeleting,
        deleteCampaignErrorMsg,
        deletionProcessStarted,
        isRecipientListDeleting,
        deletionRecipientStarted,
        deletionCampaignStarted,
        files,
        isImageUploading,
        isImageDeleting,
        imgID,
        survey,
        gettingImages,
        isRecipientsFetching,
        isImageDeleted,
        deleteCampaignError,
        isCampaignDeleted,
        hasFilterApplied,
        validateImageErrorMessage
      } = campaign,
      {
        fileData,
        uploadProgress,
        fileError,
        fileTypeError,
        recipient_list_data,
        recipient_list_data_error,
        canReload,
        sender,
        from,
        reply_to,
        recipient_list_success_status,
        subject,
        mail_body,
        isEmailSending,
        emailSendingStatus,
        email_sent_message,
        IsEmailSentError,
        email_sent_error_message,
        isEditorHistoryEnabled,
        isTestEmailSending,
        testEmailSendingStatus,
        test_email_sent_message,
        IsTestEmailSentError,
        test_email_error_message,
        showDetails,
        isEditable
      } = common,
      {
        showRecipientList,
        showFieldMerge,
        fieldMergeValue,
        showConfirmPopup,
        showEmoji,
        showClosePopup,
        isSubjectFocused
      } = this.state,
      msgData =
        recipient_list_success_status &&
        recipient_list_success_status === 'false'
          ? 'A download progress is already running'
          : 'A download link will be emailed to you shortly.',
      alertKind = recipient_list_success_status && 'success',
      emailSendAlert =
        emailSendingStatus && !IsEmailSentError ? 'success' : 'danger',
      emailMsgData =
        emailSendingStatus && !IsEmailSentError
          ? email_sent_message
          : email_sent_error_message,
      testEmailAlert =
        testEmailSendingStatus && !IsTestEmailSentError ? 'success' : 'danger',
      testEmailMsgData =
        testEmailSendingStatus && !IsTestEmailSentError
          ? test_email_sent_message
          : test_email_error_message,
      validateAlertkind = !validateError ? 'success' : 'danger',
      validateEmailError = validateError && validate_email_error_message;

    if (!isCampaignDataFetching) {
      setTimeout(() => {
        this.removeTitleFromSVG();
      }, 1000);
    }

    return (
      <Fragment>
        {isCampaignDataFetching ? (
          <Row>
            <Container>
              <ContentPlaceholder componentType="home" />
            </Container>
          </Row>
        ) : isDetailsError ? (
          <ErrorPage errorMessage={idNotFound} />
        ) : (
          <Fragment>
            {emailSendingStatus && (
              <Row>
                <Container>
                  {isEmailSending ? (
                    <div className="loaderOverlay position-fixed">
                      <div className="loaderRounded d-flex align-items-center justify-content-center">
                        <Loader className="emailloader" size="lg" dark />
                      </div>
                    </div>
                  ) : (
                    <AlertNotifier
                      kind={emailSendAlert}
                      message={emailMsgData}
                      time={3000}
                      handleClose={handleCloseAlert}
                    />
                  )}
                </Container>
              </Row>
            )}

            {testEmailSendingStatus && (
              <Row>
                <Container>
                  {isTestEmailSending ? (
                    <div className="loaderOverlay position-fixed">
                      <div className="loaderRounded d-flex align-items-center justify-content-center">
                        <Loader className="emailloader" size="lg" dark />
                      </div>
                    </div>
                  ) : (
                    <AlertNotifier
                      kind={testEmailAlert}
                      message={testEmailMsgData}
                      time={3000}
                      handleClose={handleCloseAlert}
                    />
                  )}
                </Container>
              </Row>
            )}

            {validateError && (
              <Row>
                <Container>
                  <AlertNotifier
                    kind={validateAlertkind}
                    message={validateEmailError}
                    time={3000}
                    handleClose={handleCloseAlert}
                  />
                  )}
                </Container>
              </Row>
            )}

            <Row className="container-with-border">
              <Container>
                <div className="recipients-section">
                  <div className="recipients d-flex justify-content-between">
                    <div>
                      <label className="to-from m-0">To</label>
                      <span className="recipients-btn">
                        {details.recipient_list ? (
                          <Badge
                            solid
                            color="slate"
                            className="recipient-badge position-relative text-lowercase">
                            <span
                              className="fileName"
                              onClick={() => this.handleRecipientData(id)}>
                              {details.recipient_list}
                            </span>
                            <FileIcon className="fileIcon ml-0 position-absolute" />
                            <Button
                              title="Delete"
                              onClick={() =>
                                this.setState({ showConfirmPopup: true })
                              }
                              className="fileCancel p-0 position-relative align-top">
                              <CancelIcon
                                size="xs"
                                className="deleteFileIcon"
                              />
                            </Button>
                            {!showRecipientList && showConfirmPopup && (
                              <PopUp
                                headerTitle={
                                  isRecipientListDeleting
                                    ? 'Deleting Recipient List'
                                    : 'Delete Recipient List'
                                }
                                dialogBody="Are you sure you want to delete this file?"
                                cancelButton="Cancel"
                                confirmButton="Delete"
                                open={showConfirmPopup}
                                isProcessing={
                                  showConfirmPopup
                                    ? isRecipientListDeleting
                                    : isCampaignDeleting
                                }
                                deletionErrorMsg={deleteCampaignErrorMsg}
                                deletionProcessStarted={
                                  showConfirmPopup
                                    ? deletionRecipientStarted
                                    : deletionCampaignStarted
                                }
                                onConfirm={() => {
                                  handleDeleteRecipient(id, false);
                                  this.handleConfirmPopup();
                                }}
                                onCancel={this.handleConfirmPopup}
                              />
                            )}
                          </Badge>
                        ) : (
                          <Button kind="ghost" onClick={handleRecipientModal}>
                            <PlusIcon size="xs" className="plusIcon" />
                            Recipients
                          </Button>
                        )}
                      </span>
                    </div>
                    <Button
                      kind="ghost"
                      className="show-more-btn"
                      onClick={handleShowForm}>
                      {showDetails ? 'Hide' : 'Show More'}
                    </Button>
                  </div>
                  {showDetails && (
                    <div className="form-details">
                      <div className="d-flex flex-wrap">
                        <div className="from-name-email d-flex">
                          <label className="to-from mb-0 mr-3">From</label>
                          <div className="form-group mb-0">
                            <label className="form-label">Name</label>
                            <div className="form-item">
                              <Input
                                name="sender"
                                type="text"
                                className="form-input"
                                placeholder="Sender"
                                value={details.sender}
                                onChange={e =>
                                  handleFormFieldChange(e, 'sender', id)
                                }
                                onBlur={e => handleSaveField(e, id, sender)}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-0">
                            <label className="form-label">Email</label>
                            <div className="form-item">
                              <Input
                                name="from"
                                type="text"
                                className="form-input"
                                placeholder="invent@focusvision.com"
                                value={details.from}
                                valid={
                                  from.hasBlank
                                    ? !from.hasBlank
                                    : !from.hasError
                                }
                                onChange={e =>
                                  handleFormFieldChange(e, 'email', id)
                                }
                                onBlur={e => handleSaveField(e, id, from)}
                                disabled
                              />
                              {from.hasError && (
                                <span className="error-message tooltip d-flex position-absolute mw-100">
                                  <AlertCircleIcon color="red" />
                                  <span>
                                    Enter a valid email address containing no
                                    spaces, quotes or brackets. Example:
                                    <em>user@example.com</em>
                                  </span>
                                </span>
                              )}
                              {from.hasBlank && (
                                <span className="error-message d-flex tooltip position-absolute mw-100">
                                  <AlertCircleIcon color="red" />
                                  <span>This field cannot be blank</span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="reply-to-input">
                          <div className="form-group mb-0 mr-0">
                            <label className="form-label">Reply-to</label>
                            <div className="form-item">
                              <Input
                                name="reply_to"
                                type="text"
                                className="form-input"
                                placeholder="invent@focusvision.com"
                                value={details.reply_to}
                                valid={
                                  reply_to.hasBlank
                                    ? !reply_to.hasBlank
                                    : !reply_to.hasError
                                }
                                onChange={e =>
                                  handleFormFieldChange(e, 'email', id)
                                }
                                onBlur={e => handleSaveField(e, id, reply_to)}
                                disabled
                              />
                              {reply_to.hasError && (
                                <span className="error-message d-flex tooltip position-absolute mw-100">
                                  <AlertCircleIcon color="red" />
                                  <span>
                                    Enter a valid email address containing no
                                    spaces, quotes or brackets. Example:{' '}
                                    <em>user@example.com</em>
                                  </span>
                                </span>
                              )}
                              {reply_to.hasBlank && (
                                <span className="error-message d-flex tooltip position-absolute mw-100">
                                  <AlertCircleIcon color="red" />
                                  <span>This field cannot be blank</span>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-row mb-2 mx-0">
                  <div className="form-item dragDownFile position-relative">
                    <FileUpload
                      uploadProgress={uploadProgress}
                      recipient_list_data={recipient_list_data}
                      handleBrowseFile={handleBrowseFile}
                      match={match}
                      fileData={fileData}
                      canReload={canReload}
                      fileError={fileError}
                      fileTypeError={fileTypeError}
                      recipient_list_data_error={recipient_list_data_error}
                      showRecipientModal={showRecipientModal}
                      handleRecipientModal={handleRecipientModal}
                      campaign={details}
                      handleCheckBoxChange={handleCheckBoxChange}
                      recipient_terms={recipient_terms}
                      handleSaveRecipientList={handleSaveRecipientList}
                      handleGetRecipientList={handleGetRecipientList}
                      handleFileRemove={handleFileRemove}
                      deleteCampaignError={deleteCampaignError}
                      deleteCampaignErrorMsg={deleteCampaignErrorMsg}
                      isCampaignDeleted={isCampaignDeleted}
                    />
                    <Modal
                      size="lg"
                      open={showRecipientList}
                      onClose={this.handleRecipientData}
                      dismissable={!isEditable}>
                      <Modal.Header>
                        <Modal.Title className="modal-header-title">
                          Recipient list
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="modalPopup" id="customModalSize">
                        {recipient_list_success_status && (
                          <AlertNotifier
                            fileDownloadStatus={recipient_list_success_status}
                            kind={alertKind}
                            message={msgData}
                            time={3000}
                            handleClose={handleCloseAlert}
                          />
                        )}
                        {isRecipientsFetching ? (
                          <div className="recipient-list-table table overflow-x-auto">
                            <Loader centered />
                          </div>
                        ) : (
                          <RecipientDataGrid
                            recipients={recipients}
                            totalPages={totalRecipients}
                            hasFilterApplied={hasFilterApplied}
                            currentPage={currentPage}
                            filterInput={filterInput}
                            campaign={details}
                            setRecipientListStatus={this.handleAlertPopup}
                            setRecipientFieldStatus={
                              this.setRecipientFieldStatus
                            }
                            handleRecipientsList={
                              this.props.handleRecipientsList
                            }
                            setCurrentPage={this.props.setCurrentPage}
                            campaignId={id}
                          />
                        )}
                      </Modal.Body>
                      <div className="footerButtons d-flex align-items-center justify-content-between px-4 recipientlistButtons">
                        <div>
                          <Button
                            kind="utility"
                            className="mr-4"
                            onClick={() => {
                              handleRecipientModal();
                              this.setState({ showRecipientList: false });
                            }}>
                            <UploadIcon className="uploadFileIcon" size="xs" />
                            Replace File
                          </Button>
                          <Button
                            id="exportButton"
                            kind="utility"
                            className="mr-4"
                            onClick={() => {
                              handleDownloadRecipientFile(
                                id,
                                details.recipient_list
                              );
                            }}>
                            <ArrowIcon
                              size="xs"
                              direction="down"
                              className="exportFileIcon"
                            />
                            Export File
                          </Button>
                          <Button
                            kind="utility"
                            onClick={() =>
                              this.setState({ showConfirmPopup: true })
                            }>
                            <CancelIcon size="xs" className="deleteFileIcon" />
                            Delete File
                          </Button>
                          {showRecipientList && (
                            <PopUp
                              headerTitle="Delete Recipient List"
                              dialogBody="Are you sure you want to delete this file?"
                              cancelButton="Cancel"
                              confirmButton="Delete"
                              open={showConfirmPopup}
                              isProcessing={isCampaignDeleting}
                              deletionErrorMsg={deleteCampaignErrorMsg}
                              deletionProcessStarted={deletionProcessStarted}
                              onConfirm={() => {
                                handleDeleteRecipient(id, false);
                                this.handleConfirmPopup();
                                this.setState({ showRecipientList: false });
                              }}
                              onCancel={this.handleConfirmPopup}
                            />
                          )}
                        </div>
                        <div>
                          {isEditable && this.state.isUpdatedRecipientList ? (
                            <Button
                              kind="ghost"
                              onClick={() =>
                                this.setState({ showClosePopup: true })
                              }
                              className="closeBtn">
                              Close
                            </Button>
                          ) : (
                            <Button
                              kind="ghost"
                              onClick={this.handleRecipientData}
                              className="closeBtn">
                              Close
                            </Button>
                          )}
                          <PopUp
                            headerTitle="Unsaved List"
                            dialogBody="Are you sure? You have unsaved changes in the list."
                            cancelButton="Cancel"
                            confirmButton="Close Anyway"
                            open={showClosePopup}
                            onConfirm={() => {
                              this.handleCloseConfirm();
                            }}
                            onCancel={this.handleClosePopup}
                          />
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </Container>
            </Row>
            <Row className="container-with-border mb-3">
              <Container>
                {subject.hasBlank && (
                  <span className="error-message d-block pt-1 px-0">
                    Subject cannot be blank.
                  </span>
                )}
                <div
                  className="subjectBox position-relative"
                  onBlur={e => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                      if (!showFieldMerge) {
                        this.setState({ isSubjectFocused: false });
                      }
                    }
                  }}>
                  <MentionsInput
                    id="subjectField"
                    name="subject"
                    singleLine
                    inputRef={el => {
                      this.subjectRef = el;
                    }}
                    type="text"
                    autoComplete="off"
                    placeholder="Add a Subject"
                    value={details.subject}
                    className="add-subject"
                    style={defaultStyle}
                    onChange={e => handleSubjectValue(e, 'subject')}
                    onFocus={e => {
                      this.setState({ isSubjectFocused: true });

                      return (e.target.placeholder =
                        'Type [ to insert field merge variables');
                    }}
                    onBlur={e => {
                      handleSaveField(e, id, subject);
                      window.subjectCursorPosition = e.target.selectionStart;
                      e.target.placeholder = 'Add a Subject';
                    }}>
                    <Mention
                      trigger="["
                      data={(q, cb) => getFieldMergeList(q, cb, id)}
                      displayTransform={(id, d) => `[${d}]`}
                      appendSpaceOnAdd={true}
                    />
                  </MentionsInput>
                  {showEmoji && (
                    <Emoji
                      getEmoji={this.insertEmoji}
                      showHideEmojiPicker={this.handleEmojiPicker}
                    />
                  )}
                  {isSubjectFocused && (
                    <div className="position-absolute field-merge">
                      <Fragment>
                        <Button
                          className="d-inline-block align-middle field-merge-btn insert-smiley"
                          kind="utility"
                          onClick={() => {
                            this.handleEmojiPicker();
                          }}>
                          <span role="img" aria-label="Insert Smiley">
                            <img
                              src={EmojiImg}
                              alt="Emoji"
                              className="emojiImage"
                            />
                          </span>
                        </Button>
                        <Button
                          className="d-inline-block align-middle field-merge-btn"
                          kind="utility"
                          onClick={() => {
                            this.props.getFieldMergeList(null, null, id);
                            this.showFieldMergeModal();
                          }}>
                          Field Merge
                        </Button>
                      </Fragment>
                    </div>
                  )}
                  <Modal
                    open={showFieldMerge}
                    onClose={() => {
                      this.showFieldMergeModal();
                      this.subjectRef.focus();
                      this.subjectRef.setSelectionRange(
                        window.subjectCursorPosition,
                        window.subjectCursorPosition
                      );
                    }}>
                    <Modal.Header>
                      <Modal.Title className="modal-header-title">
                        Field Merge
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {showFieldMergeList && fieldMergeList.length > 0 ? (
                        <Fragment>
                          <p>Select a variable to insert:</p>
                          <Dropdown
                            name="fieldMergeBox"
                            onChange={value =>
                              this.setState({ fieldMergeValue: value })
                            }
                            value={fieldMergeValue}>
                            {fieldMergeList.map((field, index) => (
                              <Dropdown.Option key={index} value={field}>
                                {field}
                              </Dropdown.Option>
                            ))}
                          </Dropdown>
                        </Fragment>
                      ) : (
                        showFieldMergeList && (
                          <p>No field merge variables available</p>
                        )
                      )}
                    </Modal.Body>
                    <div className="footerButtons d-flex align-items-center justify-content-end px-4 mt-5">
                      <Button
                        marginRight
                        kind="ghost"
                        onClick={() => {
                          this.showFieldMergeModal();
                          this.subjectRef.focus();
                          this.subjectRef.setSelectionRange(
                            window.subjectCursorPosition,
                            window.subjectCursorPosition
                          );
                        }}>
                        Cancel
                      </Button>
                      {fieldMergeValue && (
                        <Button
                          kind="primary"
                          onClick={() =>
                            this.insertFieldTag(
                              fieldMergeValue,
                              details.subject
                            )
                          }>
                          Insert
                        </Button>
                      )}
                    </div>
                  </Modal>
                </div>
                <CampaignEditor
                  handleEditorHTML={handleEditorHTML}
                  handleHTMLChange={handleHTMLChange}
                  campaign={details}
                  id={id}
                  isEditorHistoryEnabled={isEditorHistoryEnabled}
                  showFieldMergeList={showFieldMergeList}
                  fieldMergeList={fieldMergeList}
                  isMailBodySaving={isMailBodySaving}
                  isMailBodySaved={isMailBodySaved}
                  files={files}
                  isImageUploading={isImageUploading}
                  handleDeleteImage={handleDeleteImage}
                  isImageDeleting={isImageDeleting}
                  imgID={imgID}
                  survey={survey}
                  handleGetImages={handleGetImages}
                  gettingImages={gettingImages}
                  isImageDeleted={isImageDeleted}
                  validateImageErrorMessage={validateImageErrorMessage}
                />
                {mail_body.hasBlank && (
                  <span className="error-message d-block pt-1 px-0 w-50">
                    Email content cannot be blank.
                  </span>
                )}
              </Container>
            </Row>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = dispatch => ({
  getCampaignDetails: id => dispatch(getCampaignDetailsByID(id)),
  handleBrowseFile: file => dispatch(getFileObject(file)),
  handleCloseAlert: () => dispatch(closeAlert()),
  handleRecipientsList: (id, offset, filterString, hasFilterApplied) =>
    dispatch(getRecipientsList(id, offset, filterString, hasFilterApplied)),
  setCurrentPage: currentPage => dispatch(setCurrentPageNumber(currentPage)),
  handleFormFieldChange: (e, type, id) => dispatch(getFieldValue(e, type, id)),
  handleSaveField: (e, id, field) => dispatch(saveFormField(e, id, field)),
  handleDownloadRecipientFile: (id, fileName) =>
    dispatch(downloadRecipientList(id, fileName)),
  handleEditorHTML: (html, id) => dispatch(saveMailBody(html, id)),
  handleHTMLChange: (state, id, blur) =>
    dispatch(syncEmailBody(state, id, blur)),
  handleSendEmail: (id, subject, mailBody) =>
    dispatch(sendEmail(id, subject, mailBody)),
  getFieldMergeList: (search, callBack, campaignId) =>
    dispatch(getFieldMergeValues(search, callBack, campaignId)),
  handleShowForm: () => dispatch(showFormDetails()),
  handleRecipientModal: () => dispatch(toggleRecipientModal()),
  handleCheckBoxChange: e => dispatch(checkBoxState(e)),
  handleSaveRecipientList: (file, id, getDetail) =>
    dispatch(saveRecipientList(file, id, getDetail)),
  handleFileRemove: () => dispatch(removeFileData()),
  handleDeleteRecipient: (id, deleteCampaign) =>
    dispatch(deleteRecipientData(id, deleteCampaign)),
  handleDeleteImage: (imageId, survey) =>
    dispatch(deleteImage(imageId, survey)),
  handleGetImages: survey => dispatch(getImages(survey)),
  handleSubjectValue: (e, type) => dispatch(getSubjectValue(e, type))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
