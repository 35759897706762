import { Quill } from 'react-quill';
import store from 'store/index';

let Embed = Quill.import('blots/embed');

export default class SurveyLinkBlot extends Embed {
  static create() {
    const node = super.create();

    this._linkTextInsertion(node);
    this._linkPreviewInsertion(node);

    return node;
  }

  static _linkTextInsertion(node) {
    const linkInsert = document.createElement('a');
    const href = store.getState().campaign.campaign.survey_campaign_url;
    const text = 'link:[surveyLink]';
    linkInsert.setAttribute('href', href);
    linkInsert.setAttribute('class', 'survey-editor-link');
    linkInsert.innerHTML = text;
    node.appendChild(linkInsert);
  }

  static _linkPreviewInsertion(node) {
    const TagLinkInsert = document.createElement('a');
    const href = store.getState().campaign.campaign.survey_campaign_url;
    TagLinkInsert.setAttribute('href', href);
    TagLinkInsert.setAttribute('target', '_blank');
    TagLinkInsert.setAttribute('class', 'survey-preview-link');
    TagLinkInsert.innerHTML = href;
    node.appendChild(TagLinkInsert);
  }
}

SurveyLinkBlot.blotName = 'surveyLink';
SurveyLinkBlot.className = 'link-insertion';
SurveyLinkBlot.tagName = 'span';
