import React, { Fragment } from 'react';
import { Row, Container } from 'stylist';

const ErrorPage = props => (
  <Fragment>
    <Row>
      <Container>
        <div className="text-center">
          <h1>Whoops!!</h1>
          <p>
            {props.errorMessage ||
              `You landed on our error page. Rest assured that we are looking into
            the problem and are working hard to fix it.`}
          </p>
        </div>
      </Container>
    </Row>
  </Fragment>
);

export default ErrorPage;
