export const CampaignEditorQuillformats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
  'font',
  'align',
  'size',
  'clean',
  'width',
  'height',
  'style',
  'divider',
  'fieldtag',
  'calltoaction',
  'surveyLink',
  'surveyOptOut',
  'emoji',
  'mention',
  'utf'
];
export const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

export const Whitelist = [
  'arial',
  'courier',
  'georgia',
  'monospace',
  'sans-serif',
  'serif',
  'verdana'
];
export const IndentStyleLevels = [1, 2, 3, 4, 5];
