export const isFileTypeValid = file => {
  let type = '',
    status = false;

  if (file.type === 'csv') {
    type = 'csv';
    status = true;
  } else {
    if (getFileExtension(file.name) === 'csv') {
      type = 'csv';
      status = true;
    }
  }

  return { status, type };
};

export const getFileExtension = filename => {
  let ext = /^.+\.([^.]+)$/.exec(filename);

  return ext == null ? '' : ext[1];
};

export const replaceString = (str, find, replace) => {
  return str.replace(new RegExp(find, 'g'), replace);
};

export const getFileSize = size => {
  return size/(1024 *1024);
};
