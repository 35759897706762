import React, { Component } from 'react';
import { Button, Input, Loader } from 'stylist';
import { isEmailValid } from 'utils/fieldValidations';
import { graphqlApi } from 'api';
import { UNSUBSCRIBE_MAIL } from 'api/mutations';
import constants from './../constants';

class Unsubscribe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unsubscribeMail: '',
      hasBlank: false,
      hasError: false,
      isUnsubscribed: false,
      isLoading: false,
      campaignId: ''
    };
  }

  componentDidMount() {
    try {
      if (this.props.match.params.mail !== '[email]') {
        this.setState({
          unsubscribeMail: window.atob(this.props.match.params.mail),
          campaignId: this.props.match.params.campaignId
        });
      } else {
        this.setState({
          unsubscribeMail: ''
        });
      }
    } catch (e) {
      window.location.href = window.location.origin;
    }
  }

  handleChange = e => {
    if (isEmailValid(e.target.value)) {
      this.setState({
        unsubscribeMail: e.target.value,
        hasError: false,
        hasBlank: false
      });
    } else if (e.target.value === '') {
      this.setState({
        unsubscribeMail: e.target.value,
        hasBlank: true,
        hasError: false
      });
    } else {
      this.setState({
        unsubscribeMail: e.target.value,
        hasError: true,
        hasBlank: false
      });
    }
  };

  handleUnsubscribe = () => {
    const { unsubscribeMail, hasBlank, hasError, campaignId } = this.state;
    if (unsubscribeMail && !hasBlank && !hasError) {
      this.setState({ isLoading: true });

      return graphqlApi
        .mutate({
          mutation: UNSUBSCRIBE_MAIL(),
          variables: { email: unsubscribeMail, campaignId }
        })
        .then(resolved => {
          if (resolved.data.unsubscribeEmail.unsubscribeStatus) {
            return this.setState({ isUnsubscribed: true });
          } else {
            return this.setState({ isUnsubscribed: false });
          }
        })
        .catch(rejected => {
          return this.setState({ isUnsubscribed: false });
        });
    }
  };

  render() {
    const {
      hasBlank,
      hasError,
      unsubscribeMail,
      isUnsubscribed,
      isLoading
    } = this.state;

    let message;

    if (hasBlank) {
      message = 'E-mail cannot be blank.';
    } else if (hasError) {
      message =
        'Enter a valid email address containing no spaces, quotes or brackets. Example: user@example.com';
    } else {
      message = '';
    }

    return (
      <div className="preview-container relative unsubscribe-container">
        <div className="position-absolute w-100" />
        <div className="position-absolute w-100" />
        <div className="unsubscribe-screens">
          {isUnsubscribed ? (
            <div id="screen-2">
              <h4>{constants.THANK_YOU}</h4>
              <p>
                {constants.THANK_YOU_MESSAGE}
              </p>
            </div>
          ) : isLoading ? (
            <div className="d-flex align-items-center justify-content-center w-100 h-100 position-absolute">
              <Loader centered />
            </div>
          ) : (
            <div id="screen-1">
              <h4 className="mb-3">{constants.CONTACT_PREFERENCES}</h4>
              <p className="mb-3">
                {constants.PREFER_NOT_TO_RECIEVE}
              </p>
              <p className="mb-3">
                {constants.CHOICE_PATTERN_ONLY_COMMUNICATION}
              </p>
              <div className="form-item">
                <Input
                  name="unsubscribeMail"
                  className="w-100"
                  placeholder="[email]"
                  valid={!hasBlank && !hasError}
                  value={unsubscribeMail}
                  onChange={e => this.handleChange(e)}
                  disabled
                />
                {(hasBlank || hasError) && (
                  <span className="error-message tooltip d-flex position-absolute mw-100">
                    {message}
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  kind="standard"
                  className="mr-3"
                  onClick={() => window.close()}>
                  Cancel
                </Button>
                <Button
                  kind="primary"
                  onClick={this.handleUnsubscribe}
                  disabled={unsubscribeMail === '' ? true : false}>
                  Finish
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Unsubscribe;
