import React from 'react';
import { Container, Row } from 'stylist';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import SeedList from 'components/SeedList';

import Composer from 'components/header/Composer';
import NewCampaign from 'components/header/NewCampaign';
import LaunchModal from 'components/header/modal';
import SurveyNotLive from 'components/header/modal/SurveyNotLive';

const Header = props => {
  const { campaign, t } = props,
    { campaign: details } = campaign,
    { isSeedListFetching, isLaunchModalShow } = campaign;

  return (
    <header className="fixed-top header">
      <Row>
        <Container>
          <Composer data={campaign} detailsData={details} backButton={t} />
          <NewCampaign />
        </Container>
      </Row>
      <SurveyNotLive />
      {!isSeedListFetching && <SeedList />}
      {isLaunchModalShow && <LaunchModal />}
    </header>
  );
};

const mapStateToProps = state => {
  const { campaign, common } = state;

  return { campaign, common };
};

const mapDispatchToProps = () => ({});

export default withTranslation('res')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
