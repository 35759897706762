import React, { Component } from 'react';

let timeOut = 0;

class AlertNotifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: true
    };
  }

  componentDidMount() {
    const { time } = this.props;

    clearTimeout(timeOut);

    if (time) {
      timeOut = setTimeout(() => {
        this.setState({ isVisible: false });
      }, time);

      return timeOut;
    }
  }

  componentWillMount() {
    clearTimeout(timeOut);
  }

  render() {
    const { message, kind, handleClose, fileDownloadStatus } = this.props,
      { isVisible } = this.state;

    return (
      isVisible && (
        <div className="d-flex justify-content-center">
          <div
            className={`alertContainer ${fileDownloadStatus &&
              'exportMessage'} position-absolute alert alert-${kind ||
              'primary'}`}>
            <label className="mr-3 mb-0">{message}</label>
            <span
              onClick={() => {
                handleClose && handleClose();
                this.setState({ isVisible: false });
              }}
            />
          </div>
        </div>
      )
    );
  }
}

export default AlertNotifier;
