const constants = {
  RGB_COLOR_CODE: 'rgba(22, 38, 59, 0.28)',
  BRAND_GREEN: '#00cc52',
  RED: '#f5496c',
  SURVEY_NAME: 'Survey name',
  COMPOSER: 'Composer',
  SURVEY_STATE_TRUE: '1',
  SURVEY_STATE: 'surveystate',
  SURVEY_MODAL_TITLE: 'Campaign can\'t be Launched',
  SURVEY_MODAL_BODY:'The associated survey might not be Live. You may want to check and come back again.',
  MISSING_COLUMN_NAMES: 'Missing column name(s) w.r.t. variable(s):',
  MISSING_COLUMN_NAME: 'Missing column names w.r.t. variables:',
  AVAILABLE_SEED_LIST: 'Seed List is available',
  RECIPIENT_LIST_UPLOADED: 'Recipient list uploaded.',
  NO_SEED_LIST_AVAILABLE: 'No Seed list is available',
  NO_RECIPIENT_LIST_UPLOADED: 'No Recipient list uploaded',
  TEST_EMAIL_CAMPAIGN: 'Test Email Campaign',
  LAUNCH_EMAIL_CAMPAIGN: 'Launch Email Campaign',
  FIELD_MERGED_IS_SYNCED: 'Field merge synced.',
  LABEL_CAN_NOT_BE_BLANK: 'Label cannot be blank.',
  TEST_EMAIL: 'Test Email',
  LAUNCH_CAMPAIGN: 'Launch Campaign',
  SET_REMINDER: 'Set Reminder',
  CAMPAIGN_TIMING_MESSAGE: 'When do you want to Launch this campaign?',
  CAMPAIGN_IS_BEING_LAUNCHED: 'The campaign is being launched',
  CHECKING_INPUT_FEILDS:
    'Checking input fields - (i.e. From, Reply-To, Subject, Body)',
  VALIDATION_MESSAGE:
    'Some field(s) (from, reply_to, subject or mail_body) have validation errors.',
  TEST_LAUNCH_POP_UP_MESSAGE:
    'Before we send the test emails, we’ll perform a few checks and make sure it’s in great shape.',
  LAUNCH_POP_UP_MESSAGE:
    'Before we launch your campaign, we’ll perform a few checks and make sure it’s in great shape.',
  THANK_YOU: 'Thank You.',
  THANK_YOU_MESSAGE:
    'You will not receive further emails from us. Allow 24 hours for this setting to take effect.',
  CONTACT_PREFERENCES: 'Set Your Contact Preferences',
  PREFER_NOT_TO_RECIEVE:
    'If you\'d prefer to not receive further research e-mails from us,verify your e-mail address and select "Finish".',
  CHOICE_PATTERN_ONLY_COMMUNICATION:
    'This choice pertains only to communications from this company,as we do not share consumer data with outside companies fortheir promotional use, nor do we rent or sell customer lists.'
};

export default constants;
