import React from 'react';
import { Modal, Input, Button } from 'stylist';
import { LANGUAGE } from 'constants/LanguageConstant';

export default function InsertLink(props) {
  return (
    <Modal
      dismissable={false}
      open={props.showLinkModal}
      onClose={props.handleLinkModal}>
      <Modal.Header>
        <Modal.Title>{LANGUAGE.LINK}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label>{LANGUAGE.LABEL}</label>
          <Input
            type="text"
            name="linkLabel"
            onChange={props.handleChange}
            value={props.linkLabel}
            className="w-100"
          />
        </div>
        <div className="mb-3">
          <label>{LANGUAGE.URL}</label>
          <Input
            value={props.linkURL}
            type="text"
            name="linkURL"
            onChange={props.handleChange}
            placeholder="https://example.com"
            className="w-100"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="footerButtons mt-4">
        <Button onClick={props.handleCreateLink}>Insert</Button>
        <Modal.CancelButton />
      </Modal.Footer>
    </Modal>
  );
}
