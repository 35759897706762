import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import rootReducers from 'store/reducers';

let middlewares = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middlewares = [
    ...middlewares,
    createLogger({
      collapsed: true,
      duration: true,
      timestamp: false
    })
  ];
}

const store = createStore(
  rootReducers,
  /* preloadedState, */ composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
