import React, { Fragment } from 'react';
import { Modal, Button, Loader } from 'stylist';

const PopUp = props => {
  const {
    headerTitle,
    dialogBody,
    confirmButton,
    cancelButton,
    onConfirm,
    onCancel,
    open,
    isProcessing,
    deletionErrorMsg,
    deletionProcessStarted,
    deletionError
  } = props;

  const pop_up = document.getElementsByClassName('delete-popup');

  for (let p of pop_up) {
    p.parentNode.parentNode.previousElementSibling.style.zIndex = 9998;
    p.parentNode.parentNode.style.zIndex = 9999;
  }

  return (
    <Modal open={open} onClose={onCancel}>
      <Modal.Header>
        <Modal.Title className="modal-header-title">{headerTitle}</Modal.Title>
      </Modal.Header>
      {deletionProcessStarted ? (
        isProcessing ? (
          <Fragment>
            <Modal.Body className="position-relative delete-popup">
              <div>
                <Loader centered />
              </div>
            </Modal.Body>
            <Modal.Footer className="footerButtons mt-4" />
          </Fragment>
        ) : (
          deletionError && (
            <Fragment>
              <Modal.Body className="modalPopup delete-popup">
                <span className="error-message">{deletionErrorMsg}</span>
              </Modal.Body>
              <Modal.Footer className="footerButtons mt-4" />
            </Fragment>
          )
        )
      ) : (
        <Fragment>
          <Modal.Body className="modalPopup delete-popup">
            <p>{dialogBody}</p>
          </Modal.Body>
          <Modal.Footer className="footerButtons mt-4">
            <Button kind="utility" onClick={onCancel}>
              {cancelButton}
            </Button>
            <Button kind="danger" onClick={onConfirm}>
              {confirmButton}
            </Button>
          </Modal.Footer>
        </Fragment>
      )}
    </Modal>
  );
};

export default PopUp;
