export const LANGUAGE = {
  FIELD_MERGE: 'Field Merge',
  SELECT_A_VARIABLE_TO_INSERT: 'Select a variable to insert',
  NO_FIELD_VARIABLE_NAME: 'No field merge variables available',
  INSERT_IMAGE: 'Insert Image',
  IMAGE_MANAGER: 'Image Manager',
  THERE_ARE_NO_IMAGE_IN_LIBRARY: 'There are no images in your library.',
  LINK: 'Link',
  LABEL: 'Label',
  URL: 'URL',
  SOURCE_CODE: 'Source Code',
  COPIED: 'Copied',
  COPY: 'Copy',
  OK: 'Ok',
  BUTTON: 'Survey Button',
  SURVEY_LINK: 'Survey Link',
  SURVEY_OPTOUT: 'Survey Opt-out',
  FONT_Arial: 'Arial',
  FONT_Courier: 'Courier',
  FONT_Georgia: 'Georgia',
  FONT_Monospace: 'Monospace',
  FONT_Sans_Serif: 'Sans Serif',
  FONT_Serif: 'Serif',
  FONT_Verdana: 'Verdana',
  NO_MATCH_RESULT: 'No recipient(s) found.',
  NO_RECIPIENT_UPLOADED: 'No recipient(s) uploaded',
  PATTERN: /^((http|https):\/\/)/,
};
