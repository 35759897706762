import { Quill } from 'react-quill';

let Embed = Quill.import('blots/embed');
export default class FieldTagBlot extends Embed {
  static create(value) {
    const node = super.create(value);

    node.innerHTML = value;

    return node;
  }

  static value(node) {
    return node.childNodes[1].textContent;
  }
}

FieldTagBlot.blotName = 'fieldtag';
FieldTagBlot.tagName = 'fieldtag';
